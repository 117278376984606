import {AfterViewInit, ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {GraphqlService} from "../../../graphql/graphql.service";
import {
  qFormAddress,
  qFormCommodities,
  qFormContact,
  qFormFurniture, qFormProximities,
  qFormSizeNDisposition,
  qFormTypeNCond,
  qFormViewNXpo
} from "./qforms";
import {MsgService} from "../../../services/msg.service";
import {
  TuxDynamicFormComponent
} from "../../../tux-dynamic-forms/components/tux-dynamic-form/tux-dynamic-form.component";
import {AddApartment} from "../../../graphql/add-apt";
import {keysToCamel} from "../../../graphql/convert-to-camel";

@Component({
  selector: 'app-apt-stepper',
  templateUrl: './apt-stepper.component.html',
  styleUrls: ['./apt-stepper.component.scss']
})
export class AptStepperComponent implements OnInit, AfterViewInit{

  data: {[key: string]: any} = {};
  isLoading = true;
  summaryData: { [key: string]: { [key: string]: any } } = {};

  objectKeys = Object.keys;

  @ViewChildren(TuxDynamicFormComponent) dynamicForms!: QueryList<TuxDynamicFormComponent>;
  constructor(private gql: GraphqlService, private msgService: MsgService, private cdf: ChangeDetectorRef) {
  }

  submit() {
    const data: any = keysToCamel(this.buildFormData());
    this.gql.addApt(data).subscribe(()=>{

    });
    console.info(data);
  }

  buildFormData(): { [key: string]: any } {
    let summaryData: { [key: string]: any }={};
    for (let comp of this.dynamicForms) {
      if (comp.getLabel() === 'Address') {
        summaryData[comp.getLabel()!]=comp.getRawValue()['address'];
      } else {
        summaryData[comp.getLabel()!] = comp.getRawValue();
      }
    }
    return summaryData;
  }


  onStepChange(event: any) {
    if (event.selectedIndex === 7) {
      this.summaryData=this.buildFormData();
      this.cdf.detectChanges();
    }
  }

  prettifyKey(key: string): string {
    // remove 'is_'
    let newKey = key.startsWith('is_') ? key.slice(3) : key;
    // append a space before capital letters
    newKey = newKey.replace(/([A-Z])/g, ' $1');
    // replace underscores with spaces and capitalize first letter of each word
    newKey = newKey.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
    // trim leading and trailing whitespace
    newKey = newKey.trim();
    return newKey;
  }


  ngAfterViewInit() {
}



  ngOnInit() {
    this.gql.getAutoCompleteDataForAptAdd().valueChanges.subscribe(
      {
        next: ({data})=>{

            this.data['apt_type'] = data['getAptTypeNames'];
            this.data['renovated'] = data['getIsRenovatedNames'];
            this.data['building_type']=data['getBuildingTypesNames'];
            this.data['tama']=data['getTamaProgressNames'];
          this.data['air_conditioner'] = data['getAirConditionerTypeNames'];
          this.data['water_heater'] = data['getWaterHeaterTypeNames'];
          this.data['terrace_type']=data['getTerraceTypesNames'];
          this.data['secured_room']=data['getSecuredRoomTypeNames'];
          this.data['is_furnished'] = data['getIsFurnishedNames'];
          this.data['kitchen'] = data['getKitchenFurnitureNames'];
          this.data['living_room']=data['getLivingRoomFurnitureNames'];
          this.data['dining_room']=data['getDiningRoomFurnitureNames'];
          this.data['bedroom']=data['getBedroomFurnitureNames'];
          this.data['office']=data['getOfficeFurnitureNames'];
          this.data['view_types'] = data['getViewTypeNames']
          this.data['interest_type'] = data['getInterestTypeNames'];
          this.data['unit_type'] = data['getUnitTypeNames'];
          this.data['parks'] = data['getBatYamParksNames'];
          this.data['beaches'] = data['getBatYamBeachesNames'];
          this.data['main_streets'] = data['getBatYamMainStreetsNames'];

          this.isLoading = false;

          },
        error: ()=>{
          this.msgService.snackMsgError("there was a problem loading data from server!");
        }
      }
    );


  }

  protected readonly qFormAddress = qFormAddress;
  protected readonly qFormTypeNCond = qFormTypeNCond;
  protected readonly qFormContact = qFormContact;
  protected readonly qFormSizeNDisposition = qFormSizeNDisposition;
  protected readonly qFormFurniture = qFormFurniture;
  protected readonly qFormCommodities = qFormCommodities;
  protected readonly qFormViewNXpo = qFormViewNXpo;
  protected readonly qFormProximities = qFormProximities;
}
