<div id="buy-info" class="max-text-width-large">
<div class="bold text-large padding-bottom-1" i18n>Looking to acquire a property?</div>
<div class="bold text-medium" i18n>
Whether you are searching for your Dream Home, a second home or an investment, we accompany you in finding the perfect match.
</div>
  <div class="text-medium">
<p i18n>
Each project is unique, that is why we are attentive to every single aspect of your search. We will thoroughly inquire the market to present you the most adequate and personalized selection.
</p>
<p i18n>
We take it at heart to advise you to the best of our knowledge for you to make the most educated and serene decision.
</p>
<p i18n>
Your future property is out there, and we will find it!
</p>
</div>
</div>
<app-contact-us title="buy"></app-contact-us>
