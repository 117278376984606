import { Component } from '@angular/core';

@Component({
  selector: 'app-create-new-apt-page',
  templateUrl: './create-new-apt-page.component.html',
  styleUrls: ['./create-new-apt-page.component.scss']
})
export class CreateNewAptPageComponent {

}
