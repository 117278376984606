import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-table-columns',
  templateUrl: './table-columns.component.html',
  styleUrls: ['./table-columns.component.scss']
})
export class TableColumnsComponent {

  public ret: string[];
  public val: boolean[];

  constructor(public dialogRef: MatDialogRef<TableColumnsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {all: string[]; visible: string[]},) {
    this.ret=data.visible;
    this.val = new Array(data.visible.length).fill(true);
    for (let i of data.all) {
    if (!(this.ret.indexOf(i)>=0)) {
        this.ret.push(i);
        this.val.push(false);
      }
    }
  }

  getCompiledArray() {
    const retVal = [];
    for (let i=0;i<this.ret.length;i++) {
      if (this.val[i]) {
        retVal.push(this.ret[i]);
      }
    }
    return retVal;
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}
