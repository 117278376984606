import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {MyDrawerService} from "../../services/my-drawer.service";
import {MenuBottomSheetComponent} from "../menu-bottom-sheet/menu-bottom-sheet.component";
import {Component} from "@angular/core";
import {ActivatedRoute, NavigationEnd, Router, RoutesRecognized} from "@angular/router";
import {filter, map, startWith} from "rxjs";

@Component({
  selector: 'app-top-toolbar',
  templateUrl: './top-toolbar.component.html',
  styleUrls: ['./top-toolbar.component.scss']
})
export class TopToolbarComponent  {

  currentUrl = '/';

 isHomepage =true;

  constructor(private router: Router, private _bottomSheet: MatBottomSheet, private myDrawer: MyDrawerService) {
    this.router.events.pipe(
      filter(event => (event instanceof NavigationEnd)),
    map((event: any) => event.url),
      startWith('*')

  ).subscribe(url=>{
    if (url == '*') {
      this.isHomepage=['/','/home'].indexOf(this.currentUrl)>=0;
    }
      this.currentUrl=this.router.url;
    });
  }

  changeLang(lang: string) {
    const newUrl = window.location.href.replace(/^(http(?:s?):\/\/[^\/]+\/)([^\/]+)(.*)$/, '$1' + lang + '$3');
    window.location.href=newUrl;
  }

  getPageClass() {
    if (this.currentUrl === '/') {
      return null;
    } else {
      const name = this.currentUrl.substring(1);
      const ret: any = {};
      ret[name]=true;
      return ret;
    }
  }

  openBottomSheet(): void {
    this._bottomSheet.open(MenuBottomSheetComponent);
  }

  toggleDrawer(): void {
    this.myDrawer.opened = !this.myDrawer.opened;
  }


}
