<div id="bar">
  <div role="group" [formGroup]="parts"
       (focusin)="onFocusIn($event)"
       (focusout)="onFocusOut($event)"
  >
    <mat-form-field>
      <input matInput
             class="my-search-box"
             (keydown.enter)="$event.preventDefault()"
             placeholder="Search Location"
             formControlName="address"
             type="text"
             (input)="_handleInput(parts.controls.address, entrance)"

             #search
      />
      <button mat-icon-button matSuffix (click)="clearSearchField()" *ngIf="this.parts.get('address')?.value">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field style="max-width: 5em">
      <!--*ngIf="requestEntrance"> -->
      <input matInput formControlName="entrance" placeholder="Entrance"  i18n-placeholder
             (input)="_handleInput(parts.controls.entrance, zipcode)"
             (keyup.backspace)="autoFocusPrev(parts.controls.entrance, search)"

             #entrance />
    </mat-form-field>
    <mat-form-field style="max-width: 5em">
      <!-- *ngIf="requestZipCode"> -->
      <input matInput formControlName="zipCode" placeholder="zipCode"  i18n-placeholder #zipcode
             (input)="_handleInput(parts.controls.zipCode, entrycode)"
             (keyup.backspace)="autoFocusPrev(parts.controls.zipCode, entrance)"


      />
    </mat-form-field>
    <mat-form-field style="max-width: 5em">
      <!-- *ngIf="requestEntryCode"> -->
      <input matInput formControlName="entryCode" placeholder="Entry Code" i18n-placeholder #entrycode
             (input)="_handleInput(parts.controls.entryCode, floor)"
             (keyup.backspace)="autoFocusPrev(parts.controls.entryCode, zipcode)"

      />
    </mat-form-field>
    <ng-container>
      <!-- *ngIf="requestFloorNApt"> -->
      <mat-form-field style="max-width: 5em">
        <input matInput formControlName="floor" placeholder="Floor"  i18n-placeholder #floor
               (input)="_handleInput(parts.controls.floor, apt)"
               (keyup.backspace)="autoFocusPrev(parts.controls.floor, entrycode)"
        />
      </mat-form-field>
      <mat-form-field style="max-width: 5em">
        <input matInput formControlName="aptNumber" placeholder="Apt"  i18n-placeholder #apt
               (input)="_handleInput(parts.controls.aptNumber)"
               (keyup.backspace)="autoFocusPrev(parts.controls.aptNumber, floor)"
        />
      </mat-form-field>
    </ng-container>
  </div>
  <button class="desktop_screen" mat-raised-button color="accent" (click)="geocodeLatLng()">Current Location</button>
  <button class="mobile_screen" mat-icon-button color="accent" matTooltip="Current Location" (click)="geocodeLatLng()"><mat-icon>home_pin</mat-icon></button>
  <button *ngIf="actionName" [ngClass]="{desktop_screen: actionMobileIcon != null}" mat-raised-button color="warn" (click)="runAction()" [disabled]="!this.parts.get('address')?.value?.trim()">
    {{actionName}}</button>
  <button *ngIf="actionMobileIcon" [ngClass]="{mobile_screen: actionName != null}" mat-raised-button color="warn"
          (click)="runAction()" [matTooltip]="actionName ?? ''" [disabled]="!this.parts.get('address')?.value?.trim()">
    <mat-icon>{{actionMobileIcon}}</mat-icon>
  </button>
</div>



<google-map

  #myGoogleMap

  height="500px"
  width="100%"
  [zoom]="zoom"
  [center]="center"
  [options]="options"
>
  <map-marker *ngIf="latitude !== undefined && longitude !== undefined"
              #meMarker="mapMarker"
              [position]="{lat: latitude, lng: longitude}"
              [draggable]="true"
              [icon]="'/assets/mood.svg'"
  >
  </map-marker>
  <map-marker
    #markerElem="mapMarker"
    *ngFor="let marker of markers"
    [position]="marker.position"
    [label]="marker.label"
    [title]="marker.title"
    [options]="marker.options"
    (mapClick)="openInfo(markerElem, marker.info)"
  >
  </map-marker>

  <map-info-window>
    <ng-container *ngIf="infoContent">
      {{infoContent.address}} {{infoContent.entrance ? '(' + infoContent.entrance + ')' : ''}}
      <button mat-icon-button color="accent" (click)="runAction(true)">
        <mat-icon>apartment</mat-icon></button>
    </ng-container>
  </map-info-window>
</google-map>

<button mat-raised-button color="primary" (click)="zoomIn()">Zoom in</button>
<button mat-raised-button color="primary" (click)="zoomOut()">Zoom out</button>
