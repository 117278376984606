// https://stackoverflow.com/questions/64129620/angular-2-understand-if-dom-element-is-within-viewport

import { Directive, AfterViewInit, OnDestroy, Output, EventEmitter, Host, ElementRef } from "@angular/core";

@Directive({selector: "[enterTheViewportNotifier]"})
export class EnterTheViewportNotifierDirective implements AfterViewInit, OnDestroy {
  @Output() visibilityChange = new EventEmitter<'VISIBLE' | 'HIDDEN'>();
  private _observer: IntersectionObserver | undefined;

  constructor(@Host() private _elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    const options = {root: null,rootMargin: "0px",threshold: 0.0};
    this._observer = new IntersectionObserver(this._callback, options);
    this._observer.observe(this._elementRef.nativeElement);
  }

  ngOnDestroy() {this._observer?.disconnect();}

  private _callback = (entries: any[], observer: any) => {
    entries.forEach(entry =>
        this.visibilityChange.emit(entry.isIntersecting ? 'VISIBLE' : 'HIDDEN'));
  };
}
