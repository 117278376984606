import {Component, Inject, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RoutesRecognized} from "@angular/router";
import {MyDrawerService} from "./services/my-drawer.service";
import {NgcCookieConsentService, NgcNoCookieLawEvent, NgcStatusChangeEvent} from "ngx-cookieconsent";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy{
  title = 'Dream Home in Israel';
  showToolbar = true;
  showFooter = true;

  private popupOpenSubscription: Subscription|undefined;
  private popupCloseSubscription: Subscription|undefined;
  private statusChangeSubscription: Subscription|undefined;
  private revokeChoiceSubscription: Subscription|undefined;
  private noCookieLawSubscription: Subscription|undefined;

  constructor(private router: Router, private myDrawer: MyDrawerService,
              private ccService: NgcCookieConsentService,
              @Inject(LOCALE_ID) private locale: string) {
  }

  get myDrawerStatus(): boolean {
    return this.myDrawer.opened;
  }

  openLink(event: MouseEvent): void {
    this.myDrawer.opened=!this.myDrawer.opened;
  }

  set myDrawerStatus(val: boolean) {
    this.myDrawer.opened=val;
  }

  handleCookieConsent() {
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });



    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });
  }

  ngOnInit() {
    document.documentElement.setAttribute('lang', this.locale);
    // add to use that and not activated route because this code is out of the router-outlet
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        let route = data.state.root;
        while (route.firstChild) {
          route = route.firstChild;
        }
        const routeData = route.data;
        this.showToolbar = !(routeData?.hasOwnProperty('showToolbar') && routeData['showToolbar'] === false);
        this.showFooter = !(routeData?.hasOwnProperty('showFooter') && routeData['showFooter'] === false);
      }
    });
    this.handleCookieConsent();
  }

  ngOnDestroy() {
    this.popupOpenSubscription?.unsubscribe();
    this.popupCloseSubscription?.unsubscribe();
    this.statusChangeSubscription?.unsubscribe();
    this.revokeChoiceSubscription?.unsubscribe();
    this.noCookieLawSubscription?.unsubscribe();
  }
}
