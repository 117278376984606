import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";


@Component({
  selector: 'app-add-apt-prospect-change-entrance-dialog',
  templateUrl: './add-apt-prospect-change-entrance-dialog.component.html',
  styleUrls: ['./add-apt-prospect-change-entrance-dialog.component.scss']
})
export class AddAptProspectChangeEntranceDialogComponent {

  isRename: boolean = true;
  entrance: string|null = null;
  constructor(
              public dialogRef: MatDialogRef<AddAptProspectChangeEntranceDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.isRename=data.is_rename;
    this.entrance=data.entrance;
  }

  submit() {
      this.dialogRef.close(this.entrance);
  }

}
