import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ColorPickerComponent} from "ngx-color-picker";

@Component({
  selector: 'app-compass-form-input',
  templateUrl: './compass-form-input.component.html',
  styleUrls: ['./compass-form-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompassFormInputComponent),
      multi: true,
    }
            ]
})
export class CompassFormInputComponent  implements ControlValueAccessor {

  formGroup: FormGroup = this.fb.group({
                                  directions: ['']
                                });

  @Input({required: true}) title!: string;

  constructor(private fb: FormBuilder) { }

  onDirectionChange(direction: string) {
    if (this.formGroup.controls['directions'].value.includes(direction)) {
      this.formGroup.controls['directions'].value.splice(this.formGroup.controls['directions'].value.indexOf(direction), 1);
    } else {
      this.formGroup.controls['directions'].value.push(direction);
    }
  }

  get directions() {
    return this.formGroup.controls['directions'].value;
  }

// Custom validator


  validateDirection(direction: string): boolean {
    if (direction === 'North' || direction === 'South' || direction === 'East' || direction === 'West') {
      return true;
    } else {
      return false;
    }
  }

// ControlValueAccessor methods

  writeValue(value: any) {
    this.formGroup.controls['directions'].setValue(value);
  }

  registerOnChange(fn: (value: any) => void) {
    this.formGroup.controls['directions'].valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.formGroup.controls['directions'].valueChanges.subscribe(fn);
  }

}

