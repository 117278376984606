import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  forwardRef,
  Input, NgIterable,
  OnChanges,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import {ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {QuestionFormField, QuestionFormFieldTypeTOComponentDefault} from "../services/question.service";
import {TuxDynamicFormComponent} from "../components/tux-dynamic-form/tux-dynamic-form.component";
import {buildTuxInFormValidators, QuestionControlService} from "../services/question-control.service";




function convertToTitleCase(input: string): string {
  // Replace underscores with spaces
  let result = input.replace(/_/g, ' ');

  // Insert a space before all capital letters that are preceded by a lowercase letter
  result = result.replace(/([a-z])([A-Z])/g, '$1 $2');

  // Capitalize the first letter of each word
  result = result.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });

  return result;
}

@Component({
  selector: 'app-dynamic-form-question',
  templateUrl: './tux-dynamic-form-question.component.html',
  styleUrls: ['./tux-dynamic-form-question.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TuxDynamicFormQuestionComponent),
    multi: true,
  } ]
})
export class TuxDynamicFormQuestionComponent implements OnChanges, ControlValueAccessor {
  @Input({required: true}) question!: QuestionFormField<any, any>;
  @Input({required: false}) allData: {[key: string] : any}|undefined|null;
  selectData: any[]|undefined|null;
  //inlineFormData: any | null = {};
  form: FormGroup|undefined;
  loading = true;
  @Input() option: any;
  onChanged: Function = () => {};
  onTouched: Function = () => {};


  itemLegit(item: any) {
    console.info(item);
    return item.hasOwnProperty('registerOnChange');
  }

  @ViewChildren(TuxDynamicFormComponent) dynamicForms!: QueryList<TuxDynamicFormComponent>;

  constructor(private fb: FormBuilder) {
  }

  decListItems() {
    if (this.listControls.length > 0) {
      this.listControls.removeAt(-1);
    }
  }


  incListItems() {
    this.listControls.push(new FormControl(null,{
      validators: this.question.validators
    }));
  }

  get listControls(): FormArray<FormControl> {
    return this.form!.get(this.question.keyName) as FormArray;
  }


  ngOnChanges(changes: SimpleChanges): void {
    let groups: { [key: string]: any } = {};
    const validators = buildTuxInFormValidators(this.question);
    if (this.question.type === 'list') {
      groups[this.question.keyName] = this.fb.array([new FormControl(this.question.defaultValue ?? null,validators)]);

    } else {
      groups[this.question.keyName] = [this.question.defaultValue ?? null, validators];

    }
    this.form = this.fb.group(groups);
    this.form.valueChanges.subscribe(()=>{
      this.onValueChanged();
    });
    if (this.allData) {
      this.selectData = this.allData![this.question!.keyName];
    }
      if (!this.question.component) {
        if (!this.question.type) {
          throw new Error(`field ${this.question.keyName} is missing type or component type`);
        } else {
          this.question.component=QuestionFormFieldTypeTOComponentDefault[this.question.type];
        }
      }
      if (!this.question.label) {
        this.question.label=convertToTitleCase(this.question.keyName);
      }
      this.loading=false;

  }

  @Input()
  get value(): any[] | null {
    const data = this.form?.getRawValue();
    return Object.keys(data).length === 1 ? data[this.question.keyName] : data;
  }


  get isValid() {
    return this.form?.controls[this.question.keyName].valid;
  }

  onValueChanged() {
    if (this.onChanged) {
      this.onChanged(this.value);
    }
  }

  public onBlur(): void {
    this.onTouched();
  }

  registerOnChange(fn: Function) {
    this.onChanged = fn;
  }
  registerOnTouched(fn: Function) {
    this.onTouched = fn;
  }
  writeValue(value: any): void {
  }


}
