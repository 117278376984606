import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AptProspectStatus, AptProspectStatusOptional, ProspectStatusType} from '../../crm-pages/prospect-building/my-types';

@Component({
  selector: 'app-prospect-floor',
  templateUrl: './prospect-floor.component.html',
  styleUrls: ['./prospect-floor.component.scss']
})
export class ProspectFloorComponent {

  @Input() address:string|undefined;

  @Input() floorNumber = '';

  @Input() apartments: AptProspectStatus[]=[];

  @Input() statusTypes: {[key: number]:  ProspectStatusType} = {};

  @Output() openEditDialog: EventEmitter<AptProspectStatus> = new EventEmitter<AptProspectStatus>();
  @Output() openAddDialog: EventEmitter<AptProspectStatusOptional> = new EventEmitter<AptProspectStatusOptional>();
  @Output() openFloorDialog: EventEmitter<string> = new EventEmitter<string>();



}
