<div class="apt_div" matBadge="{{contactsNumber === 0  ? null : contactsNumber}}" matBadgePosition="below after" matBadgeColor="accent">
  <svg width="100%" height="100%" viewBox="0 0 128 214"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
  <g transform="matrix(1,0,0,1,-60,-26.8624)">
        <g>
            <path id="Path" [attr.fill]="bgColor" d="M188,240C184.8,240.1 64.6,240 60,240C61.2,222.4 61.5,41.9 60,28C66,27.2 181.6,25.9 188,28C187.3,44.1 187.3,222.8 188,240Z" style="fill-rule:nonzero;"/>
          <path id="Path-1" d="M188,240C184.8,240.1 64.6,240 60,240C61.2,222.4 61.5,41.9 60,28C66,27.2 181.6,25.9 188,28C187.3,44.1 187.3,222.8 188,240Z" style="fill:url(#_Linear1);fill-rule:nonzero;"/>
          <path id="Path-2" d="M76,48L76,218L168,218L83.1,210.9L76,48Z" style="fill:white;fill-opacity:0.6;fill-rule:nonzero;"/>
          <path id="Path-3" d="M80,48L168,48L168,218L160,55.7L80,48Z" style="fill-opacity:0.6;fill-rule:nonzero;"/>
          <path id="Ellipse" d="M184,138C184,141.3 181.3,144 178,144C174.7,144 172,141.3 172,138C172,134.7 174.7,132 178,132C181.3,132 184,134.7 184,138Z" style="fill-opacity:0.6;fill-rule:nonzero;"/>
        </g>
    </g>
    <defs>
        <linearGradient id="_Linear1" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0.106667,213.223,-213.223,0.106667,133.6,26.8789)"><stop offset="0" style="stop-color:white;stop-opacity:0"/><stop offset="0.07" style="stop-color:white;stop-opacity:0"/><stop offset="0.67" style="stop-color:white;stop-opacity:0.1"/><stop offset="1" style="stop-color:white;stop-opacity:0.1"/></linearGradient>
    </defs>
</svg>
  <div class="centered" [style.color]="textColor">{{ doorNumber }}</div>
</div>
