import {Component, Inject} from '@angular/core';
import {GraphqlService} from "../../graphql/graphql.service";
import {ProspectStatusType} from "../../crm-pages/prospect-building/my-types";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MsgService} from "../../services/msg.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-add-apt-prospect-floor-dialog',
  templateUrl: './add-apt-prospect-floor-dialog.component.html',
  styleUrls: ['./add-apt-prospect-floor-dialog.component.scss']
})
export class AddAptProspectFloorDialogComponent {


  public statusTypes: ProspectStatusType[] = [];

  form: FormGroup;

  constructor(private gqlService: GraphqlService,
              private fb: FormBuilder,
              private msgService: MsgService,
              public dialogRef: MatDialogRef<AddAptProspectFloorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: string|null) {
    this.form = this.fb.group({
      new_floor: [data, Validators.required],
      allow_existing_floor: [true, Validators.required]
    });
  }

  submit() {
    if (this.form.invalid) {
      this.msgService.snackMsgWarn('please fill floor number');
      return;
    } else {
      const formObj =this.form.value;
      if (formObj) {
        if (formObj.new_floor === this.data) {
          this.dialogRef.close();
        } else {
          this.dialogRef.close({...this.form.value, 'old_floor': this.data});
        }
      }
   1 }
  }
}
