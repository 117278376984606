<h1>Prospect Status Types</h1>
<button mat-raised-button color="primary" (click)="addStatusType()">Add Status Type</button>

<table mat-table (matSortChange)="sortData($event)"  [dataSource]="statusTypes" matSort matSortActive="statusName"
       matSortDirection="desc" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <ng-container matColumnDef="statusNameEn">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field>
        <mat-label>Status</mat-label>
      <input matInput (ngModelChange)="element.changed = true" [(ngModel)]="element.statusNameEn" />
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="textColor">
    <th mat-header-cell *matHeaderCellDef> Text Color </th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field style="width: 6em">
        <mat-label>Text Color</mat-label>
      <input matInput (ngModelChange)="element.changed = true" [(colorPicker)]="element.textColor" [(ngModel)]="element.textColor"/>
      </mat-form-field>
      <td>
  </ng-container>

  <ng-container matColumnDef="bgColor">
    <th mat-header-cell *matHeaderCellDef> Background Color </th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field style="width: 6em">
        <mat-label>Background Color</mat-label>
      <input matInput (ngModelChange)="element.changed = true" [(colorPicker)]="element.bgColor" [(ngModel)]="element.bgColor"/>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="door">
    <th mat-header-cell *matHeaderCellDef> Door </th>
    <td mat-cell *matCellDef="let element">
      <app-prospect-door [textColor]="element.textColor" [bgColor]="element.bgColor" doorNumber="11"></app-prospect-door>
    </td>
  </ng-container>

  <ng-container matColumnDef="commands">
    <th mat-header-cell *matHeaderCellDef> Commands </th>
    <td mat-cell *matCellDef="let element; let j=index">
      <button mat-icon-button color="primary" matTooltip="Save" [disabled]="!element.changed"
              [swal]="{title:'Save: Are you sure ?', showCancelButton: true}" (confirm)="saveRow(j, element)">
        <mat-icon>done</mat-icon></button>
      <button mat-icon-button color="primary" matTooltip="Revert" [disabled]="!element.id || !element.changed"
              [swal]="{title:'Revert: Are you sure ?', showCancelButton: true}"
              (confirm)="revertRow(j)"><mat-icon>undo</mat-icon></button>
      <button mat-icon-button color="primary" matTooltip="Delete" (confirm)="deleteRow(j)"
              [swal]="{title:'Delete: Are you sure ?', showCancelButton: true}"
      ><mat-icon>delete</mat-icon></button>
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
