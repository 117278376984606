<h3>{{title}}</h3>

<form [formGroup]="formGroup">
  <mat-button-toggle-group formControlName="directions" multiple style="width: 100%">
    <div class="all-row flex-column-center-space-between">
      <div>
    <mat-button-toggle value="N"><mat-icon>north</mat-icon></mat-button-toggle>
      </div>
      <div class="middle-row">
     <mat-button-toggle value="W"><mat-icon>west</mat-icon></mat-button-toggle>
    <mat-button-toggle value="E"><mat-icon>east</mat-icon></mat-button-toggle>
      </div>
      <div>
      <mat-button-toggle value="S"><mat-icon>south</mat-icon></mat-button-toggle>
      </div>
    </div>
  </mat-button-toggle-group>
</form>

<p>The selected directions are: {{ directions }}</p>

