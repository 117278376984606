import {FormGroup} from "@angular/forms";

export const atLeastOneHasValue = (fields: Array<string>) => {
  return (group: FormGroup) => {
    for (const fieldName of fields) {
      if (group.get(fieldName)?.value) {
        return null;
      }
    }
    return { atLeastOneRequired: true };
  };
};
