import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild} from '@angular/core';
import {CommonModule, NgClass} from "@angular/common";
import {MatAnchor, MatButtonModule, MatIconButton} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {animate, style, transition, trigger} from '@angular/animations';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragPreview,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray
} from "@angular/cdk/drag-drop";
import {fabric} from 'fabric';
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {FormsModule} from "@angular/forms";
// @ts-ignore
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import {logoBase64URl} from "./logo";
import {MatTooltip} from "@angular/material/tooltip";

export interface ImageFile {
  file: File;
  orientation: 'landscape' | 'portrait' | 'box';
  url: string;
  urlWithoutLogo: string;
  instaUrl: string;
  instaUrlWithoutLogo: string;
  originalUrl: string;
  //fabric: fabric.Image;
  canvasState: null|string;
  instaCanvasState: null|string;
}

interface CanvasState {
  canvas: fabric.ICanvasOptions & { objects: any[] };
  width: number;
  height: number;
  brightness: number;
  saturation: number;
  grayScale: boolean;
  contrast: number;
  logoTransparency: number;
  temperature: number;
}

//@ts-ignore
fabric.Image.filters.CustomBrightness = fabric.util.createClass(fabric.Image.filters.BaseFilter, {
  type: 'CustomBrightness',

  initialize: function (options: any) {
    options = options || {};
    this.brightness = (options.brightness || 0) * 255; // Default brightness value
  },

  applyTo: function (options: any) {
    let imageData = options.imageData;
    let data = imageData.data;
    const len = data.length;
    const brightness = this.brightness;

    for (let i = 0; i < len; i += 4) {
      // Apply brightness adjustment to each pixel
      data[i] = Math.min(255, Math.max(0, data[i] + brightness)); // Red channel
      data[i + 1] = Math.min(255, Math.max(0, data[i + 1] + brightness)); // Green channel
      data[i + 2] = Math.min(255, Math.max(0, data[i + 2] + brightness)); // Blue channel
    }

    options.imageData = imageData;
    options.ctx.canvas.willReadFrequently = true;
  },

  isNeutralState: function () {
    return this.brightness === 0;
  },

  toObject: function () {
    return {
      type: this.type,
      brightness: this.brightness / 255,
    };
  },

  fromObject: function (object: any) {
    //@ts-ignore
    return new fabric.Image.filters.CustomBrightness(object);
  },
});



//@ts-ignore
fabric.Image.filters.Temperature = fabric.util.createClass(
  fabric.Image.filters.BaseFilter,
{
  type: 'Temperature',
  temperature: 0,

  initialize: function(options: any) {
    options = options || {};
    // Scale the temperature factor to make adjustments more subtle
    this.temperature = (options.temperature || 0); // Default temperature value
    console.info(`set temperature to ${this.temperature}`);

  },
  isNeutralState: function () {
    return this.temperature === 0;
  },

  applyTo: function(options: any) {
    console.info('applyTo');
    let imageData = options.imageData;
    let data = imageData.data;
    const len = data.length;
    const temperature = this.temperature*50; // Scale temperature to be between -255 and 255

    for (let i = 0; i < len; i += 4) {
      // Apply temperature adjustment to each pixel
      data[i] += temperature; // Red channel
      data[i + 2] -= temperature; // Green channel
    }

    options.imageData = imageData;

    // Set willReadFrequently to true
    options.ctx.canvas.willReadFrequently = true;
  },

  toObject: function () {
    return {
      type: this.type,
      temperature: this.temperature,
    };
  },
  toJSON: function () {
    return {
      temperature: this.temperature,
    };
  },

});

//@ts-ignore
fabric.Image.filters.Temperature.fromObject = function(object) {
  // @ts-ignore
  return new fabric.Image.filters.Temperature(object);
};

//@ts-ignore
//fabric.Image.filters.Temperature = TemperatureFilter;
@Component({
  selector: 'app-upload-multi-images',
  standalone: true,
  imports: [
    NgClass,
    CommonModule,
    MatButtonModule,
    MatAnchor,
    MatIconModule,
    MatIconButton,
    CdkDropList,
    CdkDrag,
    MatSlideToggle,
    FormsModule,
    CdkDragPreview,
    CdkDropListGroup,
    MatTooltip
  ],
  templateUrl: './upload-multi-images.component.html',
  styleUrl: './upload-multi-images.component.scss',
  animations: [
    trigger('slideInOut', [
      transition('* <=> *', [
        style({ opacity: 0 }),
        animate('0.5s', style({ opacity: 1 })),
      ])
    ])
  ]
})
export class UploadMultiImagesComponent implements AfterViewInit{
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('brightnessSlider') brightnessSlider!: ElementRef;
  @ViewChild('tempSlider') tempSlider!: ElementRef;
  @ViewChild('grayscaleToggle') grayscaleToggle!: MatSlideToggle;
  @ViewChild('contrastSlider') contrastSlider!: ElementRef;
  @ViewChild('temperatureSlider') temperatureSlider!: ElementRef;
  @ViewChild('saturationSlider') saturationSlider!: ElementRef;
  @ViewChild('logoTransparencySlider') logoTransparencySlider!: ElementRef;

  @ViewChild('fabricCanvas', { static: true }) fabricCanvas!: ElementRef;
  @ViewChild('staticCanvas') staticCanvas!: ElementRef<HTMLCanvasElement>;

  @HostListener('dragover', ['$event'])
  onHostListenerDragOver(event: any) {
    event.preventDefault();
    let clientRect = this.elementRef.nativeElement.getBoundingClientRect();  // Get the boundaries of the element
    let threshold = clientRect.height * 0.10; // Set the threshold as 10% of the element's height

    if (event.clientY - clientRect.top < threshold) { // Mouse within the top 10% of the container
      this.elementRef.nativeElement.scrollTop -= 10;
    }

    else if ((clientRect.bottom - event.clientY) < threshold) { // Mouse within the bottom 10% of the container
      this.elementRef.nativeElement.scrollTop += 10;
    }
  }

  hiddenStaticCanvas!: fabric.StaticCanvas;
  canvas!: fabric.Canvas;
  filtersNotAffectLogo = true;

  isProcessingAddedImages=false;

  isLoadedToCanvas = false;
  isLoadedToCanvasInsta =false;

  defaultOpacity = 0.5;

  logoImageUrl: string = logoBase64URl;

  isInstaCanvaBox : boolean = false;


  files: ImageFile[] = [];
  dragOver: boolean = false;
  logoDragOver: boolean = false;
  zoomLevel: number = 1;
  currentOpenedIndex = 0;

  isViewThumbnailsInstagramMode: boolean = false;

  // image Positioning functions

  alignTopLeft() {
    const img: any = this.canvas.getObjects()[0];
    img.set({ left: 0, top: 0 });
    this.canvas.renderAll();
  }

  alignLeft() {
    const img: any = this.canvas.getObjects()[0];
    img.set({ left: 0, top: this.canvas.height! / 2 - img.height! / 2 });
    this.canvas.renderAll();
  }

  alignBottomLeft() {
    const img: any = this.canvas.getObjects()[0];
    img.set({ left: 0, top: this.canvas.height! - img.height! });
    this.canvas.renderAll();
  }

  alignBottomCenter() {
    const img: any = this.canvas.getObjects()[0];
    img.set({ left: this.canvas.width! / 2 - img.width! / 2, top: this.canvas.height! - img.height! });
    this.canvas.renderAll();
  }

  alignRightCenter() {
    const img: any = this.canvas.getObjects()[0];
    img.set({ left: this.canvas.width! - img.width, top: this.canvas.height! / 2 - img.height! / 2 });
    this.canvas.renderAll();
  }

  alignTopRight() {
    const img: any = this.canvas.getObjects()[0];
    img.set({ left: this.canvas.width! - img.width!, top: 0 });
    this.canvas.renderAll();
  }

  alignBottomRight() {
    const img: any = this.canvas.getObjects()[0];
    img.set({ left: this.canvas.width! - img.width!, top: this.canvas.height! - img.height! });
    this.canvas.renderAll();
  }

  alignTopCenter() {
    const img: any = this.canvas.getObjects()[0];
    img.set({ left: this.canvas.width! / 2 - img.width! / 2, top: 0 });
    this.canvas.renderAll();
  }

  alignCenter() {
    const img: any = this.canvas.getObjects()[0];
    this.canvas.centerObject(img);
    this.canvas.renderAll();
  }

  readImageFileDimensions(file: File): Promise<{ width: number, height: number }> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };
        img.src = event.target?.result as string;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  async createCanvas(imgUrl: string, idx:number, isInstaCanvas=false) {
    return new Promise(async (resolve, reject) => {
      console.info(`working on idx ${idx} ${isInstaCanvas}`);
      var el = document.createElement('canvas');
      var hiddenCanvas = new fabric.StaticCanvas(el);
      const imgElement = await this.createImageElement(imgUrl);

      const imgInstance = new fabric.Image(imgElement, {
        left: 0,
        top: 0,
        angle: 0,
        opacity: 1,
/*        lockMovementX: false,
        lockMovementY: false,
        selectable: true*/
      });
      let canvasWidth = imgElement.width;
      let canvasHeight = imgElement.height;
      if (isInstaCanvas) {
        const instaAspectRatio = this.isInstaCanvaBox ? 1 : 1080 / 1350;
        const imgAspectRatio = imgElement.width / imgElement.height;
        if(imgAspectRatio > instaAspectRatio) {
          // The image is too wide. Need to reduce the canvas width to conform to the Instagram aspect ratio.
          canvasWidth = canvasHeight * instaAspectRatio;
        } else {
          // The image is too narrow. Need to reduce the canvas height to conform to the Instagram aspect ratio.
          canvasHeight = canvasWidth / instaAspectRatio;
        }
      }

      hiddenCanvas.setDimensions({width: canvasWidth, height: canvasHeight});
      hiddenCanvas.centerObject(imgInstance);

      hiddenCanvas.add(imgInstance);
      await this.addLogoToCanvas(hiddenCanvas);
      await this.saveCanvas(null, hiddenCanvas, idx, isInstaCanvas);
      resolve(null);
    });
  }

  async downloadAndMoveToInstagram() {
      await this.saveCanvas(null, null, null, this.isLoadedToCanvasInsta);
      let zipWithLogo: any[]=[];
      for (let i=0;i<this.files.length;i++) {
        let paddedNumber = String(i).padStart(2, '0');
        const fileWithLogo = `image-with-logo-${paddedNumber}.jpg`;
        const fileWithoutLogo = `image-without-logo-${paddedNumber}.jpg`;
        zipWithLogo.push({name: fileWithLogo, url: this.files[i].url});
        zipWithLogo.push({name: fileWithoutLogo, url: this.files[i].urlWithoutLogo});
      }
      await this.downloadFiles(zipWithLogo, 'images-regular.zip');
      this.isViewThumbnailsInstagramMode=true;
      this.currentOpenedIndex=0;
      await this.loadToCanvas(0, true);
    }

  async downloadZipsWithLogo() {
    await this.saveCanvas(null, null, null, this.isLoadedToCanvasInsta);
    let zipWithLogo: any[]=[];
    for (let i=0;i<this.files.length;i++) {
      let paddedNumber = String(i).padStart(2, '0');
      const fileWithLogo = `image-with-logo-${paddedNumber}.jpg`;
      const fileWithoutLogo = `image-without-logo-${paddedNumber}.jpg`;
      zipWithLogo.push({name: fileWithLogo, url: this.files[i].url});
    }
    await this.downloadFiles(zipWithLogo, 'files-with-logo.zip');
  }

  async downloadZipsWithLogoInsta() {
    await this.saveCanvas(null, null, null, this.isLoadedToCanvasInsta);
    let zipWithLogo: any[]=[];
    for (let i=0;i<this.files.length;i++) {
      let paddedNumber = String(i).padStart(2, '0');
      const fileWithLogo = `image-instagram-with-logo-${paddedNumber}.jpg`;
      const fileWithoutLogo = `image-instagram-without-logo-${paddedNumber}.jpg`;
      zipWithLogo.push({name: fileWithLogo, url: this.files[i].instaUrl});
    }
    await this.downloadFiles(zipWithLogo, 'instagram-files-with-logo.zip');
  }

  async downloadInstagramZips() {
    await this.saveCanvas(null, null, null, this.isLoadedToCanvasInsta);
    let imageFiles: any[]=[];
    for (let i=0;i<this.files.length;i++) {
      let paddedNumber = String(i).padStart(2, '0');
      const fileWithLogo = `image-instagram-with-logo-${paddedNumber}.jpg`;
      // const fileWithoutLogo = `image-instagram-without-logo-${paddedNumber}.jpg`;
      imageFiles.push({name: fileWithLogo, url: this.files[i].instaUrl});
      // imageFiles.push({name: fileWithoutLogo, url: this.files[i].instaUrlWithoutLogo});
    }
    await this.downloadFiles(imageFiles, 'images-instagram.zip');
  }

  async downloadZipsWithoutLogo() {
    await this.saveCanvas(null, null, null, this.isLoadedToCanvasInsta);
    let zipWithoutLogo: any[]=[];
    for (let i=0;i<this.files.length;i++) {
      let paddedNumber = String(i).padStart(2, '0');
      const fileWithLogo = `image-with-logo-${paddedNumber}.jpg`;
      const fileWithoutLogo = `image-without-logo-${paddedNumber}.jpg`;
      zipWithoutLogo.push({name: fileWithoutLogo, url: this.files[i].urlWithoutLogo});
    }
    await this.downloadFiles(zipWithoutLogo, 'files-without-logo.zip');
  }

  async downloadZipsWithoutLogoInsta() {
    await this.saveCanvas(null, null, null, this.isLoadedToCanvasInsta);
    let zipWithoutLogo: any[]=[];
    for (let i=0;i<this.files.length;i++) {
      let paddedNumber = String(i).padStart(2, '0');
      const fileWithLogo = `image-instagram-with-logo-${paddedNumber}.jpg`;
      const fileWithoutLogo = `image-instagram-without-logo-${paddedNumber}.jpg`;
      zipWithoutLogo.push({name: fileWithoutLogo, url: this.files[i].instaUrlWithoutLogo});
    }
    await this.downloadFiles(zipWithoutLogo, 'instagram-files-without-logo.zip');
  }

  async downloadFiles(files: Array<{ name: string, url: string }>, zipFilename: string) {
    const zip = new JSZip();

    for (let file of files) {
      const response = await fetch(file.url);
      const data = await response.blob();
      zip.file(file.name, data);
    }

    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, zipFilename);
  }


  ngAfterViewInit() {
    //@ts-ignore
    fabric.initFilterBackend = function() {
      return (new fabric.Canvas2dFilterBackend());
    };

    this.canvas = new fabric.Canvas(this.fabricCanvas.nativeElement, {preserveObjectStacking: true});
    this.canvas.on('object:moving', (e)=> {
      let obj: any = e.target;
      obj.setCoords();

      let boundingRect = obj.getBoundingRect();

      if(boundingRect.top > 0) {
        obj.set('top', 0).setCoords();
      }

      if(boundingRect.left > 0) {
        obj.set('left', 0).setCoords();
      }

      //@ts-ignore
      if(boundingRect.top < this.canvas.height - boundingRect.height) {
        //@ts-ignore
        obj.set('top', this.canvas.height - boundingRect.height).setCoords();
      }

      //@ts-ignore
      if(boundingRect.left < this.canvas.width - boundingRect.width) {
        //@ts-ignore
        obj.set('left', this.canvas.width - boundingRect.width).setCoords();
      }
    });
    this.hiddenStaticCanvas = new fabric.StaticCanvas(this.staticCanvas.nativeElement);

  }

  createImageElement(dataUrl: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = dataUrl;
      img.onload = () => {
        resolve(img);
      };
      img.onerror = reject;
    });
  }



  async loadToCanvas(index: number, isInstaCanvas=false) {
    if (index>0) {
      this.isClickedSave=false;
    }
    this.isLoadedToCanvas=true;
    this.isLoadedToCanvasInsta=isInstaCanvas;
    this.currentOpenedIndex=index;
    if (!isInstaCanvas) {
      console.log('loading regular canvas state');
      await this.loadCanvasState(this.files[this.currentOpenedIndex].canvasState!, false, false, this.files[index].orientation);
    } else {
      console.log('loading insta canvas state');
      await this.loadCanvasState(this.files[this.currentOpenedIndex].instaCanvasState!, true, false, this.files[index].orientation);
    }
  }

  public currentOrientation(): 'landscape' | 'portrait' | 'box'
{
    return this.files[this.currentOpenedIndex].orientation;
  }



  constructor(private cd: ChangeDetectorRef, private elementRef: ElementRef) {}

  canvasAdjustContrast(value: number) {
    this.canvasAdjustFilter('contrast', value);
  }

  canvasAdjustLogoTransparency(value: number) {
    let object = this.canvas.getObjects()[1];
    object.set('opacity', value);
    this.canvas.renderAll();
  }


  canvasAdjustSaturation(value: number) {
    this.canvasAdjustFilter('saturation', value);
  }

  canvasAdjustGrayScale(value: boolean): void {
    let isFirst=true;
    this.canvas.getObjects().forEach((obj: any) => {
      if (!isFirst && this.filtersNotAffectLogo) {
        return;
      }
      isFirst=false;

      if (obj && obj.type === 'image') {
        //@ts-ignore
        let existingFilterIndex = obj.filters!.findIndex(filter => filter instanceof fabric.Image.filters.Grayscale);
        if (value) {
          if (existingFilterIndex > -1) {
            console.warn('try to apply grayscale while grayscale effect already applied');
          } else {
            obj.filters!.push(new fabric.Image.filters.Grayscale());
          }
        } else {
          if (existingFilterIndex === -1) {
            console.warn("tried to disable grayscale while not having grayscale effect");
          } else {
            obj.filters!.splice(existingFilterIndex, 1);
          }
        }
        obj.applyFilters();
        this.canvas.renderAll();


      }
    });

  }

  async saveCanvas(newIdx: number|null, staticCanvas: fabric.StaticCanvas|null = null, staticCanvasIdx: number|null=null, isSaveToInsta=false, isLoadinsta = false) {
    if (newIdx === null) {
      this.isClickedSave = true;
    }
    if (!staticCanvas) {
      const secondTypeCanvasStateJson = this.files[this.currentOpenedIndex][!isSaveToInsta ? 'instaCanvasState' : 'canvasState']!;
      //@ts-ignore
      let filters = this.canvas.getObjects()[0].filters;
      this.hiddenStaticCanvas.clear();
      await this.loadCanvasState(secondTypeCanvasStateJson, !isSaveToInsta, true, this.currentOrientation());
      // @ts-ignore
      const imgObj =this.hiddenStaticCanvas.getObjects()[0];
      // @ts-ignore
      imgObj.filters = filters;
      // @ts-ignore
      imgObj.applyFilters();
      this.hiddenStaticCanvas.renderAll();
      this.files[staticCanvasIdx ?? this.currentOpenedIndex][!isSaveToInsta ? 'instaCanvasState' : 'canvasState'] = this.saveCanvasState(this.hiddenStaticCanvas);
      this.files[this.currentOpenedIndex][!isSaveToInsta ? 'instaUrl' : 'url'] = this.hiddenStaticCanvas.toDataURL({format: 'jpeg'});
    }
    this.files[staticCanvasIdx ?? this.currentOpenedIndex][isSaveToInsta ? 'instaCanvasState' : 'canvasState'] = this.saveCanvasState(staticCanvas);
    if (this.currentOpenedIndex !== null) {
      this.files[staticCanvasIdx ?? this.currentOpenedIndex][isSaveToInsta ? 'instaUrl' : 'url'] = staticCanvas ? staticCanvas.toDataURL({format: 'jpeg'}) : this.canvas.toDataURL({format: 'jpeg'});
      if (staticCanvasIdx !== null) {
        staticCanvas!.getObjects()[1].visible = false;
      } else {
      this.canvas.getObjects()[1].visible = false;
      }
      this.files[staticCanvasIdx ?? this.currentOpenedIndex][isSaveToInsta ? 'instaUrlWithoutLogo' : 'urlWithoutLogo'] = staticCanvas ? staticCanvas.toDataURL({format: 'jpeg'}) : this.canvas.toDataURL({format: 'jpeg'});
      if (staticCanvasIdx !== null) {
        staticCanvas!.getObjects()[1].visible = true;
      } else {
        this.canvas.getObjects()[1].visible = true;
      }
      if (staticCanvasIdx === null) {
      if (newIdx !== null) {
        this.currentOpenedIndex = newIdx;
        if (this.currentOpenedIndex !== -1) {
          this.canvas.clear();
          await this.loadToCanvas(this.currentOpenedIndex, isLoadinsta);
        } else {
          //this.isLoadedToCanvas=false;
        }
      }
      }
    }
  }


  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  canvasAdjustFilter(filterName: string, value:number, toOnlyLogo = false) {
    const upFilterName = this.capitalizeFirstLetter(filterName);
    let isFirst=true;
    this.canvas.getObjects().forEach((obj: any) => {
      if (isFirst && toOnlyLogo) {
        return;
      }
      if (!toOnlyLogo && !isFirst && this.filtersNotAffectLogo) {
        return;
      }
      isFirst=false;
      if (obj && obj.type === 'image') {
        //@ts-ignore
        let existingFilterIndex = obj.filters!.findIndex(filter => filter instanceof fabric.Image.filters[upFilterName]);
        if (existingFilterIndex === -1) {
          existingFilterIndex = obj.filters!.length;
        }
        const props: any = {};
        props[filterName] = value;
        //@ts-ignore
        obj.filters![existingFilterIndex] = new fabric.Image.filters[upFilterName](props);
        obj.applyFilters();
        this.canvas.renderAll();
      }
    });
  }

  canvasResetFilter(filterName: string, affectOnlyLogo = false) {
    const upFilterName = this.capitalizeFirstLetter(filterName);
    let isFirst=true;
    this.canvas.getObjects().forEach((obj: any) => {
      if (affectOnlyLogo && isFirst) {
        return;
      }
      if (!affectOnlyLogo && !isFirst && this.filtersNotAffectLogo) {
        return;
      }
      isFirst=false;
      if (obj && obj.type === 'image') {
        //@ts-ignore
        let existingFilterIndex = obj.filters!.findIndex(filter => filter instanceof fabric.Image.filters[upFilterName]);
        if (existingFilterIndex >= 0) {
          obj.filters!.splice(existingFilterIndex, 1);
        }
        obj.applyFilters();
        this.canvas.renderAll();

      }
    });
  }


  canvasAdjustBrightness(value: number) {
    this.canvasAdjustFilter('brightness', value);
  }

  canvasAdjustTemperature(value: number) {
    this.canvasAdjustFilter('temperature', value);
  }

  canvasResetBrightness() {
    this.canvasResetFilter('brightness');
    this.brightnessSlider.nativeElement.value = 0;
  }

  canvasResetLogoTransparency() {
      let object = this.canvas.getObjects()[1];
      object.set('opacity', this.defaultOpacity);
      this.canvas.renderAll();
    this.logoTransparencySlider.nativeElement.value = this.defaultOpacity;
  }

  canvasResetSaturation() {
    this.canvasResetFilter('saturation');
    this.saturationSlider.nativeElement.value = 0;
  }
  canvasResetTemperature() {
    this.canvasResetFilter('temperature');
    this.temperatureSlider.nativeElement.value = 0;
  }

  canvasResetContrast() {
    this.canvasResetFilter('contrast');
    this.contrastSlider.nativeElement.value = 0;
  }


  moveLeft(index: number) {
    if (index > 0) {
      if (index === this.currentOpenedIndex) {
        this.currentOpenedIndex--;
      }

      const leftItem = this.files[index - 1];
      this.files[index - 1] = this.files[index];
      this.files[index] = leftItem;
    }
  }

  moveFirst(index: number) {
    if (index > 0 && index < this.files.length) {
      if (index === this.currentOpenedIndex) {
        this.currentOpenedIndex=0;
      }
      const item = this.files[index];
      this.files.splice(index, 1);  // removes item from its current position
      this.files.unshift(item);   // adds item to the first position
    }
  }


  drop(event: CdkDragDrop<File[]>) {
    if (event.previousIndex === event.currentIndex) return;

    moveItemInArray(this.files, event.previousIndex, event.currentIndex);
  }

  moveRight(index: number) {
    if (index < this.files.length - 1) {
      if (index === this.currentOpenedIndex) {
        this.currentOpenedIndex++;
      }
      const rightItem = this.files[index + 1];
      this.files[index + 1] = this.files[index];
      this.files[index] = rightItem;
    }
  }

  moveLast(index: number) {
    if (index >= 0 && index < this.files.length) {
      if (index === this.currentOpenedIndex) {
        this.currentOpenedIndex=this.files.length-1;
      }
      const item = this.files[index];
      this.files.splice(index, 1);  // removes item from its current position
      this.files.push(item);   // adds item to the last position
    }
  }

  /*adjustTemperatureOnCanvas(temperature: number): void {
    const fabricImage = this.files[this.currentOpenedIndex].fabric;
    let originalScaleX = fabricImage.scaleX;
    let originalScaleY = fabricImage.scaleY;
    let originalWidth = fabricImage.width! * originalScaleX!;
    let originalHeight = fabricImage.height! * originalScaleY!;

    let tempCanvas = document.createElement('canvas');
    let context = tempCanvas.getContext('2d');

    if (context) {
      let imgWidth = fabricImage.getScaledWidth();
      let imgHeight = fabricImage.getScaledHeight();

      tempCanvas.width = originalWidth;
      tempCanvas.height = originalHeight;

      // Draw the fabric image onto the context
      context.drawImage(fabricImage.getElement(), 0, 0, imgWidth, imgHeight, 0, 0, originalWidth, originalHeight);

      // Get existing ImageData from the context
      let imageData = context.getImageData(0, 0, originalWidth, originalHeight);

      // Adjust temperature
      for (let i = 0; i < imageData.data.length; i += 4) {
        imageData.data[i] = Math.max(0, Math.min(255, (imageData.data[i] - 128) * temperature + 128));
        imageData.data[i + 2] = Math.max(0, Math.min(255, (imageData.data[i + 2] - 128) * (2 - temperature) + 128));
      }

      // Put the modified ImageData back on the context
      context.putImageData(imageData, 0, 0);

      let dataUrl = tempCanvas.toDataURL('image/png');

      fabric.Image.fromURL(dataUrl, (imageContent) => {
        imageContent.scaleX = originalScaleX;
        imageContent.scaleY = originalScaleY;
        imageContent.setCoords();
        this.canvas.remove(fabricImage);
        this.canvas.add(imageContent);
        this.canvas.renderAll();
      }, {crossOrigin: 'anonymous'});
    }
  }*/

  async onFilesSelected(event: any): Promise<void> {
    const files = event.target.files;
    await this.parseFiles(files);
  }

  onDragOver(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();
    const dataTypes = event.dataTransfer?.types;
    const isFileBeingDragged = dataTypes && Array.from(dataTypes).includes('Files');
    if (isFileBeingDragged) {
      this.dragOver = true;
    }
  }


  onLogoDragOver(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();
    const dataTypes = event.dataTransfer?.types;
    const isFileBeingDragged = dataTypes && Array.from(dataTypes).includes('Files');
    if (isFileBeingDragged) {
      this.logoDragOver = true;
    }
  }

  onDragLeave(): void {
    this.dragOver = false;
  }
  onLogoDragLeave(): void {
    this.logoDragOver = false;
  }

  async parseFiles(files: FileList) {
    this.isProcessingAddedImages=true;
    if (!files) {
      return;
    }
    if (Array.from(files).length === 0) {
      console.info('no files detected');
      return;
    }
    if (this.areValidImageFiles(files)) {
      console.info('VALID IMAGES');
      const loadedFiles = await Promise.all(Array.from(files).map(async (file: File) => {
        const imgFile = await this.readImageFile(file);
        const dim = await this.readImageFileDimensions(file);
        let orientation: 'landscape' | 'portrait' | 'box' = 'box';
        if (dim.width > dim.height) {
          orientation='landscape';
        } else if (dim.width < dim.height) {
          orientation='portrait';
        }
        const loadedFile: ImageFile = {
          file,
          orientation,
          url: imgFile, // TODO: need to save with logo
          originalUrl: imgFile,
          urlWithoutLogo: imgFile,
          instaUrl: imgFile,
          instaUrlWithoutLogo: imgFile,
          canvasState: null,
          instaCanvasState: null
        };
        return loadedFile;
      }));
      for (let loadedFile of loadedFiles) {
        this.files.push(loadedFile);
        const curLastIdx =this.files.length-1;
        await this.createCanvas(this.files[curLastIdx].originalUrl, curLastIdx);
        await this.createCanvas(this.files[curLastIdx].originalUrl, curLastIdx, true);
      }
    } else {
      alert('Invalid file format. Please drop image files.');
    }
    this.isProcessingAddedImages=false;
  }

  async onDrop(event: DragEvent): Promise<void> {
    this.dragOver=false;
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer?.files;
    await this.parseFiles(files!);

  }

  // This method serializes the current state of the canvas to a JSON string
  saveCanvasState(staticCanvas: fabric.StaticCanvas|null = null): string {
    let state: CanvasState = {
      canvas: staticCanvas === null ? this.canvas.toJSON() : staticCanvas.toJSON(),
      width: staticCanvas === null ?  this.canvas.getWidth() : staticCanvas.getWidth(),
      height: staticCanvas === null ? this.canvas.getHeight() : staticCanvas.getHeight(),
      brightness: this.brightnessSlider.nativeElement.value,
      saturation: this.saturationSlider.nativeElement.value,
      grayScale: this.grayscaleToggle.checked,
      contrast: this.contrastSlider.nativeElement.value,
      temperature: this.temperatureSlider.nativeElement.value,
      logoTransparency: this.logoTransparencySlider.nativeElement.value
    };
    return JSON.stringify(state);
  }

  // This method aligns the second object in the canvas vertically
  alignSecondObjectVertically() {
    let object = this.canvas.getObjects()[1];
    if (object) {
      object.set({ top: this.canvas.getHeight() / 2, originY: 'center' });
      this.canvas.renderAll();
    } else {
      console.warn("No second object found in canvas.");
    }
  }

// This method aligns the second object in the canvas horizontally
  alignSecondObjectHorizontally() {
    let object = this.canvas.getObjects()[1];
    if (object) {
      object.set({ left: this.canvas.getWidth() / 2, originX: 'center' });
      this.canvas.renderAll();
    } else {
      console.warn("No second object found in canvas.");
    }
  }

// This method loads a previously saved state into the canvas
  async loadCanvasState(json: string, isInsta: boolean, toHiddenStaticCanvas: boolean,   orientation: 'landscape' | 'portrait' | 'box') {
    return new Promise((resolve, reject) => {
    if (json === null) {
      reject('json is null!');
    }
    console.info('load canvas state insta: ' + isInsta + ' to static: ' + toHiddenStaticCanvas);
    //console.info(json);
    // Parse the JSON string
    let state: CanvasState = JSON.parse(json);
    let curCanvas = toHiddenStaticCanvas ? this.hiddenStaticCanvas : this.canvas;

    // Clear the canvas
    curCanvas.clear();

    // Restore the canvas dimensions

    // Load the state from the objects in the JSON string
    curCanvas.loadFromJSON(state.canvas, ()=>{
      const canvasObjects = this.canvas.getObjects();
      console.info(`canvas load from json amount of elements: ${canvasObjects.length}`);
      if (canvasObjects.length > 0) {
        let firstObj = canvasObjects[0];
        if (isInsta) {
          firstObj.set({
            lockMovementX: orientation == 'portrait',
            lockMovementY: orientation == 'landscape',
            selectable: true,
          });
        } else {
          firstObj.set({
            lockMovementX: true,
            lockMovementY: true,
            selectable: false,
          });

        }
      }
      if (!toHiddenStaticCanvas) {
        this.brightnessSlider.nativeElement.value = state.brightness;
        this.saturationSlider.nativeElement.value = state.saturation;
        this.contrastSlider.nativeElement.value = state.contrast;
        this.temperatureSlider.nativeElement.value = state.temperature;
        this.grayscaleToggle.checked = state.grayScale;
        this.logoTransparencySlider.nativeElement.value = state.logoTransparency;
      }
      curCanvas.setDimensions({ width: state.width, height: state.height });
      // Make sure to re-render the canvas after state is loaded
      curCanvas.renderAll();
      resolve(null);
    });
  });
  }

  async resetLogo() {
    let logo = this.canvas.getObjects()[1];
    if (logo) {
      this.canvas.remove(logo); // remove the old logo
    }
    await this.addLogoToCanvas(); // re-add the logo
  }


  async addLogoToCanvas(staticCanvas: any = null) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      fabric.Image.fromURL(this.logoImageUrl, (img) => {
        // You can set various properties of the image here

        // Check if it's portrait or landscape
        let scaleRatio = this.canvas.getWidth() > this.canvas.getHeight() ? 0.5 : 0.5;

        img.set({
          left: (staticCanvas ? staticCanvas.getWidth() : this.canvas.getWidth()) / 2, // set left to center of canvas
          top: (staticCanvas ? staticCanvas.getHeight() : this.canvas.getHeight()) / 2, // set top to center of canvas
          originX: 'center', // set origin of x to center
          originY: 'center', // set origin of y to center
          opacity: this.defaultOpacity,
          lockRotation: true,
          lockUniScaling: true,
          lockSkewingY: true,
          lockSkewingX: true,
          lockScalingFlip: true
        });
        if (staticCanvas) {
          img.scaleToWidth(staticCanvas.getWidth() * scaleRatio);
        } else {
          img.scaleToWidth(this.canvas.getWidth() * scaleRatio);
        }

        if (staticCanvas === null) {
          this.canvas.add(img);
          this.canvas.renderAll();
        } else {
          staticCanvas.add(img);
          staticCanvas.renderAll();
        }
        resolve(null);
      });
    });
  }

  /*async onLogoDrop(event: DragEvent): Promise<void> {
    this.logoDragOver=false;
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer?.files;
    if (!files) {
      return;
    }
    if (Array.from(files).length === 0) {
      console.info('no files detected');
      return;
    }
    if (this.areValidImageFiles(files)) {
      this.showLogoPreview(files[0]);
    } else {
      alert('Invalid file format. Please drop image files.');
    }
  }*/


  async removeFile(i: number) {
    if (this.files[i].url) URL.revokeObjectURL(this.files[i].url); // free memory
    this.files.splice(i, 1);
    if (i === this.currentOpenedIndex) {
      if (i >= this.files.length) {
        this.currentOpenedIndex = i - 1;
      } else {
        this.currentOpenedIndex = i;
      }
      await this.loadToCanvas(this.currentOpenedIndex, this.isLoadedToCanvasInsta);
    }
  }

  duplicateFile(i: number) {
      if (i<this.currentOpenedIndex) {
        this.currentOpenedIndex++;
      }
    const fileToDuplicate = this.files[i];

    // Duplicate and insert into the files array
    this.files.splice(i, 0, fileToDuplicate);
  }


  readImageFile(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target?.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  readImageFileToFabric(file: File): Promise<fabric.Image> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        fabric.Image.fromURL(event.target?.result as string, (img) => {
            img.filters = img.filters || []
            resolve(img);
          },
          {crossOrigin: 'anonymous'});
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }



  private areValidImageFiles(files: FileList): boolean {
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    console.info(files);
    return Array.from(files).every(file => allowedTypes.includes(file.type));
  }


  protected readonly Number = Number;
  isClickedSave = false;

  alignSecondObjectCenter() {
    let object = this.canvas.getObjects()[1];
    if (object) {
      object.set({
        top: this.canvas.getHeight() / 2,
        left: this.canvas.getWidth() / 2,
        originX: 'center',
        originY: 'center'
      });
      this.canvas.renderAll();
    } else {
      console.warn("No second object found in canvas.");
    }
  }

  handleDragStart(i: number, $event: DragEvent) {
    console.info('drag start');
       $event.dataTransfer!.setData("text/plain", i.toString());

    // $event.preventDefault();
  }

  imagesDragOver(event: DragEvent) {
    console.info('drag over');

    // Changing the mouse cursor during drag and drop
    // event.preventDefault();
    // event.dataTransfer!.dropEffect = "move";
  }

  handleDragOver(event: DragEvent) {
    event.preventDefault();
    const dropArea = event.currentTarget as HTMLElement;
    //dropArea.style.backgroundColor = 'red';
  }

  handleDragLeave(event: DragEvent) {
    event.preventDefault();
    const dropArea = event.currentTarget as HTMLElement;
    dropArea.style.backgroundColor = 'transparent';
  }

  handleDrop(i: number, event: DragEvent) {
    event.preventDefault();
    // drop event happened
    const dropX = event.clientX;

    // getBoundingClientRect returns the size of an element and its position relative to the viewport
    const dropArea = (event.target as HTMLElement).getBoundingClientRect();

    const dropXRelativeToDropArea = dropX - dropArea.left; // The x-coordinate relative to the drop area
    const dropPercent = (dropXRelativeToDropArea / dropArea.width) * 100;
    if (dropPercent>50) {
      i++;
    }
    if (i>this.files.length) {
      i--;
    }
    let j = parseInt(event.dataTransfer!.getData("text/plain"));
    if (i != j) {
      const curFile = this.files[this.currentOpenedIndex];
      moveItemInArray(this.files, j, i);
      this.currentOpenedIndex = this.files.indexOf(curFile);

      console.info(`moved ${j} to ${i}`);
    }

  }

  falseEvent(event: Event) {
    event.preventDefault();
  }


}
