

<div class="upload-container"
     [ngClass]="{'drag-over': dragOver}"
     (dragover)="onDragOver($event)"
     (dragleave)="onDragLeave()"
     (drop)="onDrop($event)">
  <input class="file-input" #fileInput type="file" multiple (change)="onFilesSelected($event)" accept="image/*" />
  <button class="custom-button" (click)="fileInput.click()">Choose files...</button>
  <div class="drag-text">{{dragOver ? 'Drop files here!' : 'Or drag & drop them here'}}</div>
</div>
<h3 *ngIf="files.length>0">{{files.length}} images loaded!</h3>
<div class="images-container">
  <div style="display: flex; padding: 0.5em"  *ngFor="let file of files; let i = index"  draggable="true"
       (dragstart)="handleDragStart(i, $event)"
       (dragover)="handleDragOver($event)"
       (dragleave)="handleDragLeave($event)"
       (drop)="handleDrop(i,  $event)">


      <div class="image-wrapper">
      <img *ngIf="!isViewThumbnailsInstagramMode" [src]="file.url" alt="uploaded image" class="img-to-select" draggable="false"
           (click)="isLoadedToCanvas ? saveCanvas(i, null, null, isLoadedToCanvasInsta, false)  : loadToCanvas(i)" />
      <img *ngIf="isViewThumbnailsInstagramMode" [src]="file.instaUrl" alt="uploaded image" class="img-to-select" draggable="false"
           (click)="isLoadedToCanvas ? saveCanvas(i, null, null, isLoadedToCanvasInsta, true)  : loadToCanvas(i, true)" />
    <div class="button-container">
      <button mat-icon-button color="primary" (click)="moveFirst(i)" [disabled]="i === 0">
        <mat-icon>fast_rewind</mat-icon>
      </button>
      <button mat-icon-button color="primary" (click)="moveLeft(i)" [disabled]="i === 0">
        <mat-icon>arrow_left</mat-icon>
      </button>
      <button mat-icon-button color="warn" (click)="removeFile(i)">X</button>
      <button *ngIf="isViewThumbnailsInstagramMode" mat-icon-button color="warn" (click)="duplicateFile(i)"><mat-icon>file_copy</mat-icon></button>
      <!-- Disable moveRight button if the image is the last in the list -->
      <button mat-icon-button color="primary" (click)="moveRight(i)" [disabled]="i === files.length - 1">
        <mat-icon>arrow_right</mat-icon>
      </button>
      <button mat-icon-button color="primary" (click)="moveLast(i)" [disabled]="i === files.length - 1">
        <mat-icon>fast_forward</mat-icon>
      </button>
    </div>
      </div>

</div>
</div>
<div *ngIf="files.length>0 && !isLoadedToCanvas">
  <button mat-raised-button (click)="loadToCanvas(this.currentOpenedIndex)" [disabled]="isProcessingAddedImages">start editing images</button>
</div>

<div class="canvas-n-btns" [ngStyle]="{ 'display': isLoadedToCanvas ? 'flex' : 'none' }">
<div class="my-canvas-container">
  <canvas #fabricCanvas id="fabricCanvas"></canvas>
</div>

<div class="canvas-effects" *ngIf="currentOpenedIndex !== null" [style.background-color]="isLoadedToCanvasInsta ? '#a1b3c5' : '#d9c4d1'">
  <div *ngIf="isLoadedToCanvas">
   <!-- <h4>elements in canvas {{canvas.getObjects().length}}</h4> -->
    <h4>{{isLoadedToCanvasInsta ? "Instagram" : "Regular"}} Image</h4>
    <h5>{{currentOrientation()}}</h5>
  </div>
  <mat-slide-toggle [(ngModel)]="isViewThumbnailsInstagramMode">Thumbnail Instagram Mode</mat-slide-toggle>
  <div class="effect-group">
    <label class="effect-label">Brightness</label>
    <button mat-icon-button color="primary" (click)="canvasResetBrightness()">
      <mat-icon>autorenew</mat-icon>
    </button>
    <div class="slider-wrap">
      <input type="range" min="-1" max="1" value="0" step="0.01"
             #brightnessSlider (input)="canvasAdjustBrightness(Number(brightnessSlider.value))" class="effect-slider">
      <span class="slider-value">{{brightnessSlider.value}}</span>
    </div>
  </div>
  <div class="effect-group">
    <label class="effect-label">Saturation</label>
    <button mat-icon-button color="primary" (click)="canvasResetSaturation()">
      <mat-icon>autorenew</mat-icon>
    </button>
    <div class="slider-wrap">
      <input type="range" min="-1" max="1" value="0" step="0.01"
             #saturationSlider (input)="canvasAdjustSaturation(Number(saturationSlider.value))" class="effect-slider">
      <span class="slider-value">{{contrastSlider.value}}</span>
    </div>
  </div>
  <div class="effect-group">
    <label class="effect-label">Contrast</label>
    <button mat-icon-button color="primary" (click)="canvasResetContrast()">
      <mat-icon>autorenew</mat-icon>
    </button>
    <div class="slider-wrap">
      <input type="range" min="-1" max="1" value="0" step="0.01"
             #contrastSlider (input)="canvasAdjustContrast(Number(contrastSlider.value))" class="effect-slider">
      <span class="slider-value">{{contrastSlider.value}}</span>
    </div>
  </div>
  <div class="effect-group">
      <label>Grayscale</label>
      <mat-slide-toggle #grayscaleToggle  (change)="canvasAdjustGrayScale(grayscaleToggle.checked)" />
  </div>
  <div class="effect-group">
    <label class="effect-label">Temperature</label>
    <button mat-icon-button color="primary" (click)="canvasResetTemperature()">
      <mat-icon>autorenew</mat-icon>
    </button>
    <div class="slider-wrap">
      <input type="range" min="-1" max="1" value="0" step="0.01"
             #temperatureSlider (input)="canvasAdjustTemperature(Number(temperatureSlider.value))" class="effect-slider">
      <span class="slider-value">{{temperatureSlider.value}}</span>
    </div>
  </div>
  <div class="effect-group">
    <label class="effect-label">Logo Transparency</label>
    <button mat-icon-button color="primary" (click)="canvasResetLogoTransparency()">
      <mat-icon>autorenew</mat-icon>
    </button>
    <div class="slider-wrap">
      <input type="range" min="0" max="1" value="{{defaultOpacity}}" step="0.01"
             #logoTransparencySlider (input)="canvasAdjustLogoTransparency(Number(logoTransparencySlider.value))" class="effect-slider">
      <span class="slider-value">{{logoTransparencySlider.value}}</span>
    </div>
  </div>
  <h3  *ngIf="this.currentOpenedIndex>-1 && this.isLoadedToCanvas">Image {{this.currentOpenedIndex+1}} out of {{this.files.length}}</h3>
  <div class="action-buttons">
    <div class="logo-action">
      LOGO
      <button mat-icon-button matTooltip="Vertical Align" (click)="alignSecondObjectVertically()">
        <mat-icon>vertical_align_center</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Horizontal Align" (click)="alignSecondObjectHorizontally()">
        <mat-icon>drag_handle</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Center Align" (click)="alignSecondObjectCenter()">
        <mat-icon>center_focus_strong</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Reset Logo" (click)="resetLogo()">
        <mat-icon>replay</mat-icon>
      </button>
    </div>
  </div>
  <div class="action-buttons">
    <button  mat-raised-button color="primary" (click)="saveCanvas(currentOpenedIndex-1, null, null, isLoadedToCanvasInsta, isLoadedToCanvasInsta)" [disabled]="this.currentOpenedIndex==0">
      PREV
    </button>
    <button mat-raised-button color="primary" (click)="saveCanvas(currentOpenedIndex+1, null, null, isLoadedToCanvasInsta, isLoadedToCanvasInsta)" [disabled]="this.currentOpenedIndex+1 === files.length">
      NEXT
    </button>
  </div>
    <div  *ngIf="isLoadedToCanvasInsta">
        <!-- Top-Left Align Button -->
      <div>
        <button mat-icon-button (click)="alignTopLeft()" [disabled]="currentOrientation() == 'portrait' ||currentOrientation() == 'landscape' ">
            <mat-icon>north_west</mat-icon>
        </button>

        <!-- Top-Center Align Button -->
        <button mat-icon-button (click)="alignTopCenter()" [disabled]="currentOrientation() == 'landscape'">
            <mat-icon>north</mat-icon>
        </button>

        <!-- Top-Right Align Button -->
        <button mat-icon-button (click)="alignTopRight()" [disabled]="currentOrientation() == 'portrait' ||currentOrientation() == 'landscape'">
            <mat-icon>north_east</mat-icon>
        </button>
      </div>
      <div>

        <!-- Left-Center Align Button -->
        <button mat-icon-button (click)="alignLeft()" [disabled]="currentOrientation() == 'portrait'">
          <mat-icon>west</mat-icon>
        </button>

        <!-- Center Align Button -->
        <button mat-icon-button (click)="alignCenter()">
          <mat-icon>center_focus_strong</mat-icon>
        </button>
        <button mat-icon-button (click)="alignRightCenter()" [disabled]="currentOrientation() == 'portrait'">
          <mat-icon>east</mat-icon>
        </button>
      </div>
        <!-- Right-Center Align Button -->

    <div>
        <!-- Bottom-Right Align Button -->
      <button mat-icon-button (click)="alignBottomLeft()" [disabled]="currentOrientation() == 'portrait' ||currentOrientation() == 'landscape'">
        <mat-icon>south_west</mat-icon>
      </button>

        <!-- Bottom-Center Align Button -->
        <button mat-icon-button (click)="alignBottomCenter()" [disabled]="currentOrientation() == 'landscape'">
            <mat-icon>south</mat-icon>
        </button>
      <button mat-icon-button (click)="alignBottomRight()" [disabled]="currentOrientation() == 'portrait' ||currentOrientation() == 'landscape'">
        <mat-icon>south_east</mat-icon>
      </button>

        <!-- Bottom-Left Align Button -->
    </div>
    </div>
  <div class="action-buttons">
    <button  mat-raised-button color="primary" *ngIf="!isViewThumbnailsInstagramMode" (click)="downloadAndMoveToInstagram()">
      <mat-icon>download</mat-icon> & Move to Instagram
    </button>

    <button  mat-raised-button color="primary" *ngIf="isViewThumbnailsInstagramMode" (click)="downloadInstagramZips()">
      <mat-icon>download</mat-icon> Instagram Images
    </button>
   <!-- <button mat-raised-button color="primary" *ngIf="files.length>0" (click)="downloadZipsWithLogo()">
      <mat-icon>download</mat-icon>
      ZIP WITH LOGO
    </button>
    <button mat-raised-button color="accent" *ngIf="files.length>0" (click)="downloadZipsWithoutLogo()">
      <mat-icon>download</mat-icon>
      ZIP WITHOUT LOGO
    </button>
      <button mat-raised-button color="primary" *ngIf="files.length>0" (click)="downloadZipsWithLogoInsta()">
          <mat-icon>download</mat-icon>
          INSTAGRAM ZIP WITH LOGO
      </button> -->
<!--      <button mat-raised-button color="accent" *ngIf="files.length>0" (click)="downloadZipsWithoutLogoInsta()">-->
<!--          <mat-icon>download</mat-icon>-->
<!--          INSTAGRAM ZIP WITHOUT LOGO-->
<!--      </button>-->

  </div>
</div>
</div>


<canvas #staticCanvas id="staticCanvas" style="display: none"></canvas>
