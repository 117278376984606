<div *ngIf="form" [formGroup]="form" [ngSwitch]="question.component">
    <ng-select *ngSwitchCase="'select-autocomplete'"
        [items]=selectData!
               [formControlName]="question.keyName"
        [bindLabel]="question.selectData!.keyNameForLabel!"
        [bindValue]="question.selectData!.keyNameForValue!"
    >
    </ng-select>

  <app-compass-form-input
    *ngSwitchCase="'direction'"
    title="Exposition" i18n-title [formControlName]="question.keyName" />

    <ng-select *ngSwitchCase="'select-autocomplete-multi'"
        [items]=selectData!
               [placeholder]="question.label || ''"
               [formControlName]="question.keyName"
               [multiple]="true"
        [bindLabel]="question.selectData!.keyNameForLabel!"
        [bindValue]="question.selectData!.keyNameForValue!"
    >
    </ng-select>

  <app-address-generic-fields *ngSwitchCase="'full-address'" [formControlName]="question.keyName" [requestZipCode]="true" [requestFloorNApt]="true" [requestEntryCode]="true" [requestEntrance]="true" />

  <div *ngSwitchCase="'list'">
    <h3 i18n>{{question.label}}</h3>
    <button mat-icon-button (click)="incListItems()"><mat-icon>add</mat-icon></button>
    <button mat-icon-button (click)="decListItems()"><mat-icon>remove</mat-icon></button>
    <ol [formArrayName]="question.keyName">
      <li *ngFor="let item of listControls.controls; let i = index">
        {{question.keyName}}
        <app-dynamic-form  [qform]="question.listItems!" [showSubmit]="false" [formControlName]="i"
                          [selectDataByKeyName]="allData!"></app-dynamic-form>
       </li>
    </ol>
  </div>


  <mat-form-field *ngSwitchCase="'select'">
    <mat-label>{{question.label}}</mat-label>
    <mat-select [formControlName]="question.keyName">
      <mat-option *ngFor="let option of selectData" [value]="option[question.selectData!.keyNameForValue!]">
        {{option[question.selectData!.keyNameForLabel!]}}
      </mat-option>
    </mat-select>
  </mat-form-field>
<mat-form-field *ngSwitchCase="'select-multi'">
    <mat-label>{{question.label}}</mat-label>
    <mat-select multiple [formControlName]="question.keyName">
      <mat-option *ngFor="let option of selectData" [value]="option[question.selectData!.keyNameForValue!]">
        {{option[question.selectData!.keyNameForLabel!]}}
      </mat-option>
    </mat-select>
  </mat-form-field>

    <mat-form-field *ngSwitchCase="'text_box'">
      <mat-label *ngIf="question.label">{{question.label}}</mat-label>
    <input  matInput  [formControlName]="question.keyName"
           [id]="question.keyName" [attr.required]="question.required" />
    </mat-form-field>
  <mat-form-field *ngSwitchCase="'number_box'">
      <mat-label *ngIf="question.label">{{question.label}}</mat-label>
    <input matInput type="number"  [formControlName]="question.keyName"
           [attr.min]="question.type === 'positive_number' ? 1 : null"
           [id]="question.keyName" [attr.required]="question.required">
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'text_area'">
      <mat-label *ngIf="question.label">{{question.label}}</mat-label>
    <textarea matInput  [formControlName]="question.keyName"
               [id]="question.keyName" ></textarea>
    </mat-form-field>

  <mat-slide-toggle [id]="question.keyName" *ngSwitchCase="'toggle'" [formControlName]="question.keyName">{{question.label}}</mat-slide-toggle>

    <select [id]="question.keyName" *ngSwitchCase="'dropdown'" [formControlName]="question.keyName">
      <option *ngFor="let opt of question.values" [value]="opt.key">{{opt.value}}</option>
    </select>


  <mat-error *ngIf="form?.touched && !isValid">{{question.label}} is required</mat-error>
</div>
