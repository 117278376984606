import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter, forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output, QueryList,
  SimpleChanges, ViewChildren
} from '@angular/core';
import {QuestionControlService} from "../../services/question-control.service";
import {ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {QuestionForm, QuestionFormField} from "../../services/question.service";
import {MsgService} from "../../../services/msg.service";

const DEFAULT_GROUP_NAME='';

export interface TuxDynamicFormGroup {
  groupKeyName: string;
  fields: QuestionFormField<any, any>[];
}

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './tux-dynamic-form.component.html',
  styleUrls: ['./tux-dynamic-form.component.scss'],
  providers: [ QuestionControlService,{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TuxDynamicFormComponent),
    multi: true,
  } ]
})
export class TuxDynamicFormComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input({required: true}) qform!: QuestionForm;
  @Input({required: false}) showSubmit: boolean = false;
  @Input({required: false}) selectDataByKeyName: { [key: string]: any }|undefined;

  @Output() ngSubmit: EventEmitter<any | null> = new EventEmitter<any | null>();
  //inlineFormsData : any[] | null= null;
  form!: FormGroup;
  payLoad = '';
  groups: string[] = [];
  groupedData: TuxDynamicFormGroup[] = [];
  @ViewChildren(TuxDynamicFormComponent) dynamicForms!: QueryList<TuxDynamicFormComponent>;

  order=0;
  loading = true;

  @Input() option: any;
  onChanged: Function = () => {};
  onTouched: Function = () => {};


  onValueChanged() {
    if (this.onChanged) {
      this.onChanged(this.form?.getRawValue());
    }
  }

  registerOnChange(fn: Function) {
    this.onChanged = fn;
  }
  registerOnTouched(fn: Function) {
    this.onTouched = fn;
  }
  writeValue(value: any): void {
    //this.form.setValue(value);
    //TODO: is it needed ?
    if (value != null) {
      this.form.patchValue(value, { emitEvent: false });
    }
  }

  constructor(private qcs: QuestionControlService, private msgService: MsgService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.loading = false;
    if (this.qform) {
    this.form = this.qcs.buildForm(this.qform);
    this.form.valueChanges.subscribe(()=>{
      this.onValueChanged();
    });

      for (let field of this.qform.fields) {
        if (field.type?.startsWith('select') && !field.selectData) {
          throw new Error(`key ${field.keyName} ` + (field.groupKeyName ? `from group '${field.groupKeyName}'` : '') + ` is missing selectData property`);
        }
        if (field.groupKeyName) {
          if (!(this.groups.indexOf(field.groupKeyName) >= 0)) {
            this.groups.push(field.groupKeyName);
          }
        } else if (!(this.groups.indexOf(DEFAULT_GROUP_NAME)>=0)) {
          this.groups.push(DEFAULT_GROUP_NAME);
        }
      }
      let lastGroupKeyName = '';
      let currentGroup: QuestionFormField<any, any>[] = [];
      for (let field of this.qform.fields) {
        if (lastGroupKeyName === (field.groupKeyName ?? '')) {
          currentGroup.push(field);
        } else {
          this.groupedData.push({
            fields: currentGroup,
            groupKeyName: lastGroupKeyName
          });
          lastGroupKeyName = (field.groupKeyName ?? '');
          currentGroup= [field];
        }
      }
      if (currentGroup.length>0) {
        this.groupedData.push({
          fields: currentGroup,
          groupKeyName: lastGroupKeyName
        });

      }
    }
  }

  public getRawValue(): any| any[]| null {
    return this.form.getRawValue();
  }

  public getLabel(): string|null {
    return this.qform.label ?? null;
  }


  ngOnInit() {
  }

  getSelectDataByKeyName(keyName: string) {
    return (this.selectDataByKeyName?.[keyName] ?? null);
  }

  onSubmit() {
    this.payLoad = JSON.stringify(this.form.getRawValue());
    if (this.ngSubmit) {
      this.ngSubmit.next(this.form.getRawValue());
    }
  }

  done(isEmpty: boolean) {
    if (isEmpty) {
      this.ngSubmit.next(null);
    }
    if (this.form.invalid) {
      this.msgService.snackMsgWarn('please fill all fields');
      return;
    } else {
      this.ngSubmit.next(this.form.value);
    }
  }

}
