<div style="display: flex; flex-direction: column; margin-top: 1em;">
  <div style="display: flex">
    <button mat-raised-button color="primary" (click)="openTableColumnsDialog()" i18n>Show/Hide Columns</button>
  </div>
<mat-form-field>
  <mat-label>Filter Online</mat-label>
  <input matInput (keyup)="search($any($event.target).value, false)" #input>
</mat-form-field>
<mat-form-field>
  <mat-label>Filter Local Data</mat-label>
  <input matInput (keyup)="applyFilter($event)" #input>
</mat-form-field>
</div>
<div class="mat-elevation-z8" style="position: relative">
  <mat-spinner *ngIf="isLoading" style="margin: 0 auto;"></mat-spinner>
  <table [class.mid-hidden]="isLoading" mat-table [dataSource]="dataSource" matSort
         cdkDropList
         cdkDropListOrientation="horizontal"
         (cdkDropListDropped)="drop($event)"
  >

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Type </th>
      <td mat-cell *matCellDef="let row"> {{row.type}} </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Title </th>
      <td mat-cell *matCellDef="let row"> {{row.title}} </td>
    </ng-container>

  <ng-container matColumnDef="subTitle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Sub Title </th>
      <td mat-cell *matCellDef="let row"> {{row.subTitle}}
        <span *ngIf="row.entrance">כניסה {{row.entrance}}</span>
         </td>
    </ng-container>

  <ng-container matColumnDef="entrance">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Entrance </th>
      <td mat-cell *matCellDef="let row"> {{row.entrance}} </td>
    </ng-container>

<ng-container matColumnDef="itemDesc">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Desc </th>
      <td mat-cell class="td-text-trim" *matCellDef="let row" [matTooltip]="row.itemDesc"> {{row.itemDesc}} </td>
    </ng-container>

<ng-container matColumnDef="item_img">
      <th mat-header-cell *matHeaderCellDef cdkDrag> Img </th>
      <td mat-cell *matCellDef="let row"> <img i18n-alt alt="apt image" [ngSrc]="row.itemImg" width="50" height="50" /> </td>
    </ng-container>

    <ng-container matColumnDef="isFlexible">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Flexible </th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="!row.isFlexible">close</mat-icon>
        <mat-icon *ngIf="row.isFlexible">done</mat-icon>
         </td>
    </ng-container>

    <ng-container matColumnDef="realEstateOffice">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> RealEstate </th>
      <td mat-cell *matCellDef="let row"> {{row.realEstateOffice}} </td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Price </th>
      <td mat-cell *matCellDef="let row"> {{row.price}} </td>
    </ng-container>

    <ng-container matColumnDef="rooms">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Rooms </th>
      <td mat-cell *matCellDef="let row"> {{row.rooms}} </td>
    </ng-container>

    <ng-container matColumnDef="floor">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Floor </th>
      <td mat-cell *matCellDef="let row"> {{row.floor}} </td>
    </ng-container>

    <ng-container matColumnDef="squareMeters">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Square Meters" i18n-matTooltip cdkDrag> Sq. M. </th>
      <td mat-cell *matCellDef="let row" class="square-meters-td" >
        <div *ngIf="row.squareMeters"><mat-icon matTooltip="Square Meters" i18n-matTooltip>square_foot</mat-icon><br/>{{row.squareMeters}}</div>
        <div *ngIf="row.squareMetersTotal"><mat-icon matTooltip="Square Meters Total" i18n-matTooltip>home</mat-icon><br/>{{row.squareMetersTotal}}</div>
        <div *ngIf="row.squareMetersBuild"><mat-icon matTooltip="Square Meters Build" i18n-matTooltip>cottage</mat-icon><br/>{{row.squareMetersBuild}}</div>
        <div *ngIf="row.squareMetersBuiltTotal"><mat-icon matTooltip="Square Meters Build Total" i18n-matTooltip>apartment</mat-icon><br/>{{row.squareMetersBuiltTotal}}</div>
        <div *ngIf="row.squareMetersGarden"><mat-icon matTooltip="Square Meters Garden" i18n-matTooltip>deck</mat-icon><br/>{{row.squareMetersGarden}}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="entranceDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Entr. Date </th>
      <td mat-cell *matCellDef="let row"> {{row.entranceDate}} </td>
    </ng-container>

    <ng-container matColumnDef="conditionOfPlace">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Condition </th>
      <td mat-cell *matCellDef="let row"> {{row.conditionOfPlace}} </td>
    </ng-container>

    <ng-container matColumnDef="balconies">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> balconies </th>
      <td mat-cell *matCellDef="let row"> {{row.balconies}} </td>
    </ng-container>

    <ng-container matColumnDef="floorsInBuilding">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag matTooltip="Floors in Building"> Floors </th>
      <td mat-cell *matCellDef="let row"> {{row.floorsInBuilding}} </td>
    </ng-container>

    <ng-container matColumnDef="parkingSpots">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Parking Spots </th>
      <td mat-cell *matCellDef="let row"> {{row.parkingSpots}} </td>
    </ng-container>

    <ng-container matColumnDef="isExclusive">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Exclusive </th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="!row.isExclusive">close</mat-icon>
        <mat-icon *ngIf="row.isExclusive">done</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="commodities">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Commodities </th>
      <td mat-cell *matCellDef="let row">
        <mat-list style="display: flex">
          <mat-list-item *ngFor="let item of commodities">
          <mat-icon [class.comm_disabled]="!row[item.name]" [matTooltip]="item.title">{{item.icon}}</mat-icon>
          </mat-list-item>
        </mat-list>
      </td>
    </ng-container>

    <ng-container matColumnDef="sellers">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Sellers </th>
      <td mat-cell *matCellDef="let row" style="display: flex">
      <div *ngFor="let seller of row.sellers">
        {{seller.name}}:<a href="tel:{{seller.number}}">{{seller.number}}</a>
      </div>
      </td>
    </ng-container>
<ng-container matColumnDef="numOfPayments">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag matTooltip="Number of Payments"> N. Payments </th>
      <td mat-cell *matCellDef="let row"> {{row.numOfPayments}} </td>
    </ng-container>

<ng-container matColumnDef="vaadBaitPerMonth">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag matTooltip="Vaad Bait Per Month"> Vaad </th>
      <td mat-cell *matCellDef="let row"> {{row.vaadBaitPerMonth}} </td>
    </ng-container>

    <ng-container matColumnDef="arnonnaPer2Months">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag matTooltip="Arnona every 2 months"> Arnona </th>
      <td mat-cell *matCellDef="let row"> {{row.arnonnaPer2Months}} </td>
    </ng-container>
<ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Created </th>
      <td mat-cell *matCellDef="let row" [matTooltip]="row.createdAt">
        <ng-container *ngIf="row.createdAt">{{row.createdAt | timeago}}</ng-container>
       </td>
    </ng-container>

<ng-container matColumnDef="updatedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> Updated </th>
      <td mat-cell *matCellDef="let row" [matTooltip]="row.updatedAt">
        <ng-container *ngIf="row.updatedAt">{{row.updatedAt | timeago}}</ng-container> </td>
    </ng-container>

<ng-container matColumnDef="cmds">
      <th mat-header-cell *matHeaderCellDef cdkDrag> Cmds </th>
  <td mat-cell *matCellDef="let row"> <a [href]="row.itemUrl" target="_blank">Open</a> </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4" *ngIf="!isLoading">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[100, 200, 500, 1000]" aria-label="Select page of users"></mat-paginator>
</div>
