import {Component, OnDestroy, OnInit} from '@angular/core';
import {GraphqlService} from '../../graphql/graphql.service';
import {Subscription} from 'rxjs';
import {
  AptProspectFloorChangeDialogData,
  AptProspectStatus,
  AptProspectStatusOptional,
  ProspectStatusType
} from './my-types';
import {keyAscComparator, keyDescComparator} from './my-keyvalue-compare';
import {QueryRef} from "apollo-angular";
import {AddAptProspectDialogComponent} from "../../crm-components/add-apt-prospect-dialog/add-apt-prospect-dialog.component";
import {MsgService} from "../../services/msg.service";
import {ActivatedRoute, Router} from "@angular/router";
import {
  AddAptProspectFloorDialogComponent
} from "../../crm-components/add-apt-prospect-floor-dialog/add-apt-prospect-floor-dialog.component";
import {
  AddAptProspectChangeEntranceDialogComponent
} from "../../crm-components/add-apt-prospect-change-entrance-dialog/add-apt-prospect-change-entrance-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-prospect-building',
  templateUrl: './prospect-building.component.html',
  styleUrls: ['./prospect-building.component.scss']
})
export class ProspectBuildingComponent implements OnInit, OnDestroy {

  keyDescFn  = keyDescComparator;
  keyAscFn = keyAscComparator;

  constructor(private gqlService: GraphqlService, private msgService: MsgService, private dialog: MatDialog,
              private actRoute: ActivatedRoute, private router: Router) { }

  private prospectStatusTypesSubscription: Subscription|undefined;
  private prospectStatusTypesQueryRef: QueryRef<any>|undefined;
  public statusTypes: {[key: number]: ProspectStatusType}={};
  public aptStatus: {[key: string] : AptProspectStatus[]} = {};
  public address='';
  public entrance: string|null=null;

  public floorsSortOrder: 'asc'|'desc'='desc';

  isEmpty() {
    return Object.keys(this.aptStatus).length== 0;
  }

  ngOnInit(): void {
    this.address=this.actRoute.snapshot.params['address'];
    this.entrance=this.actRoute.snapshot.params.hasOwnProperty('entrance') ?
      this.actRoute.snapshot.params['entrance'] : null;
    this.prospectStatusTypesQueryRef = this.gqlService.getAptProspectStatusNTypes(this.address, this.entrance);
    this.prospectStatusTypesSubscription=this.prospectStatusTypesQueryRef.
    valueChanges.subscribe(({data})=>{
      this.aptStatus={};
      data.prospectStatusTypes.forEach((row: ProspectStatusType)=>{
        this.statusTypes[row.id!]=row;
      })
      data.aptProspectStatus.forEach((row: AptProspectStatus)=>{
        if (!this.aptStatus.hasOwnProperty(row.floor)) {
          this.aptStatus[row.floor]=[];
        }
        this.aptStatus[row.floor].push(row);
      });
    });
  }

  openEditDialog(apt: AptProspectStatus): void {
    const dialogRef = this.dialog.open(AddAptProspectDialogComponent, {
      //width: '250px',
      data: apt,
    });

    dialogRef.afterClosed().subscribe((result: AptProspectStatus | undefined) => {
      if (result !== undefined) {
        const aptStatusId = result.id!;
        if (result.cmd === 'delete') {
          this.gqlService.deleteAptProspectStatus(aptStatusId).subscribe((data: any) => {
            if (data.data.deleteAptProspectStatus) {
              this.msgService.snackMsgSuccess('deleted prospect successfully!');
              this.prospectStatusTypesQueryRef?.refetch();
            } else {
              this.msgService.snackMsgSuccess('there was a problem deleting this prospect!');
            }
          });
        } else {
          //@ts-ignore
          delete result.id;
          this.gqlService.updateAptProspectStatus(aptStatusId, result).subscribe((data: any) => {
            if (data.data.updateAptProspectStatus) {
              this.msgService.snackMsgSuccess('added updated successfully');
              this.prospectStatusTypesQueryRef?.refetch();
            } else {
              this.msgService.snackMsgError('there was a problem updating an apartment');
            }
          });
        }
      }
    });
  }

  buildNavAndRedirect(address: string, entrance: string|null, is_rename: boolean = true, isRefresh: boolean = false) {
    this.entrance=entrance;
    const nav = ['/', 'prospect-building', address];
    if (entrance !== null && entrance !== undefined) {
      nav.push(entrance);
    }
    this.router.navigate(nav).then(()=>{
      if (isRefresh) {
        this.prospectStatusTypesQueryRef?.refetch({address: this.address, entrance: this.entrance});
      }
      this.msgService.snackMsgSuccess(is_rename ? 'renamed' : 'changed' + " entrance successfully");
    });


  }



  openChangeEntranceDialog(isRename: boolean) {
    const dialogRef = this.dialog.open(AddAptProspectChangeEntranceDialogComponent, {
      //  width: '250px',
      data: {is_rename: isRename, entrance: this.entrance}
    });
    dialogRef.afterClosed().subscribe( (entrance: string|undefined|null) => {
      if (entrance !== undefined) {
        if (isRename) {
        this.gqlService.updateAddressEntrance(this.address,this.entrance, entrance).subscribe((data: any)=>{
          if (data.data.changeAddressEntrance) {
            this.buildNavAndRedirect(this.address, entrance, isRename);
          } else {
            this.msgService.snackMsgError("failed changing entrance");
          }
        });
      } else {
          this.buildNavAndRedirect(this.address, entrance, isRename, true);
        }
      }
    });

  }

  openFloorDialog(floor:string) {
    const dialogRef = this.dialog.open(AddAptProspectFloorDialogComponent, {
      //  width: '250px',
      data: floor
    });
    dialogRef.afterClosed().subscribe( (data: AptProspectFloorChangeDialogData|undefined) => {
      if (data !== undefined) {
        this.gqlService.changeAptProspectStatusFloor(this.address,this.entrance,
          data.old_floor, data.new_floor, data.allow_existing_floor).subscribe((data: any)=>{
            if (data.data.changeAptProspectStatusFloor) {
              this.msgService.snackMsgSuccess("changed floor successfully");
              this.prospectStatusTypesQueryRef?.refetch();
            } else {
              this.msgService.snackMsgError("failed renaming floor");
            }
        });
      }
    });
  }
  openAddDialog(data: AptProspectStatusOptional): void {
    const dialogRef = this.dialog.open(AddAptProspectDialogComponent, {
    //  width: '250px',
      data
    });

    dialogRef.afterClosed().subscribe( (result: AptProspectStatus|undefined) => {
      if (result !== undefined) {
        // @ts-ignore
        delete result.id;
        this.gqlService.addAptProspectStatus(this.address, this.entrance, result).subscribe((data: any)=>{
          if (data.data.addAptProspectStatus) {
            this.msgService.snackMsgSuccess('added apartment successfully');
            this.prospectStatusTypesQueryRef?.refetch();
          } else {
            this.msgService.snackMsgError('there was a problem adding an apartment');
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.prospectStatusTypesSubscription?.unsubscribe();
  }

}
