import {FocusMonitor} from '@angular/cdk/a11y';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
  forwardRef, NgZone,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';
import {Subject} from 'rxjs';
import {MsgService} from "./services/msg.service";
import {GraphqlService} from "./graphql/graphql.service";


@Component({ template: '' ,
  providers: [{provide: MatFormFieldControl, useExisting:MyMatFormFieldAbstract}],
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
  }
)
export abstract class MyMatFormFieldAbstract<T = any> implements MatFormFieldControl<T>, ControlValueAccessor, OnDestroy{
  static nextId = 0;

  parts: FormGroup|undefined;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  id = `mat-form-field-abstract-${MyMatFormFieldAbstract.nextId++}`;
  onChange = (_: any) => {};
  onTouched = () => {};

  isEmpty(formGroup: FormGroup): boolean {
  return Object.keys(formGroup.controls).every(key => !formGroup!.get(key)!.value);
}

  // @ts-ignore
  get empty() {
    return this.isEmpty(this.parts!);
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy!: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder!: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts!.disable() : this.parts!.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): T | null {
    return this.parts!.value;
  }
  set value(val: T | null) {
    Object.keys(this.parts!.controls).forEach(key => {
      // Check if val is not null before trying to access val[key]
        //@ts-ignore
        const value = val ? val[key] ?? null : null;
        this.parts!.get(key)?.setValue(value);
    });
    this.stateChanges.next();
  }

  get errorState(): boolean {
    if (this.parts) {
      return this.parts.invalid && this.touched;
    } else {
      return false;
    }
  }

  constructor(
    protected formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    protected ngZone: NgZone,
    protected msgService: MsgService, protected gqlService: GraphqlService,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }


  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.example-tel-input-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    // Save a reference to this to use inside the forEach callback function
    let self = this;

    // Convert the controls object into an array and start iterating
    Object.keys(this.parts!.controls).forEach(function(key) {
      // Check if the current control is valid
      if (self.parts!.controls[key].valid) {
        // If the control is valid, ensure it's focused appropriately
        // @ts-ignore
        self._focusMonitor.focusVia(self[key + 'Input'], 'program');
        // Then return to stop further processing
        return;
      }
    });
  }

  writeValue(tel: T | null): void {
    this.value = tel;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    //this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }
}
