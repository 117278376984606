import {KeyValue} from '@angular/common';
import {Pipe} from "@angular/core";

export function keyDescComparator<K, V>(
  keyValueA: KeyValue<K, V>, keyValueB: KeyValue<K, V>): number {
  const a = keyValueA.key;
  const b = keyValueB.key;
  if (a === b) {
    return 0;
  }
  return a < b ? 1 : -1;
}

export function keyAscComparator<K, V>(
  keyValueA: KeyValue<K, V>, keyValueB: KeyValue<K, V>): number {
  const a = keyValueA.key;
  const b = keyValueB.key;
  if (a === b) {
    return 0;
  }
  return a < b ? -1 : 1;
}
