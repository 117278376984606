import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from './site-pages/home/home.component';
import {ProspectBuildingComponent} from './crm-pages/prospect-building/prospect-building.component';
import {ProspectStatusTypeComponent} from './crm-pages/prospect-status-type/prospect-status-type.component';
import {AdminComponent} from "./crm-pages/admin/admin.component";
import {ProspectBuildingMapComponent} from "./crm-pages/prospect-building-map/prospect-building-map.component";
import {EditTableTypeConfigPageComponent} from "./crm-pages/edit-table-type-config-page/edit-table-type-config-page.component";
import {AboutUsComponent} from "./site-components/about-us/about-us.component";
import {BuyComponent} from "./site-components/buy/buy.component";
import {SellComponent} from "./site-components/sell/sell.component";
import {RentalManagementComponent} from "./site-components/rental-management/rental-management.component";
import {UnderConstructionsComponent} from "./site-pages/under-constructions/under-constructions.component";
import {PrivacyPolicyComponent} from "./privacy-policy/privacy-policy.component";
import {TermsAndConditionsComponent} from "./terms-and-conditions/terms-and-conditions.component";
import {DisclaimerComponent} from "./disclaimer/disclaimer.component";
import {CookiesPolicyComponent} from "./cookies-policy/cookies-policy.component";
import {AptHistoryComponent} from "./crm-pages/apt-history/apt-history.component";
import {CreateNewAptPageComponent} from "./crm-pages/create-new-apt-page/create-new-apt-page.component";
import {UploadMultiImagesComponent} from "./crm-components/upload-multi-images/upload-multi-images.component";

export interface TableTypeConfigRouteData {
  get_fnc: string;
  add_fnc: string;
  delete_fnc: string;
  update_fnc: string;
  add_btn_title: string;
  title: string;

}

const routes: Routes = [{path:  '', pathMatch: 'full', redirectTo: 'home'},
  {path: 'terms', component: TermsAndConditionsComponent, data: {'showToolbar': false}},
  {path: 'disclaimer', component: DisclaimerComponent, data: {'showToolbar': false}},
  {path: 'privacy', component: PrivacyPolicyComponent, data: {'showToolbar': false}},
  {path: 'cookies-policy', component: CookiesPolicyComponent, data: {'showToolbar': false}},
  {path: 'about-us', component: AboutUsComponent, data: {'showToolbar': false}},
  {path: 'under-construction', component: UnderConstructionsComponent, data: {'showToolbar': false,
    'showUserToolbar': false}},
  {path: 'buy', component: BuyComponent, data: {'showToolbar': false}},
  {path: 'sell', component: SellComponent, data: {'showToolbar': false}},
  {path: 'rental-management', component: RentalManagementComponent, data: {'showToolbar': false}},
  {path: 'home', component: HomeComponent, data: {'showToolbar': false}},
  {path: 'prospect-building/:address', component: ProspectBuildingComponent},
  {path: 'prospect-building/:address/:entrance', component: ProspectBuildingComponent},
  {path: 'anais', component: AdminComponent, children: [
      {path: 'add-apt', component: CreateNewAptPageComponent},
      {path: 'edit-images', component: UploadMultiImagesComponent, data: {'showFooter': false}},
      {path: 'apt-history', component: AptHistoryComponent},
      {path: 'edit-prospect-status-type', component: ProspectStatusTypeComponent},
      {path: 'edit-commodities-type', component: EditTableTypeConfigPageComponent, data: <TableTypeConfigRouteData>{
          add_fnc: 'addCommodity',
          add_btn_title:'Add Commodity',
          get_fnc: 'getCommoditiesTypes', update_fnc: 'updateCommodity', delete_fnc: 'deleteCommodity', title: 'Commodities'
        }},
      {path: 'edit-utilities-and-service-type', component: EditTableTypeConfigPageComponent, data: <TableTypeConfigRouteData>{
          add_btn_title:'Add',
          add_fnc: 'addUtilitiesAndServiceTypes',
          get_fnc: 'getCommoditiesTypes', update_fnc: 'updateUtilitiesAndServiceTypes', delete_fnc: 'deleteUtilitiesAndServiceTypes',
          title: 'Utilities and Service Types'
        }},
      {path: 'edit-maintenance-and-service-supplier-type', component: EditTableTypeConfigPageComponent, data: <TableTypeConfigRouteData>{
          add_fnc: 'addMaintenanceServiceSupplierType',
          add_btn_title:'Add',
          get_fnc: 'getMaintenanceServiceSupplierTypes', update_fnc: 'updateMaintenanceServiceSupplierType', delete_fnc: 'deleteMaintenanceServiceSupplierType',
          title: 'Maintenance and Service Supplier Type'
        }},
      {path: 'prospect-building-map', component: ProspectBuildingMapComponent}
    ]}
];


@NgModule({
  imports: [RouterModule.forRoot(routes,{ scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
