<h1 mat-dialog-title>
  {{data.prospectStatusId !== undefined ? 'Edit Apt' : 'Add Apt'}}
</h1>
<form [formGroup]="form" (ngSubmit)="submit()">
<div mat-dialog-content>
  <mat-form-field>
  <mat-label>
    Floor
  </mat-label>
  <input matInput *ngIf="data.floor === undefined" formControlName="floor" cdkfocusinitial/>
  <input matInput *ngIf="data.floor !== undefined" formControlName="floor" />
</mat-form-field>
<mat-form-field>
  <mat-label>Apt.</mat-label>
  <input *ngIf="data.floor !== undefined" matInput formControlName="apt" CdkFocusInitial />
  <input *ngIf="data.floor === undefined" matInput formControlName="apt" />
</mat-form-field>
  <mat-form-field>

  <mat-label>Prospect Status</mat-label>
  <mat-select formControlName="prospectStatusId">
    <mat-option *ngFor="let status of statusTypes" [value]="status.id">{{status.statusNameEn}}</mat-option>
  </mat-select>
  </mat-form-field>
  <textarea placeholder="Comment" formControlName="comment"></textarea>
  <div id="contacts-dialog">
    <div *ngFor="let contact of contacts; let i = index" class="contact-box">
      <div>{{contact.firstName}}&nbsp;{{contact.lastName}}</div>
      <div><ng-container *ngIf="contact.phone1"><a href="tel:{{contact.phone1}}"><mat-icon class="smaller-icon">call</mat-icon>&nbsp;{{contact.phone1}}</a></ng-container></div>
      <div><ng-container *ngIf="contact.phone2"><a href="tel:{{contact.phone2}}"><mat-icon class="smaller-icon">call</mat-icon>&nbsp;{{contact.phone2}}</a></ng-container></div>
      <div><ng-container *ngIf="contact.email"><a href="mailto:{{contact.email}}"><mat-icon class="smaller-icon">mail</mat-icon>&nbsp;{{contact.email}}</a></ng-container></div>
      <div class="contact-dialog-actions">
        <button mat-icon-button type="button" (click)="openEditContactDialog(contact, i)"><mat-icon>edit_note</mat-icon></button>
        <button mat-icon-button
                [swal]="{title:'Delete: Are you sure ?', showCancelButton: true}" (confirm)="deleteContact(i)"
                color="accent" matTooltip="Delete" ><mat-icon>delete</mat-icon></button>
      </div>
      <div [innerText]="contact.comment"></div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button type="submit">Ok</button>
  <button mat-button type="button" [mat-dialog-close]="null">No Thanks</button>
  <button mat-raised-button type="button" color="warn" (click)="openEditContactDialog()">Add Contact</button>
</div>
</form>
