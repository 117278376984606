<mat-nav-list role="navigation">
<!--  <div mat-subheader style="color: white" i18n>Navigation</div>-->
  <mat-list-item routerLinkActive="active-nav-item" (click)="openLink($event)" [routerLink]="['/','home']">
    <mat-icon matListItemIcon>home</mat-icon>
    <span matListItemLine i18n>Home</span>
<!--  <span matListItemLine i18n>Home Page</span>-->
  </mat-list-item>
  <mat-list-item routerLinkActive="active-nav-item" (click)="openLink($event)" [routerLink]="['/','buy']">
    <mat-icon matListItemIcon>shopping_bag</mat-icon>
    <span matListItemLine i18n>Acquire</span>
<!--  <span matListItemLine i18n>Looking to Acquire a Property</span>-->
  </mat-list-item>
  <mat-list-item routerLinkActive="active-nav-item" (click)="openLink($event)" [routerLink]="['/','sell']">
    <mat-icon matListItemIcon>sell</mat-icon>
    <span matListItemLine i18n>List</span>
<!--  <span matListItemLine i18n>Looking to List an Apartment</span>-->
  </mat-list-item>
  <mat-list-item routerLinkActive="active-nav-item" (click)="openLink($event)" [routerLink]="['/','rental-management']">
    <mat-icon matListItemIcon>door_front</mat-icon>
    <span matListItemLine i18n>Rental</span>
<!--  <span matListItemLine i18n>Full Rental Management</span>-->
  </mat-list-item>
  <mat-list-item routerLinkActive="active-nav-item" (click)="openLink($event)" [routerLink]="['/','about-us']">
    <mat-icon matListItemIcon>group</mat-icon>
    <span matListItemLine i18n>About us</span>
<!--  <span matListItemLine i18n>Dream Home in Israel</span>-->
  </mat-list-item>
</mat-nav-list>
<!--<div style="text-align: center">-->
<!--<img style="max-height: 3em"  class="top-toolbar-logo" src="/en/assets/logo.svg" />&ndash;&gt;-->
<!--</div>-->
