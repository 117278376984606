import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {debounceTime, distinctUntilChanged, Subject, Subscription, switchMap} from "rxjs";
import {GraphqlService} from "../../graphql/graphql.service";
import {QueryRef} from "apollo-angular";
import {AptHistoryData} from "./types/apt-history-types";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {FormControl} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {TableColumnsComponent} from "../../crm-components/table-columns/table-columns.component";

export interface AptHistoryCommodityInfo {
  name: string;
  title: string;
  is_enabled?: boolean;
  icon: string;
}

@Component({
  selector: 'app-apt-history',
  templateUrl: './apt-history.component.html',
  styleUrls: ['./apt-history.component.scss']
})
export class AptHistoryComponent implements OnInit, OnDestroy, AfterViewInit{

  public commodities: AptHistoryCommodityInfo[] = [
    {name: 'isAirconTadiran', title: 'Tadiran Aircon', icon: 'heat_pump'},
    {name: 'isAircon', title: 'Aircon', icon:'ac_unit'},
    {name: 'isElevator', title: 'Elevator', icon: 'door_sliding'},
    {name: 'isBoiler', title: 'Boiler', icon: 'bathtub'},
    {name: 'isDisabledAccess', title: 'Accesible for the Disabled', icon: 'accessible'},
    {name: 'isMamad', title: 'Mamad', icon:'meeting_room'},
    {name: 'isRenovated', title: 'Renovated', icon: 'imagesearch_roller'},
    {name: 'isStorage', title: 'Storage Place', icon: 'garage_home'},
    {name: 'isHasFurniture', title: 'Furnished', icon: 'chair'},
    {name: 'isBars', title: 'Bars', icon: 'texture'},
  ];

  allDisplayedColumns: string[] = [
    'type', 'item_img', 'title','subTitle','itemDesc', 'floorsInBuilding','isFlexible',
    'realEstateOffice', 'isExclusive','price','rooms','floor','squareMeters',
    'entranceDate','conditionOfPlace','balconies','parkingSpots',
     'commodities', 'sellers',
    'numOfPayments','vaadBaitPerMonth','arnonnaPer2Months','createdAt','updatedAt','cmds'
  ];

  displayedColumns: string[] = [...this.allDisplayedColumns];

  dataSource: MatTableDataSource<AptHistoryData>;
  rows: AptHistoryData[] = [];


  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @ViewChild(MatSort) sort!: MatSort;

  private readonly searchSubject = new Subject<{ isExactMatch: boolean|null;
    match: string|null; limit:number|null }>();

  searchSubscription!: Subscription;

  isLoading: boolean = true;


  aptHistorySub: Subscription|null  = null;
  private aptHistoryQueryRef: QueryRef<any, {
    isExactMatch: boolean | null;
    match: string | null;
    limit: number | null
  }>;



  constructor(private gqlService: GraphqlService, private dialog: MatDialog) {
    this.dataSource = new MatTableDataSource<AptHistoryData>();
    this.aptHistoryQueryRef=this.gqlService.getAptHistory('',false);
    this.aptHistorySub=this.aptHistoryQueryRef.valueChanges.subscribe((data)=>{
      this.isLoading=false;
      this.dataSource.data=data.data.getAptHistory;
      this.dataSource.sort=this.sort;
      this.dataSource.paginator=this.paginator;
    });
  }

  openTableColumnsDialog() {
    this.dialog.open(TableColumnsComponent, {
      data: {all: this.allDisplayedColumns, visible: this.displayedColumns}
    }).afterClosed().subscribe((data)=>{
      if (data) {
        this.displayedColumns=data;
      }
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator=this.paginator;
    this.dataSource.sort=this.sort;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  search(match: string, isExactMatch: boolean, limit:number|null=null) {
    this.searchSubject.next({match, isExactMatch, limit});
    this.isLoading=true;
    this.aptHistoryQueryRef.refetch({match, isExactMatch, limit}).finally(()=>{
      console.debug(`finished refetching ${match}`);
    });
  }

  ngOnInit() {
    this.searchSubscription = this.searchSubject.pipe(debounceTime(300),distinctUntilChanged(),
      switchMap((searchQuery)=>this.aptHistoryQueryRef.refetch(searchQuery)))
      .subscribe((result) => {
        console.info('done');
      });
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
  }

}
