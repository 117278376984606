<div id="tux-dynamic-form-main-div" *ngIf="!loading">
  {{this.form.getRawValue() | json}}
  <form (ngSubmit)="done(false)" [formGroup]="form">
   <div *ngFor="let groups of groupedData" class="group-div">
  <ng-container  *ngIf="(groups.groupKeyName ?? '') === ''" >
      <app-dynamic-form-question [formControlName]="question.keyName" *ngFor="let question of groups.fields; let i=index"
        [question]="question" [allData]="selectDataByKeyName"></app-dynamic-form-question>
  </ng-container>
   <div *ngIf="(groups.groupKeyName ?? '') !== ''" class="group-div">
  <h4 >{{groups.groupKeyName}}</h4>
      <app-dynamic-form-question [formControlName]="question.keyName"
        *ngFor="let question of groups.fields; let i=index"
        [question]="question" [allData]="selectDataByKeyName"></app-dynamic-form-question>
  </div>
    <div *ngIf="showSubmit">
      <button mat-button type="submit" [disabled]="!form.dirty">Ok</button>
      <button mat-button (click)="done(true)">No Thanks</button>
    </div>
   </div>
  </form>
</div>
