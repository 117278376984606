<h1 mat-dialog-title>
  Floor {{data}}
</h1>
<form [formGroup]="form" (ngSubmit)="submit()">
<div mat-dialog-content>
  <mat-form-field>
  <mat-label>
    Floor
  </mat-label>
  <input matInput formControlName="new_floor" cdkfocusinitial/>
</mat-form-field>
  <mat-slide-toggle formControlName="allow_existing_floor" i18n>Merge floors if exists</mat-slide-toggle>
</div>
<div mat-dialog-actions>
  <button mat-button type="submit" >Ok</button>
  <button mat-button [mat-dialog-close]="null">No Thanks</button>
</div>
</form>
