import {Injectable} from '@angular/core';
import {Token} from './token';
import {LocalStorageService} from './local-storage.service';

const graphQlLoginTokenKey = 'gql_token';
const acceptCookieConsent = 'cookie_consent';

@Injectable({
  providedIn: 'root'
})
export class MyAuthService {
  name: string|null = null;
  profileDir: string|null = null;
  isAdminRole = false;

  constructor(private localStorage: LocalStorageService) { }

  public isMyProfile(profileDir: string): boolean {
    return this.isLoggedIn() && this.profileDir === profileDir;
  }

  public acceptCookieConsent() {
    this.localStorage.setItem(acceptCookieConsent,'1');
  }

  public removeCookieConsent() {
    this.localStorage.removeItem(acceptCookieConsent);
  }

  public isAcceptCookieConsent(): boolean {
    return this.localStorage.getItem(acceptCookieConsent) === '1';
  }

  public storeToken(token: Token): void {
    this.localStorage.setItem(graphQlLoginTokenKey, JSON.stringify(token));
  }

  public isLoggedIn(): boolean {
    return this.getValidToken() !== null;
  }

  public isAdmin(): boolean{
    return this.isAdminRole;
  }


  public logout(): void {
    this.localStorage.removeItem(graphQlLoginTokenKey);
    this.resetUser();
  }

  public getValidToken(): string|null {
    let retToken: string|null = null;
    const tokenJson: string|null = this.localStorage.getItem(graphQlLoginTokenKey);
    if (tokenJson) {
      const token: Token = JSON.parse(tokenJson);
      const secondsSinceEpoch = Math.round((new Date()).getTime() / 1000);
      if ((secondsSinceEpoch + 60) > token.exp) {
        this.logout();
        this.resetUser();
      } else {
        retToken = token.token;
        this.name = token.name;
        this.profileDir = token.profileDir;
        this.isAdminRole=token.roles.indexOf('ADMIN')>=0;
      }
    }
    return retToken;
  }

  private resetUser() {
    this.name = null;
    this.profileDir=null;
    this.isAdminRole=false;
  }

}
