import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, forwardRef,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  AddressInput,
  FullAddress,
  MapProspectMarker
} from "../../../crm-pages/prospect-building/my-types";
import {GoogleMap, MapInfoWindow, MapMarker} from "@angular/google-maps";
import {QueryRef} from "apollo-angular";
import {Subscription} from "rxjs";
import {MyMatFormFieldAbstract} from "../../../my-mat-form-field-abstract";

class TFullAddress {
  constructor(
    public address: string,
    public entrance: string,
    public latitude: number,
    public longitude: number,
    public zipCode: string,
    public entryCode: string,
    public floor: string,
    public aptNumber: string) {
  };
}

@Component({
  selector: 'app-address-generic-fields',
  templateUrl: './address-generic-fields.component.html',
  styleUrls: ['./address-generic-fields.component.scss'],
})
export class AddressGenericFieldsComponent extends MyMatFormFieldAbstract<TFullAddress> implements OnInit, AfterViewInit{

  @Input({required: false}) requestZipCode: boolean = true;
  @Input({required: false}) requestEntryCode: boolean = true;
  @Input({required: false}) requestFloorNApt: boolean = true;
  @Input({required: false}) requestEntrance: boolean = true;

  @Input({required: false}) actionName: string|null=null;
  @Input({required: false}) actionMobileIcon: string|null=null;
  @Output() actionFuncClicked: EventEmitter<FullAddress> = new EventEmitter<FullAddress>();

  @ViewChild('myGoogleMap', { static: false })
  map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false })
  info!: MapInfoWindow;
  @ViewChild('search')
  public searchElementRef!: ElementRef;

  @ViewChild('meMarker')
  public meMarker!: MapMarker;

  override parts = this.formBuilder.group({
    address: [''],
    entrance: [''],
    zipCode: [''],
    entryCode: [''],
    floor: [''],
    aptNumber: ['']
  });

  zoom = 19;
  maxZoom = 19;
  minZoom = 8;
  center!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    mapTypeId: 'roadmap',
    maxZoom:this.maxZoom,
    minZoom:this.minZoom,
  }
  markers = []  as  any;
  infoContent: MapProspectMarker|undefined;
  latitude!: any;
  longitude!: any;
  geocoder = new google.maps.Geocoder();
  mapProspectMarkerQueryRef: QueryRef<any, any>|undefined;
  mapProspectMarkerQuerySubscription: Subscription|undefined;





  ngOnInit() {
    this.mapProspectMarkerQueryRef=this.gqlService.getMapProspectMarkers();
    this.mapProspectMarkerQuerySubscription=this.mapProspectMarkerQueryRef.valueChanges.subscribe((data)=>{
      if (!data.data.getAptProspectMarkers) {
        this.msgService.snackMsgError('failed getting prospect markers');
      } else {
        data.data.getAptProspectMarkers.forEach((row: MapProspectMarker)=>{
          this.addMarker(row);
        });
      }
    });
    navigator.geolocation.getCurrentPosition((position) => {
      this.latitude=position.coords.latitude;
      this.longitude=position.coords.longitude;
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
      this.geocodeLatLng();
    });
  }

  runAction(useInfoContent: boolean = false) {
    if (!this.parts.get('address')?.value?.trim()) {
      this.msgService.snackMsgWarn("search field should not be empty");
      return;
    }
    const c_data: AddressInput = {
      address: useInfoContent ? this.infoContent!.address : this.parts.get('address')!.value!.trim(),
      entrance: useInfoContent ? this.infoContent!.entrance : this.parts.get('entrance')!.value!.trim(),
      latitude: useInfoContent ? this.infoContent!.latitude : this.latitude,
      longitude: useInfoContent ? this.infoContent!.longitude : this.longitude
    }
    this.gqlService.addAddressGetID(c_data).subscribe((data:any)=>{
      if (isNaN(data.data.addAddress)) {
        this.msgService.snackMsgError("failed understanding address");
      } else {
        const ad: FullAddress = {
          address: c_data.address,
          entrance: c_data.entrance,
          latitude: c_data.latitude,
          longitude: c_data.longitude,
          zipCode: this.parts.get('zipCode')!.value,
          entryCode: this.parts.get('entryCode')!.value,
          floor: this.parts.get('floor')!.value,
          aptNumber: this.parts.get('aptNumber')!.value
        }
        this.actionFuncClicked.next(ad);
      }
    });
  }


  zoomIn() {
    if (this.zoom < this.maxZoom) this.zoom++;
    console.log('Get Zoom',this.map.getZoom());
  }

  zoomOut() {
    if (this.zoom > this.minZoom) this.zoom--;
  }

  eventHandler(event: any ,name:string){
    console.log(event,name);

    // Add marker on double click event
    if(name === 'mapDblclick'){
      this.dropMarker(event);
    }
  }

  // Markers
  logCenter() {
    console.log(JSON.stringify(this.map.getCenter()))
  }

  hello() {
    alert('test');
  }

  generateMarkerInfo(address: string, entrance: string|null|undefined) {
    return address + (entrance ? ' entrance ' + entrance : '') + `
    <br />
<button onclick="hello()">hello</button>`;
  }

  addMarker(marker: MapProspectMarker) {
    this.markers.push({
      position: {
        lat: marker.latitude,
        lng: marker.longitude,
      },
      label: {
        color: 'blue',
        text: 'Pr',
      },
      title: marker.address,
      info: marker,
      options: {
        animation: google.maps.Animation.DROP,
      },
    })
  }

  dropMarker(event:any) {
    this.markers.push({
      position: {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      },
      label: {
        color: 'blue',
        text: 'Marker label ' + (this.markers.length + 1),
      },
      title: 'Marker title ' + (this.markers.length + 1),
      info: 'Marker info ' + (this.markers.length + 1),
      options: {
        animation: google.maps.Animation.DROP,
      },
    })
  }

  openInfo(marker: MapMarker, content: MapProspectMarker) {
    this.infoContent = content;
    this.info.open(marker)
  }

  clearSearchField() {
    this.parts.get('address')!.setValue('');
    this.onChange(this.value);
  }

  geocodeLatLng() {
    this.map.panTo(this.center);
    this.geocoder
      .geocode({ location: this.center })
      .then((response) => {
        if (response.results[0]) {
          this.parts.get('address')?.setValue(response.results[0].formatted_address);
          this.onChange(this.value);
          this.openInfo(this.meMarker, {address: this.parts.get('address')!.value!, entrance: null,
            longitude: this.center.lng, latitude: this.center.lat});
        } else {
          this.msgService.snackMsgError("no results found for current location")
        }
      })
      .catch((e) => this.msgService.snackMsgError("Geocoder failed due to: " + e));
  }

  ngAfterViewInit() {
    let autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement
    );
    // Align search box to center
    // this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(
    //   this.searchElementRef.nativeElement
    // );
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        //get the place result
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        if (place.formatted_address) {
          this.parts.get('address')?.setValue(place.formatted_address);

        }

        console.log({ place }, place.geometry.location?.lat());

        //set latitude, longitude and zoom
        this.latitude = place.geometry.location?.lat();
        this.longitude = place.geometry.location?.lng();
        this.center = {
          lat: this.latitude,
          lng: this.longitude,
        };
      });
    });
  }

}
