import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-under-constructions',
  templateUrl: './under-constructions.component.html',
  styleUrls: ['./under-constructions.component.scss']
})
export class UnderConstructionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
