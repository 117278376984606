<mat-drawer-container class="my-container" autosize [hasBackdrop]="true">
  <mat-drawer class="my-sidenav" mode="over" [(opened)]="myDrawerStatus">
    <app-mobile-menu-buttons [openLink]="openLink.bind(this)"></app-mobile-menu-buttons>
  </mat-drawer>
<app-top-toolbar *ngIf="!showToolbar"></app-top-toolbar>
<div id="content">
  <router-outlet></router-outlet>
</div>
<app-footer *ngIf="showFooter"></app-footer>
</mat-drawer-container>
