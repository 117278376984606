import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-my-vert-card',
  templateUrl: './my-vert-card.component.html',
  styleUrls: ['./my-vert-card.component.scss']
})
export class MyVertCardComponent implements OnInit {

  wasVisibleBefore = false;
  visibleClass = false;

visibilityChangeHandler($event: string) {
  if (!this.wasVisibleBefore && $event === "VISIBLE") {
    this.wasVisibleBefore=true;
    this.visibleClass=true;

  }
 //console.info($event);
}

  @Input() routerLink:string[] | string | null | undefined;
  @Input() title: string | undefined;
  @Input() image: string | undefined;
  @Input() content: string | undefined;

  constructor() { }

  ngOnInit(): void {
  }

}
