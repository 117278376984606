<h1>{{routeData.title}}</h1>
<button mat-raised-button color="primary" (click)="addCommodityType()">{{routeData.add_btn_title}}</button>

<table mat-table (matSortChange)="sortData($event)" [dataSource]="rowTypes" matSort matSortActive="nameEn"
       matSortDirection="desc" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <ng-container matColumnDef="nameEn">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name(En) </th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field>
        <mat-label>Name(en)</mat-label>
        <input matInput (ngModelChange)="element.changed = true" [(ngModel)]="element.nameEn" />
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="nameHe">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name(He) </th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field>
        <mat-label>Name(he)</mat-label>
        <input matInput (ngModelChange)="element.changed = true" [(ngModel)]="element.nameHe" />
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="nameFr">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name(Fr) </th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field>
        <mat-label>Name(Fr)</mat-label>
        <input matInput (ngModelChange)="element.changed = true" [(ngModel)]="element.nameFr" />
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="commands">
    <th mat-header-cell *matHeaderCellDef> Commands </th>
    <td mat-cell *matCellDef="let element; let j=index">
      <button mat-icon-button color="primary" matTooltip="Save" [disabled]="!element.changed"
              [swal]="{title:'Save: Are you sure ?', showCancelButton: true}" (confirm)="saveRow(j, element)">
        <mat-icon>done</mat-icon></button>
      <button mat-icon-button color="primary" matTooltip="Revert" [disabled]="!element.id || !element.changed"
              [swal]="{title:'Revert: Are you sure ?', showCancelButton: true}"
              (confirm)="revertRow(j)"><mat-icon>undo</mat-icon></button>
      <button mat-icon-button color="primary" matTooltip="Delete" (confirm)="deleteRow(j)"
              [swal]="{title:'Delete: Are you sure ?', showCancelButton: true}"
      ><mat-icon>delete</mat-icon></button>
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-divider></mat-divider>
