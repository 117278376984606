import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {atLeastOneHasValue} from "../../../validators/at-least-one-field-validator";
import {AptProspectStatus, AptProspectStatusContact} from "../../../crm-pages/prospect-building/my-types";
import {MsgService} from "../../../services/msg.service";
import {QuestionForm} from "../../../tux-dynamic-forms/services/question.service";

@Component({
  selector: 'app-contact-generic-fields',
  templateUrl: './contact-generic-fields.component.html',
  styleUrls: ['./contact-generic-fields.component.scss']
})
export class ContactGenericFieldsComponent implements OnChanges {

  @Input({required: false}) data: AptProspectStatusContact = {
    comment: null,
    email: null,
    firstName: "",
    has_whatsapp: null,
    lastName: null,
    phone1: null,
    phone2: null
  };
  @Input({required: true}) showSubmit!: boolean;

  @Output() submit: EventEmitter<AptProspectStatusContact | null> = new EventEmitter<AptProspectStatusContact | null>();

  qForm?: QuestionForm;

  constructor(private fb: FormBuilder, private msgService: MsgService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.qForm) {
      this.qForm = {
        label: 'Contact Basic Info',
        fields: [
          {
            type: 'text',
            keyName: 'firstName',
            defaultValue: this.data?.firstName,
            required: true
          },
          {
            type: 'text',
            keyName: 'lastName',
            defaultValue: this.data?.lastName,
          },
          {
            type: 'phone',
            keyName: 'phone1',
            defaultValue: this.data?.phone1
          },
          {
            type: 'phone',
            keyName: 'phone2',
            defaultValue: this.data?.phone2
          },
          {
            type: 'email',
            keyName: 'email',
            defaultValue: this.data?.email,
            validators: [Validators.email]
          },
          {
            type: 'comment',
            keyName: 'comment'
          },
          {
            type: 'boolean',
            keyName: 'has_whatsapp',
            defaultValue: this.data?.has_whatsapp
          }
        ],
        validators: [atLeastOneHasValue(['phone1', 'phone2', 'email'])] // TODO: verify this validator works
      };
    }
    }

  /*public getFormData(): AptProspectStatusContact {
    return this.form.value;
  }

  done(isEmpty: boolean) {
    if (isEmpty) {
      this.submit.next(null);
    }
    if (this.form.invalid) {
      this.msgService.snackMsgWarn('please fill all fields');
      return;
    } else {
      this.submit.next(this.form.value);
    }
  }*/

}
