<mat-stepper class="my-stepper" #stepper *ngIf="!isLoading" (selectionChange)="onStepChange($event)">
  <mat-step i18n-label label="Contact">
    <div>
      <button mat-button matStepperNext>Next</button>
    </div>
    <app-dynamic-form [qform]="qFormContact" [showSubmit]="false"></app-dynamic-form>
    <div>
      <button mat-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step i18n-label  label="Address">
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>
    <app-dynamic-form [qform]="qFormAddress" [showSubmit]="false"></app-dynamic-form>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
  </mat-step>
  <mat-step i18n-label label="Type & Condition">
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>

    <app-dynamic-form [qform]="qFormTypeNCond" [selectDataByKeyName]="data" [showSubmit]="false"></app-dynamic-form>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>

  </mat-step>
  <mat-step i18n-label label="Size & Disposition">
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>

    <app-dynamic-form [qform]="qFormSizeNDisposition" [selectDataByKeyName]="data" [showSubmit]="false"></app-dynamic-form>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step i18n-label label="Furniture">
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>

    <app-dynamic-form [qform]="qFormFurniture" [selectDataByKeyName]="data" [showSubmit]="false"></app-dynamic-form>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step i18n-label label="Commodities">
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>

    <app-dynamic-form [qform]="qFormCommodities" [selectDataByKeyName]="data" [showSubmit]="false" />
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>

  </mat-step>
  <mat-step i18n-label label="View & Expo">
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>

    <app-dynamic-form [qform]="qFormViewNXpo" [selectDataByKeyName]="data" [showSubmit]="false" />
    <app-dynamic-form [qform]="qFormProximities" [selectDataByKeyName]="data" [showSubmit]="false" />
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>

  </mat-step>

  <mat-step i18n-label label="Summary">
    <div class="summary-container">
      <div class="section-container" *ngFor="let item of summaryData |keyvalue">
        <mat-toolbar color="primary">
          <span>{{ item.key }}</span>
        </mat-toolbar>
        <mat-card>
          <mat-card-content>
            <ul>
              <li *ngFor="let key of objectKeys(item.value)">
                <span class="key">{{prettifyKey(key)}}</span>: <span class="value">{{item.value[key] !== null ? item.value[key] : 'Not provided'}}</span>
              </li>
            </ul>
          </mat-card-content>
        </mat-card>
      </div>
      <button mat-raised-button style="width: 90vw" (click)="submit()" color="primary">Submit</button>
    </div>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button (click)="stepper.reset()">Reset</button>
    </div>
  </mat-step>
</mat-stepper>
