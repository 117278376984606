<mat-toolbar>
  <mat-toolbar-row>
    <div id="contact-buttons">
    <a  href="mailto:contact@dreamhomeinisrael.com" target="_blank" i18n-matTooltip matTooltip="Send Us an Email">
      <button  class="contact_footer_icon" mat-icon-button color="primary">
        <mat-icon>email</mat-icon>
      </button>
<!--      <div>Email</div>-->

    </a>

    <a href="tel:+972555560595" target="_blank" i18n-matTooltip matTooltip="Call Us">
      <button  class="contact_footer_icon" mat-icon-button>
        <mat-icon>call</mat-icon>
      </button>
<!--      <div>Phone</div>-->

    </a>


    <a href="https://wa.me/972555560595" target="_blank" i18n-matTooltip matTooltip="Send us a WhatsApp Message">
      <button class="contact_footer_icon" mat-icon-button>
        <img src="/en/assets/whatsapp.svg"  alt="whatsapp"/>
      </button>
<!--      <div i18n>Whatsapp</div>-->
    </a>
    </div>
    <div class="flex-row-wrap-center">
    <div i18n [routerLink]="['/cookies-policy']" class="cursor-pointer capital-letters">
      Cookies Policy
    </div>
    <div i18n [routerLink]="['/privacy']" class="cursor-pointer capital-letters">
      Privacy Policy
    </div>
    <div i18n [routerLink]="['/terms']" class="cursor-pointer capital-letters">
      Terms & Conditions
    </div>
    <div i18n [routerLink]="['/disclaimer']" class="cursor-pointer capital-letters">
      Disclaimer
    </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
