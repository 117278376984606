import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {ApolloClientOptions, ApolloLink, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {MyAuthService} from '../user/my-auth.service';
import {onError} from '@apollo/client/link/error';
import {setContext} from '@apollo/client/link/context';
import {MsgService} from '../services/msg.service';
import {ToastrModule} from 'ngx-toastr';
import {environment} from "../../environments/environment";


let localMsgService: MsgService|undefined;
let localMyAuth: MyAuthService;

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (err.extensions?.['code'] === 'UNAUTHENTICATED') {
            localMsgService?.snackMsgError('Authentication error');

            // error code is set to UNAUTHENTICATED
            // when AuthenticationError thrown in resolver

            // modify the operation context with a new token
            const oldHeaders = operation.getContext()['headers'];
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: localMyAuth?.getValidToken(),
              },
            });
            // retry the request, returning the new observable
            return forward(operation);
        } else {
          localMsgService?.snackMsgError(err.message);
        }
      }
    }
    if (networkError) {
      localMsgService?.snackMsgError(networkError.message);
      console.log('[Network error]: ' + JSON.stringify(networkError));
      // if you would also like to retry automatically on
      // network errors, we recommend that you use
      // apollo-link-retry
    }
    return;
  }
);

const uri = environment.graphQlUri;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createApollo = (httpLink: HttpLink, toastrService: MsgService, myauth: MyAuthService): ApolloClientOptions<any> => {
  localMsgService = toastrService;
  localMyAuth = myauth;
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8'
    }
  }));

  const auth = setContext(async (_, { headers }) => {
    // Grab token if there is one in storage or hasn't expired
    const token = localMyAuth.getValidToken();

    if (!token) {
      return {headers: {}};
      // An observable to fetch a new token
      // Converted .toPromise()
    } else {
      // Return the headers as usual
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
  });

  // @ts-ignore
  const link = ApolloLink.from([errorLink, basic, auth, httpLink.create({ uri })]);
  const cache = new InMemoryCache();

  return {
    link,
    cache
  };
};

@NgModule({
  imports: [
    ToastrModule,
    ApolloModule
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, MsgService, MyAuthService],
    },
  ],
})
export class GraphQLModule {}
