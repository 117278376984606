<mat-toolbar color="primary">
  <mat-toolbar-row>Dream home in Israel</mat-toolbar-row>
  <mat-toolbar-row>
    <button mat-icon-button matTooltip="Apt. Prospect" [matMenuTriggerFor]="apt_prospect_menu"><mat-icon>home_work</mat-icon></button>
    <button mat-icon-button matTooltip="Settings TAbles" [matMenuTriggerFor]="settings_menu"><mat-icon>settings</mat-icon></button>
  </mat-toolbar-row>

</mat-toolbar>

<mat-menu #apt_prospect_menu="matMenu">
  <button mat-menu-item [routerLink]="['/anais/edit-images']">Edit Images</button>
  <button mat-menu-item [routerLink]="['/anais/add-apt']">Add Apartment</button>
  <button mat-menu-item [routerLink]="['/anais/prospect-building-map']">Prospect Building Map</button>
  <button mat-menu-item [routerLink]="['/anais/apt-history']">Apt. History</button>
</mat-menu>

<mat-menu #settings_menu="matMenu">
  <button mat-menu-item [routerLink]="['/anais/edit-prospect-status-type']">Edit Prospect Status Type</button>
  <button mat-menu-item [routerLink]="['/anais/edit-utilities-and-service-type']">Edit Utilities and Service Types</button>
  <button mat-menu-item [routerLink]="['/anais/edit-commodities-type']">Edit Commodities Type</button>
  <button mat-menu-item [routerLink]="['/anais/edit-maintenance-and-service-supplier-type']">Edit Service Supplier Type</button>
</mat-menu>

