<app-about-us></app-about-us>
<mat-divider></mat-divider>
<div id="cards">
  <app-my-vert-card
   [routerLink]="['/','buy']"
   i18n-title i18n-content
   title="Looking to acquire a property?"
   image="/en/assets/aquire_t.jpg"
   content="Whether you are searching for your Dream Home, a second home or an investment, we accompany you in finding the perfect match."
  ></app-my-vert-card>
<app-my-vert-card
  [routerLink]="['/','sell']"
  i18n-title i18n-content
  title="Looking to list your property?"
  image="/en/assets/list_t.jpg"
  content="You are turning a page a ready to start a new project? We accompany you to list your property to best match your new targets."
>
</app-my-vert-card>
  <app-my-vert-card
    [routerLink]="['/','rental-management']"
    i18n-title i18n-content
    title="Looking to rent your property?"
    image="/en/assets/rent_t.jpg"
    content="Your property is an important asset and you want to make sure it is in good hands? We take in charge the whole renting management so you can serenely focus on your daily activities."
  ></app-my-vert-card>
</div>
