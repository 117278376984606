import {Validators} from "@angular/forms";
import {atLeastOneHasValue} from "../../../validators/at-least-one-field-validator";
import {QuestionForm} from "../../../tux-dynamic-forms/services/question.service";

export const qFormContact: QuestionForm = {
  label: 'Contact',
  fields: [
    {
      type: 'text',
      keyName: 'firstName',
      required: true
    },
    {
      type: 'text',
      keyName: 'lastName',
    },
    {
      type: 'phone',
      keyName: 'phone1',
    },
    {
      type: 'phone',
      keyName: 'phone2',
    },
    {
      type: 'email',
      keyName: 'email',
      validators: [Validators.email]
    },
    {
      type: 'comment',
      keyName: 'comment'
    },
    {
      type: 'boolean',
      keyName: 'has_whatsapp',
      defaultValue: true
    }
  ],
  validators: [atLeastOneHasValue(['phone1', 'phone2', 'email'])] // TODO: verify this validator works
};

export const qFormCommodities: QuestionForm = {
  label: 'Commodities',
  fields: [
    {
      type: "positive_number",
      keyName: 'elevators'
    },
    {
      type: 'boolean',
      keyName: 'pool'
    },
    {
      type: 'boolean',
      keyName: 'spa'
    },
    {
      type: 'boolean',
      keyName: 'jacuzzi'
    },
    {
      type: 'boolean',
      keyName: 'guardian'
    },
    {
      type: 'boolean',
      keyName: 'gym'
    },
    {
      type: 'boolean',
      keyName: 'handicap_access'
    },
    {
      type: 'positive_number',
      groupKeyName: 'parking',
      keyName: 'underground'
    },
    {
      type: 'positive_number',
      groupKeyName: 'parking',
      keyName: 'private'
    },
    {
      type: 'positive_number',
      groupKeyName: 'parking',
      keyName: 'shared'
    },
    {
      type: 'positive_number',
      groupKeyName: 'parking',
      keyName: 'street'
    },
    {
      type: 'positive_number',
      keyName: 'storage_rooms'
    },
  ]
};


export const qFormProximities: QuestionForm = {
  label: 'Proximities',
  fields: [
    {
      keyName: 'interest_type',
      type: 'select',
      selectData: {keyNameForLabel: 'nameEn', keyNameForValue: 'id'}
    },
    {
      keyName: 'distance',
      type: 'positive_number'
    },
    {
      keyName: 'unit_type',
      type: 'select',
      selectData: {keyNameForLabel: 'nameEn', keyNameForValue: 'id'}
    },
    {
      keyName: 'is_walking_distance',
      type: "boolean"
    },
    {
      keyName: 'parks',
      type: 'select-autocomplete-multi',
      selectData: {keyNameForLabel: 'nameEn', keyNameForValue: 'id'}
    },
    {
      keyName: 'beaches',
      type: 'select-autocomplete-multi',
      selectData: {keyNameForLabel: 'nameEn', keyNameForValue: 'id'}
    },
    {
      keyName: 'main_streets',
      type: 'select-autocomplete-multi',
      label: 'Main Streets',
      selectData: {keyNameForLabel: 'nameEn', keyNameForValue: 'id'}
    },
    {
      keyName: 'is_shops',
      type: 'boolean'
    },
    {
      keyName: 'is_restaurants',
      type: 'boolean'
    },
    {
      keyName: 'is_transports',
      type: 'boolean'
    },
    {
      keyName: 'is_tram',
      type: "boolean"
    },
    {
      keyName: 'is_tram_station',
      type: 'boolean'
    },
    {
      keyName: 'comment',
      type: 'comment'
    }
  ]
};

export const qFormViewNXpo: QuestionForm = {
  label: 'View and Xpo',
  fields: [
    {
      type: 'boolean',
      keyName: 'angle'
    },
    {
      type: 'select',
      keyName: 'view_types',
      selectData: {keyNameForLabel: 'nameEn', keyNameForValue: 'id'}
    },
    {
      type: 'direction',
      keyName: 'exposition'
    }
  ]
};


export const qFormFurniture: QuestionForm = {
  label: 'Forniture',
  fields: [
    {
      type: 'select',
      keyName: 'is_furnished'
      ,selectData: {keyNameForLabel: 'nameEn', keyNameForValue: 'id'}
    },
    {
      type: 'select-multi',
      keyName: 'kitchen'
      ,selectData: {keyNameForLabel: 'nameEn', keyNameForValue: 'id'}
    },
    {
      type: 'select-multi',
      keyName: 'living_room'
      ,selectData: {keyNameForLabel: 'nameEn', keyNameForValue: 'id'}
    },
    {
      type: 'select-multi',
      keyName: 'dining_room'
      ,selectData: {keyNameForLabel: 'nameEn', keyNameForValue: 'id'}
    },
    {
      type: 'select-multi',
      keyName: 'bedroom'
      ,selectData: {keyNameForLabel: 'nameEn', keyNameForValue: 'id'}
    },
    {
      type: 'select-multi',
      keyName: 'office'
      ,selectData: {keyNameForLabel: 'nameEn', keyNameForValue: 'id'}
    },
  ]
};


export const qFormSizeNDisposition: QuestionForm = {
  label: 'Size N Disposition',
  fields: [
    {
      type: 'positive_number',
      keyName: 'apt_size'
    },
    {
      type: "positive_number",
      keyName: 'total_apt_size'
    },
    {type: 'positive_number',
    keyName: 'rooms'
    },
    {type: 'positive_number',
    keyName: 'showers'},
    {type: 'positive_number', keyName: 'bath_tabs'},
    {type: 'positive_number', keyName: 'toilets'},
    {type: 'positive_number', keyName: 'master_bedrooms'},
    {type: 'select', keyName: 'secured_room',selectData: {keyNameForLabel: 'nameEn', keyNameForValue: 'id'}},
    {type: 'select', keyName: 'air_conditioner',selectData: {keyNameForLabel: 'nameEn', keyNameForValue: 'id'}},
    {type: 'select', keyName: 'water_heater',selectData: {keyNameForLabel: 'nameEn', keyNameForValue: 'id'}},
    {
      type: 'list', keyName: 'terraces',
      listItems: {
        label: '',
        fields: [
          {
            type: 'positive_number',
            keyName: 'terrace_size'
          },
          {
            type: 'select',
            keyName: 'terrace_type',
            selectData: {keyNameForLabel: 'nameEn', keyNameForValue: 'id'}
          }]
      }
    }
  ]
};


export const qFormAddress: QuestionForm = {
  label: 'Address',
  fields: [{
    type: 'full-address',
    keyName: 'address',
    required: true,
  }
  ]
};

export const qFormTypeNCond: QuestionForm = {
  label: 'Type N Cond',
  fields: [
    {
      type: 'select',
      keyName: 'apt_type',
      required: true,
      selectData: {
        keyNameForLabel: 'nameEn',
        keyNameForValue: 'id'
      }
    },
    {
      type: 'boolean',
      keyName: 'is_divided',
    },
    {
      type: 'select',
      keyName: 'renovated',
      selectData: {
        keyNameForLabel: 'nameEn',
        keyNameForValue: 'id'
      }
    },
    {
      type: 'positive_number',
      keyName: 'building_floors',
    },
    {
      type: 'select',
      keyName: 'building_type',
      selectData: {
        keyNameForLabel: 'nameEn',
        keyNameForValue: 'id'
      }
    },
    {
      type: 'select',
      keyName: 'tama',
      selectData: {
        keyNameForLabel: 'nameEn',
        keyNameForValue: 'id'
      }
    },
    {
      type: 'comment',
      keyName: 'comment',
    }
  ]
};
