import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {GraphqlService} from "../../graphql/graphql.service";
import {Subscription} from "rxjs";
import {
  AptProspectStatusContact,
  ProspectStatusType
} from "../../crm-pages/prospect-building/my-types";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MsgService} from "../../services/msg.service";
import {atLeastOneHasValue} from "../../validators/at-least-one-field-validator";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {QuestionBase} from "../../tux-dynamic-forms/question-base";
import {QuestionForm} from "../../tux-dynamic-forms/services/question.service";

@Component({
  selector: 'app-add-apt-prospect-contact-dialog',
  templateUrl: './add-apt-prospect-contact-dialog.component.html',
  styleUrls: ['./add-apt-prospect-contact-dialog.component.scss']
})
export class AddAptProspectContactDialogComponent implements OnInit, OnDestroy {

  pStatusSub: Subscription|undefined;



  public statusTypes: ProspectStatusType[] = [];

  form: FormGroup;
  qForm: QuestionForm;

  constructor(private gqlService: GraphqlService,
              private fb: FormBuilder,
              private msgService: MsgService,
              public dialogRef: MatDialogRef<AddAptProspectContactDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AptProspectStatusContact) {
    this.qForm = {
      label: 'Apt Prospect Contact Dialog',
      fields: [
        {
          type: 'text',
          keyName:'firstName',
          defaultValue: data?.firstName,
          required: true
        },
        {
          type: 'text',
          keyName:'lastName',
          defaultValue: data?.lastName,
        },
        {
          type: 'phone',
          keyName: 'phone1',
          defaultValue: data?.phone1
        },
        {
          type: 'phone',
          keyName: 'phone2',
          defaultValue: data?.phone2
        },
        {
          type: 'email',
          keyName: 'email',
          defaultValue: data?.email,
          validators: [Validators.email]
        },
        {type: 'comment',
        keyName: 'comment'},
        {
          type: 'boolean',
          keyName: 'has_whatsapp',
          defaultValue: data?.has_whatsapp
        }
      ],
      validators: [atLeastOneHasValue(['phone1', 'phone2', 'email'])]
    };

    this.form = this.fb.group({
      firstName: [data?.firstName, [Validators.required]],
      lastName: [data?.lastName],
      phone1: [data?.phone1],
      phone2: [data?.phone2],
      email: [data?.email, [Validators.email]],
      comment: [data?.comment],
      has_whatsapp: [data?.has_whatsapp]
    }, {validators: atLeastOneHasValue(['phone1', 'phone2', 'email'])});
  }

  ngOnInit(): void {
    //TODO: why have it? for now not using it
    this.pStatusSub = this.gqlService.getProspectStatusTypes().valueChanges.subscribe(({data}) => {
      this.statusTypes = data.prospectStatusTypes;
    });
  }

  ngOnDestroy() {
    this.pStatusSub?.unsubscribe();
  }

  get f(): { [p: string]: AbstractControl } {
    return this.form.controls;
  }

  submit(data: AptProspectStatusContact|null) {
      this.dialogRef.close(data);
  }

}
