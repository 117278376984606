const toCamel = (s: string) => {
  return s.replace(/([-_\s][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '').replace(' ', '');
  });
};

const isArray = function (a: any) {
  return Array.isArray(a);
};

const isObject = function (o: any) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

export const keysToCamel = function (o: any) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      // @ts-ignore
      n[toCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    //@ts-ignore
    return o.map((i) => {
      return keysToCamel(i);
    });
  }

  return o;
};
