import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-prospect-door',
  templateUrl: './prospect-door.component.html',
  styleUrls: ['./prospect-door.component.scss']
})
export class ProspectDoorComponent implements OnInit {

  @Input() textColor='black';
  @Input() bgColor='white';
  @Input() doorNumber = '';
  @Input() contactsNumber: number=0;

  constructor() { }

  ngOnInit(): void {
  }

}
