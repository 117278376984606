<h3>{{address}}
<span *ngIf="entrance">&nbsp; Entrance {{entrance}}</span>
</h3>
<button mat-icon-button color="warn" matTooltip="Go to Map" [routerLink]="['/','anais','prospect-building-map']"><mat-icon>location_on</mat-icon></button>
<button mat-stroked-button color="warn" (click)="openChangeEntranceDialog(false)">Change Entrance</button>
<button mat-stroked-button color="warn" (click)="openChangeEntranceDialog(true)">Rename Entrance</button>
<div>
  <ul>
    <li *ngFor="let status of statusTypes | keyvalue"
        style="display: inline-block; margin: 1em"
        [ngStyle]="{'background-color': status.value.bgColor, 'color': status.value.textColor}">
      {{status.value.statusNameEn}}
    </li>
  </ul>
</div>
<div>
  <mat-button-toggle-group [(ngModel)]="floorsSortOrder">
    <mat-button-toggle value="asc" matTooltip="ascending" aria-label="ascending">
      <mat-icon>keyboard_double_arrow_down</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="desc" matTooltip="descending" aria-label="descending">
      <mat-icon>keyboard_double_arrow_up</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>&nbsp;
<button class="width-based-item-big" mat-raised-button color="primary" (click)="openAddDialog({})">Add Apartment</button>
  <button class="width-based-item-small" mat-icon-button color="primary" (click)="openAddDialog({})"><mat-icon>add_home</mat-icon></button>
</div>
<app-prospect-floor (openAddDialog)="openAddDialog($event)" (openFloorDialog)="openFloorDialog($event)"
                    (openEditDialog)="openEditDialog($event)"
                    [address]="address" *ngFor="let item of aptStatus | keyvalue: (floorsSortOrder === 'asc' ? keyAscFn : keyDescFn) "
                    [floorNumber]="item.key" [statusTypes]="statusTypes" [apartments]="item.value"></app-prospect-floor>
<mat-card *ngIf="isEmpty()" style="margin: 1em; padding: 1em">
  <mat-card-content>New Address</mat-card-content>
</mat-card>
