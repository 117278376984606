import {Component, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {TableTypeRow} from "../prospect-building/my-types";
import {MatSort, Sort} from "@angular/material/sort";
import {GraphqlService} from "../../graphql/graphql.service";
import {MsgService} from "../../services/msg.service";
import {ActivatedRoute} from "@angular/router";
import {TableTypeConfigRouteData} from "../../app-routing.module";
import {MatTable, MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-edit-table-type-config-page',
  templateUrl: './edit-table-type-config-page.component.html',
  styleUrls: ['./edit-table-type-config-page.component.scss']
})
export class EditTableTypeConfigPageComponent implements OnInit {


  private rowTypesSubscription: Subscription | undefined;

  public rowTypes: TableTypeRow[] = [];
  public datasource!: MatTableDataSource<TableTypeRow>;

  public routeData!: TableTypeConfigRouteData;

  public newRow: TableTypeRow = {
    id: null,
    nameHe: null,
    nameEn: null,
    nameFr: null
  };

  @ViewChild(MatTable, {static: false}) table!: MatTable<TableTypeRow>;
  @ViewChild(MatSort, {static: false}) sort!: MatSort;

  displayedColumns: string[] = ['nameEn', 'nameHe', 'nameFr', 'commands'];

  constructor(private gqlService: GraphqlService, private msgService: MsgService, private activatedroute:ActivatedRoute) {
  }

  sortData(sort: Sort) {
    this.rowTypes= this.rowTypes.sort((a: TableTypeRow, b: TableTypeRow) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'statusName':
          // @ts-ignore
          return this.compare(a.nameEn ?? a.nameHe?? a.nameFr, b.nameEn ?? b.nameHe ?? b.nameFr, isAsc);
        default:
          return 0;
      }
    });
    this.table.renderRows();
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  addCommodityType() {
    this.rowTypes.unshift(JSON.parse(JSON.stringify(this.newRow)));
    this.table.renderRows();
    this.msgService.snackMsgSuccess("an empty status was added to the table, don't forget to save after changed!");
  }

  ngOnInit(): void {
    this.activatedroute.data.subscribe(data => {
      //@ts-ignore
      this.routeData = data;
      //@ts-ignore
      this.rowTypesSubscription = this.gqlService[this.routeData.get_fnc]().valueChanges.subscribe(({data}) => {
        this.rowTypes = [];
        //@ts-ignore
        data[Object.keys(data)[0]].forEach((row: TableTypeRow) => {
          row.orig = JSON.stringify(row);
          this.rowTypes.push(row);
        });
        this.sortData(this.sort);
      });
    });
  }

  ngOnDestroy() {
    this.rowTypesSubscription?.unsubscribe();
  }

  deleteRow(rowNumber: number) {
    const id = this.rowTypes[rowNumber].id;
    if (id) {
      //@ts-ignore
      const sub = this.gqlService[this.routeData.delete_fnc](id).subscribe((data: any)=>{
        sub.unsubscribe();
        if (data.data[Object.keys(data.data)[0]]) {
          this.msgService.snackMsgSuccess('deleted row successfully');
          this.rowTypes.splice(rowNumber,1);
          this.table.renderRows();
        } else {
          this.msgService.snackMsgError('failed deleting row!');
        }
      });
    } else {
      this.rowTypes.splice(rowNumber,1);
      this.table.renderRows();
    }

  }

  saveRow(rowNumber: number, data: TableTypeRow) {
    if (data.hasOwnProperty('orig')) {
      delete data.orig;
    }
    if (data.hasOwnProperty('changed')) {
      delete data.changed;
    }
    if (data.id !== undefined) {
      const commodityTypeId = data.id;
      delete data.id;
      if (data.hasOwnProperty('__typename')) {
        delete data.__typename;
      }
      //@ts-ignore
      const subUpdate = this.gqlService[this.routeData.update_fnc](commodityTypeId!, data).subscribe((rdata: any) => {
        subUpdate.unsubscribe();
        // @ts-ignore
        if (rdata['data'][Object.keys(rdata['data'])[0]]) {
          this.msgService.snackMsgSuccess('row updated successfully!');
          this.rowTypes[rowNumber].orig=JSON.stringify(data);
        } else {
          this.msgService.snackMsgError('row updated failed!');
        }
      });
    } else {
      //@ts-ignore
      const subAdd = this.gqlService[this.routeData.add_fnc](data).subscribe((rdata: any) => {
        subAdd.unsubscribe();
        if (rdata.data[Object.keys(rdata.data)[0]]) {
          this.msgService.snackMsgSuccess("row added successfully!");
          data.id=rdata.data.addProspectStatusType;
          if (data.hasOwnProperty('orig')) {
            delete data.orig;
          }
          this.rowTypes[rowNumber].orig=JSON.stringify(data);
          this.rowTypes[rowNumber].changed=false;
        } else {
          this.msgService.snackMsgError("failed to add row");
        }
      });
    }
  }

  revertRow(rowNumber: number) {
    this.rowTypes[rowNumber] = JSON.parse(this.rowTypes[rowNumber].orig ?
      this.rowTypes[rowNumber].orig! : JSON.stringify(this.newRow));
    this.rowTypes[rowNumber].orig = JSON.stringify(this.rowTypes[rowNumber]);
    this.table.renderRows();
  }



}
