<mat-card class="mat-elevation-z9" style="margin: 2em">
  <mat-card-title style="display: flex">
    <span style="cursor: pointer" (click)="openFloorDialog.next(floorNumber)">Floor {{floorNumber}}</span>&nbsp;
    <span style="flex: 1 1 auto"></span>
    <button class="width-based-item-big" mat-raised-button color="primary"
                        (click)="openAddDialog.emit({floor: floorNumber})">Add Apartment</button>
    <button class="width-based-item-small" mat-icon-button color="primary"
            (click)="openAddDialog.emit({floor: floorNumber})">
      <mat-icon>add_home</mat-icon>
    </button>
  </mat-card-title>
  <mat-card-content>
  <app-prospect-door [matTooltip]="statusTypes[apt.prospectStatusId].statusNameEn" [contactsNumber]="apt.contacts ? apt.contacts.length : 0"
                     *ngFor="let apt of apartments | sortByAptNumber" [bgColor]="statusTypes[apt.prospectStatusId].bgColor"
                     [textColor]="statusTypes[apt.prospectStatusId].textColor" [doorNumber]="apt.apt"
  (click)="openEditDialog.emit(apt)"
  ></app-prospect-door>
  </mat-card-content>
</mat-card>
