<h1 mat-dialog-title>Hi</h1>
<ul>
  <li *ngFor="let d of ret; let i=index"><mat-slide-toggle [(ngModel)]="val[i]">{{d}}</mat-slide-toggle></li>
</ul>
<div mat-dialog-content>
</div>
<div mat-dialog-actions>
  <button mat-button i18n (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="getCompiledArray()" i18n cdkFocusInitial>Save</button>
</div>
