import { Injectable } from '@angular/core';
import {of} from "rxjs";
import {QuestionBase} from "../question-base";
import {DropdownQuestion} from "../input-components/question-dropdown";
import {TextboxQuestion} from "../input-components/question-textbox";
import {ValidatorFn, Validators} from "@angular/forms";

export type TuxQuestionFormFieldTypeType = 'full-address'|'map'|'list'|'comment'|'text'|'number'|'positive_number'|'email'|'phone'|'id'|'boolean'|'select'|'select-autocomplete'|'select-multi'|'select-autocomplete-multi'|'direction';
export type TuxQuestionFormFieldComponentType = 'full-address'|'map'|'list'|'dropdown'|'text_box'|'dropdown_multiple'|'toggle'|'text_area'|'number_box'|'select'|'select-autocomplete'|'select-multi'|'select-autocomplete-multi'|'direction';
export const QuestionFormFieldTypeTOComponentDefault: { [key: string] : TuxQuestionFormFieldComponentType} = {
  comment:'text_area',
  text:'text_box',
  number:'number_box',
  positive_number:'number_box',
  email:'text_box',
  phone:'text_box',
  id: 'text_box',
  boolean: 'toggle',
  select: 'select',
  'select-multi': 'select-multi',
  'select-autocomplete-multi': 'select-autocomplete-multi',
  list: 'list',
  'select-autocomplete': 'select-autocomplete',
  direction: 'direction',
  map: 'map',
  'full-address': "full-address"
};


export interface QuestionFormSelectData<T> {
  keyNameForValue?: string;
  keyNameForLabel?: string;
}

export interface QuestionFormField<VAL_TYPE, SELECT_OBJ_TYPE> {
  keyName: string;
  type?: TuxQuestionFormFieldTypeType;
  label?: string;
  component?: TuxQuestionFormFieldComponentType;
  validators?: ValidatorFn[];
  defaultValue?: VAL_TYPE|null;
  required?: boolean;
  values?: VAL_TYPE[];
  selectData?: QuestionFormSelectData<SELECT_OBJ_TYPE>;
  listItems?: QuestionForm;
  groupKeyName?: string;
}

export interface QuestionFormMapOptionsRet {
  hideEntrance: boolean;
  isShowZipCode: boolean;
  isShowEntryCode: boolean;
  isShowFloor: boolean;
  isShowApt: boolean;
  isShowCurrentLocation: boolean;
}


export interface QuestionFormFieldGroup {
  groupKeyName: string;
  label: string;
}

export interface QuestionForm {
  label?: string;
  fields: QuestionFormField<any, any>[];
  groups?: QuestionFormFieldGroup[];
  validators?: Validators[];
}

@Injectable({
  providedIn: 'root'
})
export class QuestionService {


  getQuestions() {

    const questions: QuestionBase<string>[] = [

      new DropdownQuestion({
        key: 'brave',
        label: 'Bravery Rating',
        options: [
          {key: 'solid',  value: 'Solid'},
          {key: 'great',  value: 'Great'},
          {key: 'good',   value: 'Good'},
          {key: 'unproven', value: 'Unproven'}
        ],
        order: 3
      }),

      new TextboxQuestion({
        key: 'firstName',
        label: 'First name',
        value: 'Bombasto',
        required: true,
        order: 1
      }),

      new TextboxQuestion({
        key: 'emailAddress',
        label: 'Email',
        type: 'email',
        order: 2
      })
    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }
}
