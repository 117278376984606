<div mat-dialog-title>
  {{isRename ? 'Rename' : 'Change'}} Entrance
</div>
<div mat-dialog-content>
<mat-form-field>
  <input matInput placeholder="Entrance" [(ngModel)]="entrance" />
</mat-form-field>
</div>
  <div mat-dialog-actions>
    <button mat-button (click)="submit()">Ok</button>
    <button mat-button [mat-dialog-close]="null">No Thanks</button>
  </div>

