import { Pipe, PipeTransform } from '@angular/core';
import {AptProspectStatus} from "./crm-pages/prospect-building/my-types";

@Pipe({
  name: 'sortByAptNumber'
})
export class SortByAptNumberPipe implements PipeTransform {

  transform(value: AptProspectStatus[], ...args: any[]): AptProspectStatus[] {
    return value.sort((n1: AptProspectStatus, n2: AptProspectStatus)=>{
      return parseInt(n1.apt) - parseInt(n2.apt)
    });
  }

}
