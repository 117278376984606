import {AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {GoogleMap, MapInfoWindow, MapMarker} from "@angular/google-maps";
import {MsgService} from "../../services/msg.service";
import {AddressInput, FullAddress, MapProspectMarker} from "../prospect-building/my-types";
import {GraphqlService} from "../../graphql/graphql.service";
import {Router} from "@angular/router";
import {QueryRef} from "apollo-angular";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-prospect-building-map',
  templateUrl: './prospect-building-map.component.html',
  styleUrls: ['./prospect-building-map.component.scss']
})
export class ProspectBuildingMapComponent  {

  constructor(private ngZone: NgZone,
              private router:Router,
              ) {}


  editProspect(address: FullAddress) {
        const nav = ['/','prospect-building', address.address];
        if (address.entrance) {
          nav.push(address.entrance);
        }
        this.router.navigate(nav).then(r => {});
      }

}
