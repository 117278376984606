<mat-card [routerLink]="routerLink"
[class.shown]="visibleClass"
[class.hidden]="!visibleClass"
enterTheViewportNotifier (visibilityChange)="visibilityChangeHandler($event)">
  <mat-card-header>
    <mat-card-title class="bold">{{title}}</mat-card-title>
  </mat-card-header>
  <div mat-card-image style="width:100%; overflow: hidden">
  <img class="img" [src]="image"  />
  </div>
  <mat-card-content>
    {{content}}
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button color="primary" i18n>READ MORE</button></mat-card-actions>
</mat-card>
