<div id="sell-info" class="max-text-width-large">
<div class="bold text-large padding-bottom-1" i18n>Looking to list your property?</div>
<div class="bold text-medium" i18n>
You are turning a page a ready to start a new project? We accompany you to list your property to best match your new targets.
</div>
  <div class="text-medium">
<p i18n>
  Trusting a Real Estate agent with your property is a crucial decision.
</p>
<p i18n>
  Your property is the jewel on which you have invested time and sentiment, that is why we are passionate about finding it the buyer it deserves.
</p>
<p i18n>
  Selling a property is often the start of a new project and we are attentive to understanding your stakes and defending them. We will accompany you to getting it listed to the best of your expectations in adequation with the market, as well as advising you on the best strategies to sell it at the best price point.
</p>
<p i18n>
  You’re new project is waiting, let us help you get started!
</p>
  </div>
</div>
  <app-contact-us title="sell"></app-contact-us>
