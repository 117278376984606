import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {GraphqlService} from "../../graphql/graphql.service";
import {Subscription} from "rxjs";
import {
  AptProspectStatus,
  AptProspectStatusContact,
  AptProspectStatusOptional,
  ProspectStatusType
} from "../../crm-pages/prospect-building/my-types";
import {FormBuilder, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {MsgService} from "../../services/msg.service";

import {
  AddAptProspectContactDialogComponent
} from "../add-apt-prospect-contact-dialog/add-apt-prospect-contact-dialog.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {QuestionForm} from "../../tux-dynamic-forms/services/question.service";


// TODO change cummel case types KfirOzer or kfir_ozer to Kfir Ozer for label


@Component({
  selector: 'app-add-apt-prospect-dialog',
  templateUrl: './add-apt-prospect-dialog.component.html',
  styleUrls: ['./add-apt-prospect-dialog.component.scss']
})
export class AddAptProspectDialogComponent implements OnInit, OnDestroy {

  pStatusSub: Subscription|undefined;

  contacts: AptProspectStatusContact[] = [];

  public statusTypes: ProspectStatusType[] = [];

  form: FormGroup;

  constructor(private gqlService: GraphqlService,
              private fb: FormBuilder,
              private msgService: MsgService,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<AddAptProspectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AptProspectStatusOptional) {
    if (data.contacts) {
      this.contacts=data.contacts;
    }
    const q: QuestionForm = {
      label: 'Apt Prospect Dialog',
      fields: [
        {
          keyName: 'id',
          defaultValue: data.id,
        } ,
        {
          keyName: 'floor',
          required: true,
          defaultValue: data.floor,
        } ,
        {
          keyName: 'apt',
          defaultValue: data.apt,
          required: true,
        } ,
        {
          keyName: 'prospectStatusId',
          defaultValue: data.prospectStatusId,
          required: true
        },
        {
          keyName: 'comment',
          defaultValue: data.comment
        }
      ]
    };

    this.form = this.fb.group({
      id: [data.id],
      floor: [data.floor, Validators.required],
      apt: [data.apt, Validators.required],
      prospectStatusId: [data.prospectStatusId, Validators.required],
      comment: [data.comment]
    });
  }

  ngOnInit(): void {
    this.pStatusSub = this.gqlService.getProspectStatusTypes().valueChanges.subscribe(({data}) => {
      this.statusTypes = data.prospectStatusTypes;
    });
  }

  deleteContact(/*contact: AptProspectStatusContact,*/ i : number) {
    this.contacts.splice(i, 1);
    //this.contacts=this.contacts.filter(prop => prop.id != contact.id);
    /*this.gqlService.deleteAptProspectStatusContact(contact.id!).subscribe((data: any)=>{
      if (data.data.deleteAptProspectStatusContact) {
        this.msgService.snackMsgSuccess("deleted contact successfully");
      } else {
        this.msgService.snackMsgError("failed to delete contact!");
      }
    });*/
  }

  openEditContactDialog(data?: AptProspectStatusContact, i?: number) {
    const dialogRef = this.dialog.open(AddAptProspectContactDialogComponent, {
      //  width: '250px',
      data
    });
    dialogRef.afterClosed().subscribe( (modifiedData: AptProspectStatusContact|undefined) => {
      if (modifiedData) {
        if (i !== undefined) {
          this.contacts[i]=modifiedData;
        }
        else {
          this.contacts.push(modifiedData);
        }
      /*if (modifiedData !== undefined) {
        if (i !== undefined) {
          this.gqlService.updateAptProspectStatusContact(this.data.id!, modifiedData).subscribe((data: any) => {
            if (data.data.updateAptProspectStatusContact) {
              this.msgService.snackMsgSuccess("updated contact successfully");
            } else {
              this.msgService.snackMsgError("failed updating contact");
            }
          });
        } else {
          this.gqlService.addAptProspectStatusContact(this.data.id!, modifiedData).subscribe((data: any) => {
            if (data.data.addAptProspectStatusContact) {
              this.msgService.snackMsgSuccess("added contact successfully");
            } else {
              this.msgService.snackMsgError("failed adding contact");
            }
          });
        }*/
      }
    });
  }

  ngOnDestroy() {
    this.pStatusSub?.unsubscribe();
  }

  deleteProspectStatusID() {
    this.dialogRef.close(<AptProspectStatus>{
      id: this.data.id!,
      cmd: 'delete',
      apt: '',
      prospectStatusId: 0,
      floor: '',
      comment: '',
      contacts: this.contacts
    });
  }

  submit() {
    if (this.form.invalid) {
      this.msgService.snackMsgWarn('please fill all fields');
      return;
    } else {
      for (let i=0;i<this.contacts.length;i++) {
        if (this.contacts[i].hasOwnProperty('__typename')) {
          //@ts-ignore
          delete this.contacts[i]['__typename'];
        }
        if (this.contacts[i].hasOwnProperty('id')) {
          //@ts-ignore
          delete this.contacts[i]['id'];
        }
      }
      this.dialogRef.close({...this.form.value, contacts: this.contacts});
    }
  }

}
