<div class="bold" style="font-size: 18pt; padding-bottom: 2em;" i18n>For more information, please leave your contact details below</div>
<form [formGroup]="formGroup" (ngSubmit)="submit()">
  <mat-form-field appearance="outline">
    <mat-label i18n>First Name</mat-label>
    <input matInput formControlName="firstName"/>
    <mat-error *ngIf="formGroup.get('firstName')!.hasError('required')" i18n>First Name is required</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label i18n>Last Name</mat-label>
    <input matInput formControlName="lastName"/>
    <mat-error *ngIf="formGroup.get('lastName')!.hasError('required')" i18n>Last Name is required</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label i18n>Phone Number</mat-label>
    <input matInput formControlName="phone"/>
    <mat-error *ngIf="formGroup.get('phone')!.hasError('required')" i18n>Phone Number is required</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label i18n>Email</mat-label>
    <input matInput formControlName="email" placeholder="foo@bar.com" />
    <mat-error *ngIf="formGroup.get('email')!.hasError('email')" i18n>Please Enter Valid Email</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label i18n>Please tell us about your project</mat-label>
  <textarea matNativeControl formControlName="comment"></textarea>
    <mat-error *ngIf="formGroup.get('comment')!.hasError('required')" i18n>Please provide more information</mat-error>
  </mat-form-field>
  <mat-slide-toggle [(ngModel)]="agreedPrivacyPolicy" [ngModelOptions]="{standalone: true}"><ng-container i18n>I agree to the terms of your</ng-container> <span class="bold cursor-pointer" routerLink="/privacy" i18n>Privacy Policy</span></mat-slide-toggle>
  <button type="submit" mat-stroked-button color="primary" i18n>SUBMIT</button>
</form>

