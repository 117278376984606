import {Component, Input} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {MsgService} from "../../services/msg.service";
import {GraphqlService} from "../../graphql/graphql.service";
import {ContactUsRow} from "../../crm-pages/prospect-building/my-types";

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {

  @Input() title = '';

  agreedPrivacyPolicy: boolean = false;

  formGroup = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['',Validators.required],
    phone: ['', [Validators.required]],
    email: ['', [Validators.email,Validators.required]],
    comment: ['', [Validators.required]]
  });

  constructor(private fb: FormBuilder, private msgService: MsgService, private graphQlService: GraphqlService) {
  }

  submit() {
    if (!this.agreedPrivacyPolicy) {
      this.msgService.snackMsgWarn("please agree to our privacy policy");
    }
    else if (!this.formGroup.valid) {
      this.msgService.snackMsgError("please fill all the required fields");
    } else {
      this.graphQlService.addContactUs(this.title, this.formGroup.value as ContactUsRow).subscribe(()=>{
        this.msgService.snackMsgSuccess('thank you, we will contact you soon');
        this.formGroup.reset();
      });
    }
  }

}
