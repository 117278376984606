import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {GraphqlService} from '../../graphql/graphql.service';
import {ProspectStatusType} from '../prospect-building/my-types';
import {MsgService} from "../../services/msg.service";
import {MatSort, Sort} from "@angular/material/sort";
import {MatTable, MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-prospect-status-type',
  templateUrl: './prospect-status-type.component.html',
  styleUrls: ['./prospect-status-type.component.scss']
})
export class ProspectStatusTypeComponent implements OnInit, OnDestroy {

  private prospectStatusTypesSubscription: Subscription | undefined;

  public statusTypes: ProspectStatusType[] = [];
  public datasource!: MatTableDataSource<ProspectStatusType>;

  public newRow: ProspectStatusType = {
    statusNameEn: '',
    statusNameHe: '',
    statusNameFr: '',
    bgColor: 'white',
    textColor: 'black'
  };

  @ViewChild(MatTable, {static: false}) table!: MatTable<ProspectStatusType>;
  @ViewChild(MatSort, {static: false}) sort!: MatSort;

  displayedColumns: string[] = ['statusNameEn', 'textColor', 'bgColor', 'door', 'commands'];

  constructor(private gqlService: GraphqlService, private msgService: MsgService) {
  }

  sortData(sort: Sort) {
    this.statusTypes= this.statusTypes.sort((a: ProspectStatusType, b: ProspectStatusType) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'statusName':
          return this.compare(a.statusNameEn ?? a.statusNameHe ?? a.statusNameFr,
            b.statusNameEn ?? b.statusNameHe ?? b.statusNameFr, isAsc);
        default:
          return 0;
      }
    });
    this.table.renderRows();
  }

 compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


  addStatusType() {
    this.statusTypes.unshift(JSON.parse(JSON.stringify(this.newRow)));
    this.table.renderRows();
    this.msgService.snackMsgSuccess("an empty status was added to the table, don't forget to save after changed!");
  }

  ngOnInit(): void {
    this.prospectStatusTypesSubscription = this.gqlService.getProspectStatusTypes().valueChanges.subscribe(({data}) => {
      this.statusTypes = [];
      //@ts-ignore
      data.prospectStatusTypes.forEach((row: ProspectStatusType) => {
        row.orig = JSON.stringify(row);
        this.statusTypes.push(row);
      });
      this.sortData(this.sort);
    });
  }

  ngOnDestroy() {
    this.prospectStatusTypesSubscription?.unsubscribe();
  }

  deleteRow(rowNumber: number) {
    const id = this.statusTypes[rowNumber].id;
    if (id) {
      const sub = this.gqlService.deleteProspectStatusType(id).subscribe((data: any)=>{
        sub.unsubscribe();
        if (data.data.deleteProspectStatusType) {
          this.msgService.snackMsgSuccess('deleted row successfully');
          this.statusTypes.splice(rowNumber,1);
          this.table.renderRows();
        } else {
          this.msgService.snackMsgError('failed deleting row!');
        }
      });
    } else {
      this.statusTypes.splice(rowNumber,1);
      this.table.renderRows();
    }

  }

  saveRow(rowNumber: number, data: ProspectStatusType) {
    if (data.hasOwnProperty('orig')) {
      delete data.orig;
    }
    if (data.hasOwnProperty('changed')) {
      delete data.changed;
    }
    if (data.id !== undefined) {
      const statusTypeId = data.id;
      delete data.id;
      if (data.hasOwnProperty('__typename')) {
        delete data.__typename;
      }
      const subUpdate = this.gqlService.updateProspectStatusType(statusTypeId, data).subscribe((rdata: any) => {
        subUpdate.unsubscribe();
        // @ts-ignore
        if (rdata['data']['updateProspectStatusType']) {
          this.msgService.snackMsgSuccess('row updated successfully!');
          this.statusTypes[rowNumber].orig=JSON.stringify(data);
        } else {
          this.msgService.snackMsgError('row updated failed!');
        }
      });
    } else {
      const subAdd = this.gqlService.addProspectStatusType(data).subscribe((rdata: any) => {
        subAdd.unsubscribe();
        if (rdata.data.addProspectStatusType) {
          this.msgService.snackMsgSuccess("row added successfully!");
          data.id=rdata.data.addProspectStatusType;
          if (data.hasOwnProperty('orig')) {
            delete data.orig;
          }
          this.statusTypes[rowNumber].orig=JSON.stringify(data);
          this.statusTypes[rowNumber].changed=false;
        } else {
          this.msgService.snackMsgError("failed to add row");
        }
      });
    }
  }

  revertRow(rowNumber: number) {
    this.statusTypes[rowNumber] = JSON.parse(this.statusTypes[rowNumber].orig ?
      this.statusTypes[rowNumber].orig! : JSON.stringify(this.newRow));
    this.statusTypes[rowNumber].orig = JSON.stringify(this.statusTypes[rowNumber]);
    this.table.renderRows();
  }

}
