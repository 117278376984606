import { Injectable } from '@angular/core';
import {QuestionBase} from "../question-base";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {QuestionForm, QuestionFormField} from "./question.service";

@Injectable({
  providedIn: 'root'
})
export class QuestionControlService {

  constructor(private fb: FormBuilder) {
  }
  buildForm(questionForm: QuestionForm) {
    let group: any = {};
    questionForm.fields.forEach(field => {
      // Check if a defaultValue is defined
      let controlValue = field.defaultValue ? field.defaultValue : null;
      group[field.keyName] = new FormControl(controlValue);
    });
    return new FormGroup(group);
  }
}

export function buildTuxInFormValidators(field: QuestionFormField<any, any>) {
  let validations = field.validators ?? [];
  if (field.required) {
    validations.push(Validators.required);
  }
  return validations;
}
