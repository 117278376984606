<div id="rental-management-info" class="max-text-width-large">
<div class="bold text-large padding-bottom-1" i18n>Looking to rent your property?</div>
<div class="bold text-medium" i18n>Your property is an important asset and you want to make sure it is in good hands? We take in charge the whole renting management so you can serenely focus on your daily activities.
</div>
  <div class="text-medium">
<p i18n>
  Trust is essential in the renting process, this is why we offer you a full range of services to ensure the best management of your asset.
<p i18n>
  Renting a property is time consuming. We accompany you in finding the best tenant, taking care of the whole entry and exit process as well as daily maintenance so that you can focus on your daily life with serenity.
<p i18n>
  Your time is precious, let us take care of your asset!
</p>
  </div>
</div>
  <app-contact-us title="rental-management"></app-contact-us>

