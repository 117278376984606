<div id="main-search-bar"
[ngClass]="getPageClass()"
>
<mat-toolbar>
  <mat-toolbar-row class="toolbar-row-1">
    <img  [routerLink]="['/','home']" class="top-toolbar-logo" src="/en/assets/logo.svg" />
    <div class="top-toolbar-buttons-div">
    <button mat-stroked-button class="top-toolbar-button" [routerLink]="['/','home']" i18n>Home</button>
    <span class="button-divider">|</span>
    <button mat-stroked-button class="top-toolbar-button" [routerLink]="['/','buy']" i18n>Acquire</button>
    <span class="button-divider">|</span>
    <button mat-stroked-button class="top-toolbar-button" [routerLink]="['/','sell']" i18n>List</button>
    <span class="button-divider">|</span>
    <button mat-stroked-button class="top-toolbar-button" [routerLink]="['/','rental-management']" i18n>Rental Management</button>
    <span class="button-divider">|</span>
    <button mat-stroked-button class="top-toolbar-button" [routerLink]="['/','about-us']" i18n>About Us</button>
    </div>
    <div class="flags-buttons-div">
      <span i18n-matTooltip matTooltip="English" class="fi fi-gb" (click)="changeLang('en')"></span>
      <span i18n-matTooltip matTooltip="Hebrew" class="fi fi-il" (click)="changeLang('he')"></span>
      <span i18n-matTooltip matTooltip="French" class="fi fi-fr" (click)="changeLang('fr')"></span>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row class="toolbar-row-2">
    <button mat-icon-button class="side-menu menu-button" (click)="toggleDrawer()"><mat-icon>menu</mat-icon></button>
    <button mat-icon-button class="bottom-menu menu-button" (click)="openBottomSheet()"><mat-icon>menu</mat-icon></button>
    <img class="logo" src="/en/assets/logo.svg" [routerLink]="['/']"  style="cursor: pointer" alt="logo"/>
    <div class="flags-buttons-div flex-row-center">
      <span i18n-matTooltip matTooltip="English" class="fi fi-gb" (click)="changeLang('en')"></span>
      <span i18n-matTooltip matTooltip="Hebrew" class="fi fi-il" (click)="changeLang('he')"></span>
      <span i18n-matTooltip matTooltip="French" class="fi fi-fr" (click)="changeLang('fr')"></span>
    </div>
<!--    <button class="about-us-button" mat-stroked-button [routerLink]="['/','about-us']" style="color: white" i18n>About Us</button>-->
  </mat-toolbar-row>
</mat-toolbar>
<!--<img src="assets/logo.svg" style="max-height: 50%" />-->
</div>
