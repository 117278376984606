<div class="flex-column-center">
<div class="max-text-width-large">
<div class="text-large bold" i18n>About Us</div>
<div class="text-medium">
<p>
  Dream Home in Israel <ng-container i18n>is a full-service real estate and brokerage company that offers a wide range of services whether you are looking to sell, buy or rent a property in Israel.</ng-container>
</p>
<p i18n>
Each project is unique and we take pride in being attentive to the specificities of you research and effectively making every effort to finding the best possible match to fit your wishes.
</p>
  <p>
    <ng-container i18n>If you're looking for a place to call home in Israel</ng-container>, Dream Home in Israel <ng-container i18n>is the perfect place to start your search!</ng-container>
  </p>
</div>
</div>
</div>
