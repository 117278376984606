import gql from 'graphql-tag';
import {Injectable} from '@angular/core';
import {Apollo, QueryRef} from 'apollo-angular';
import {
  AddressInput,
  AptProspectStatus,
  AptProspectStatusContact, ContactUsRow,
  ProspectStatusType, TableTypeRow
} from "../crm-pages/prospect-building/my-types";
import {AddApartment} from "./add-apt";


const GET_IS_RENOVATED = gql`
  query isRenovated {
  getIsRenovatedNames {id, nameEn, nameHe, nameFr}
  }
`;



const GET_APT_SIZE_N_DISPOSITION_TYPES = gql`
  query data {
    getTerraceTypesNames {id, nameEn, nameHe, nameFr}
    getSecuredRoomTypeNames {id, nameEn, nameHe, nameFr}
    getAirConditionerTypeNames {id, nameEn, nameHe, nameFr}
    getWaterHeaterTypeNames {id, nameEn, nameHe, nameFr}
  }
`;



const GET_APT_TYPES_N_IS_RENOVATED = gql`
  query data {
  getIsRenovatedNames {id, nameEn, nameHe, nameFr}
  getTamaProgressNames {id, nameEn, nameHe, nameFr}
  getBuildingTypesNames {id, nameEn, nameHe, nameFr}
  getAptTypeNames {id, nameEn, nameHe, nameFr}
  }
`;

const GET_AUTOCOMPLETE_ADD_APT_DATA = gql`
  query data {
    getIsRenovatedNames {id, nameEn, nameHe, nameFr}
    getTamaProgressNames {id, nameEn, nameHe, nameFr}
    getBuildingTypesNames {id, nameEn, nameHe, nameFr}
    getAptTypeNames {id, nameEn, nameHe, nameFr}
    getTerraceTypesNames {id, nameEn, nameHe, nameFr}
    getSecuredRoomTypeNames {id, nameEn, nameHe, nameFr}
    getAirConditionerTypeNames {id, nameEn, nameHe, nameFr}
    getWaterHeaterTypeNames {id, nameEn, nameHe, nameFr}
    getIsFurnishedNames {id, nameEn, nameHe, nameFr}
    getKitchenFurnitureNames {id, nameEn, nameHe, nameFr}
    getLivingRoomFurnitureNames {id, nameEn, nameHe, nameFr}
    getDiningRoomFurnitureNames {id, nameEn, nameHe, nameFr}
    getBedroomFurnitureNames {id, nameEn, nameHe, nameFr}
    getOfficeFurnitureNames {id, nameEn, nameHe, nameFr}
    getViewTypeNames {id, nameEn, nameHe, nameFr}
    getInterestTypeNames {id, nameEn, nameHe, nameFr}
    getUnitTypeNames {id, nameEn, nameHe, nameFr}
    getBatYamParksNames {id, nameEn, nameHe, nameFr}
    getBatYamBeachesNames {id, nameEn, nameHe, nameFr}
    getBatYamMainStreetsNames {id, nameEn, nameHe, nameFr}
  }
`;

const GET_PROXIMITES_AUTOCOMPLETE_INFO = gql`
  query data {
  getInterestTypeNames {id, nameEn, nameHe, nameFr}
  getUnitTypeNames {id, nameEn, nameHe, nameFr}
  getBatYamParksNames {id, nameEn, nameHe, nameFr}
  getBatYamBeachesNames {id, nameEn, nameHe, nameFr}
  getBatYamMainStreetsNames {id, nameEn, nameHe, nameFr}
  }
`;

const GET_FURNITURE_TYPES = gql`
  query data {
  getIsFurnishedNames {id, nameEn, nameHe, nameFr}
  getKitchenFurnitureNames {id, nameEn, nameHe, nameFr}
  getLivingRoomFurnitureNames {id, nameEn, nameHe, nameFr}
  getDiningRoomFurnitureNames {id, nameEn, nameHe, nameFr}
  getBedroomFurnitureNames {id, nameEn, nameHe, nameFr}
  getOfficeFurnitureNames {id, nameEn, nameHe, nameFr}
  }
`;

const GET_PARKING_TYPES = gql`
  query data {
  getParkingTypeNames {id, nameEn, nameHe, nameFr}
  }
`;

const ADD_APARTMENT_MUTATION = gql`
  mutation AddApartment($aptInput: ApartmentInput!) {
    addApartment(aptInput: $aptInput)
  }
`;

const GET_VIEW_TYPES = gql`
  query data {
  getViewTypeNames {id, nameEn, nameHe, nameFr}
  }
`;

const GET_APT_TYPES = gql`
  query aptTypes {
  getAptTypeNames {id, nameEn, nameHe, nameFr}
  }
`;

const GET_COMMODITIES_TYPES = gql`
  query getCommodities{
    getCommoditiesTypes {
      id, nameEn,nameHe,nameFr
    }
  }
`;

const GET_UTILITIES_AND_SERVICE_TYPES = gql`
  query GetUtilitiesAndServiceTypes{
    getUtilitiesAndServiceTypes {
      id, nameEn,nameHe,nameFr
    }
  }
`;

const GET_MAINTENANCE_SERVICE_SUPPLIER_TYPES = gql`
  query getMaintenanceServiceSupplierTypes{
    getMaintenanceServiceSupplierTypes {
      id, nameEn, nameHe, nameFr
    }
  }
`;

const DELETE_COMMODITY = gql`
    mutation deleteCommodity($id: ID!) {
      deleteCommodityType(typeId: $id)
    }
`;

const DELETE_MAINTENANCE_SERVICE_SUPPLIER_TYPE =gql`
  mutation deleteMaintenanceServiceSupplierType($id: ID!) {
    deleteMaintenanceServiceSupplierType(typeId: $id)
  }
`;

const DELETE_UTILITIES_AND_SERVICES_TYPE = gql`
  mutation deleteUtilitiesAndServiceType($id: ID!) {
    deleteUtilitiesAndServiceType(typeId: $id)
  }
`;


const UPDATE_COMMODITY = gql`
  mutation updateCommodity($id: ID!, $data: TableTypeNameInput!) {
    updateCommodityType(commodityId: $id, data: $data)
  }`;

const UPDATE_MAINTENANCE_SERVICE_SUPPLIER_TYPE = gql`
  mutation updateMaintenanceAndServiceSupplerType($id: ID!, $data: TableTypeNameInput!) {
    updateMaintenanceServiceSupplierType(typeId:  $id, data: $data)
  }`;

const UPDATE_UTILITIES_AND_SERVICE_TYPE = gql`
  mutation updateUtilitiesAndServiceType($id: ID!, $data: TableTypeNameInput!) {
    updateUtilitiesAndServiceTypes(typeId: $id, data: $data)
  }`;


const ADD_COMMODITY = gql`
mutation AddCommodity($data: TableTypeNameInput!) {
  addCommodityType(data: $data)
}`;

const ADD_CONTACT_US = gql`
mutation addContact($category: String!, $data: ContactUsInput!) {
  addContactUs(category: $category, data: $data)
}`;

const ADD_MAINTENANCE_SERVICE_SUPPLIER_TYPE = gql`
mutation AddMaintenanceServiceSupplierType($data: TableTypeNameInput!) {
  addMaintenanceServiceSupplierType(data: $data)
}`;

const ADD_UTILITIES_AND_SERVICE_TYPE = gql`
mutation AddUtilitiesAndServiceType($data: TableTypeNameInput!) {
  addUtilitiesAndServiceTypes(data: $data)
}`;

const UPDATE_APT_PROSPECT_STATUS_CONTACT=gql`
mutation updateAptProspectStatusContact($aptProspectStatusContactId: ID!, $contact: AptProspectStatusContactInput!) {
  updateAptProspectStatusContact(cId: $aptProspectStatusContactId, contact: $contact)
}`;

const ADD_APT_PROSPECT_STATUS_CONTACT=gql`
mutation addAptProspectStatusContact($aptProspectStatusId: ID!, $contact: AptProspectStatusContactInput!) {
  addAptProspectStatusContact(aptProspectStatusId: $aptProspectStatusId, contact: $contact)
}`;

const DELETE_APT_PROSPECT_STATUS_CONTACT=gql`
mutation deleteAptProspectStatusContact($aptProspectStatusContactId: ID!) {
  deleteAptProspectStatusContact(cId: $aptProspectStatusContactId)
}`;

const GET_MAP_MARKERS = gql`
query getAptProspectMarkersMarker {
  getAptProspectMarkers {
    id,address,entrance,latitude,longitude
  }
}`;

const GET_APT_HISTORY_DATA = gql`
query history($match: String, $isExactMatch: Boolean, $limit: Int){
  getAptHistory(match: $match, isExactMatch: $isExactMatch, limit: $limit) {
  type,id,title,subTitle,entrance,itemDesc,itemImg,
  realEstateOffice,price,rooms,floor,squareMeters,squareMetersBuild,squareMetersBuiltTotal,
  squareMetersGarden,entranceDate,conditionOfPlace,balconies,floorsInBuilding,parkingSpots,
  isExclusive,isAircon,isElevator,isBoiler,isDisabledAccess,isMamad,isRenovated,
  isStorage,isAirconTadiran,isHasFurniture,isFlexible,isBars,adNum,sellers,itemUrl,
  createdAt,updatedAt,foundAgainAt,numOfPayments,vaadBaitPerMonth,arnonnaPer2Months
  }
}
`;

const GET_APT_PROSPECT_STATUS_N_TYPES = gql`
  query aptProspectStatusNTypes($address: String!, $entrance: String){
    prospectStatusTypes {
      id, statusNameEn, textColor, bgColor
    }
    aptProspectStatus(address: $address, entrance: $entrance) {
      id,floor,apt,prospectStatusId,comment, contacts{
        id,firstName,lastName,email,phone1,phone2,comment
      }
    }
  }

`

const GET_PROSPECT_STATUS_TYPES = gql`
  query {
    prospectStatusTypes {
      id, statusNameEn, textColor, bgColor
    }
  }
`;

const GET_APT_PROSPECT_STATUS = gql`
  query aptProspectStatus($address: String!, $entrance: String){
    aptProspectStatus(address: $address, entrance: $entrance) {
      id,floor,apt,prospectStatusId,comment, contacts{
        id,firstName,lastName,email,phone1,phone2, comment
      }
    }
  }
`;

const CHANGE_APT_PROSPECT_STATUS_FLOOR=gql`
mutation changeAptProspectStatusFloor($address: String!, $entrance: String, $oldFloor: String!,
  $newFloor: String!, $allowExistingFloor: Boolean!
) {
  changeAptProspectStatusFloor(address: $address, entrance: $entrance, oldFloor: $oldFloor, newFloor: $newFloor,
  allowExistingFloor: $allowExistingFloor)
}`;

const UPDATE_PROSPECT_STATUS_TYPE=gql`
  mutation updateProspectStatusType($statusTypeId: ID!, $data: ProspectStatusTypeInput!) {
    updateProspectStatusType(statusTypeId: $statusTypeId, data: $data)
  }`;

const ADD_PROSPECT_STATUS_TYPE=gql`
  mutation addProspectStatusType($data: ProspectStatusTypeInput!) {
    addProspectStatusType(data: $data)
  }`;

const ADD_ADDRESS_GET_ID = gql`
  mutation addAddress($data: AddressInput!) {
    addAddress(data: $data)
  }`;


const ADD_APT_PROSPECT_STATUS = gql`
  mutation addAptProspectStatus($address: String!, $entrance: String, $data: AptProspectStatusInput!) {
    addAptProspectStatus(address: $address, entrance: $entrance, data: $data)
  }`;

const UPDATE_ADDRESS_ENTRANCE =gql`
  mutation changeAddressEntrance($address: String!, $oldEntrance: String, $newEntrance: String) {
    changeAddressEntrance(address: $address, oldEntrance: $oldEntrance, newEntrance: $newEntrance)
  }
`;

const UPDATE_APT_PROSPECT_STATUS = gql`
  mutation updateAptProspectStatus($aptStatusId: ID!, $data: AptProspectStatusInput!) {
    updateAptProspectStatus(aptStatusId: $aptStatusId, data: $data)
  }`;

const DELETE_PROSPECT_STATUS_TYPE = gql`
  mutation deleteProspectStatusType($statusTypeId: ID!) {
    deleteProspectStatusType(statusTypeId: $statusTypeId)
  }`;

const DELETE_APT_PROSPECT_STATUS = gql`
  mutation deleteAptProspectStatus($aptStatusId: ID!) {
    deleteAptProspectStatus(aptStatusId: $aptStatusId)
  }`;


@Injectable({
  providedIn: 'root'
})
export class GraphqlService {

  constructor(private apollo: Apollo) {

  }

  public getCommoditiesTypes(): QueryRef<any, any> {
    return this.apollo.watchQuery({fetchPolicy: 'no-cache', query: GET_COMMODITIES_TYPES});
  }

  public getIsRenovated(): QueryRef<any, any> {
    return this.apollo.watchQuery({fetchPolicy: 'no-cache', query: GET_IS_RENOVATED});
  }

  public getAptTypesNIsRenovated(): QueryRef<any, any> {
    return this.apollo.watchQuery({fetchPolicy: 'no-cache', query: GET_APT_TYPES_N_IS_RENOVATED});
  }

  public getAutoCompleteDataForAptAdd(): QueryRef<any, any> {
    return this.apollo.watchQuery({fetchPolicy: 'no-cache', query: GET_AUTOCOMPLETE_ADD_APT_DATA});
  }

  public getFurnitureTypes(): QueryRef<any, any> {
    return this.apollo.watchQuery({fetchPolicy: 'no-cache', query: GET_FURNITURE_TYPES});
  }

  public getProximitesAutoCompleteInfo(): QueryRef<any, any> {
    return this.apollo.watchQuery({fetchPolicy: 'no-cache', query: GET_PROXIMITES_AUTOCOMPLETE_INFO});
  }

  public getParkingTypes(): QueryRef<any, any> {
    return this.apollo.watchQuery({fetchPolicy: 'no-cache', query: GET_PARKING_TYPES});
  }

  public getViewTypes(): QueryRef<any, any> {
    return this.apollo.watchQuery({fetchPolicy: 'no-cache', query: GET_VIEW_TYPES});
  }

  public getAptSizeNDispositionTypes(): QueryRef<any, any> {
    return this.apollo.watchQuery({fetchPolicy: 'no-cache', query: GET_APT_SIZE_N_DISPOSITION_TYPES});
  }

  public getAptTypes(): QueryRef<any, any> {
    return this.apollo.watchQuery({fetchPolicy: 'no-cache', query: GET_APT_TYPES});
  }

  public getUtilitiesAndServiceTypes(): QueryRef<any, any> {
    return this.apollo.watchQuery({fetchPolicy: 'no-cache', query: GET_UTILITIES_AND_SERVICE_TYPES});
  }

  public getMaintenanceServiceSupplierTypes(): QueryRef<any, any> {
    return this.apollo.watchQuery({fetchPolicy: 'no-cache', query: GET_MAINTENANCE_SERVICE_SUPPLIER_TYPES});
  }

  public getProspectStatusTypes(): QueryRef<any, any> {
    return this.apollo.watchQuery({fetchPolicy: 'no-cache', query: GET_PROSPECT_STATUS_TYPES});
  }

  public getAptProspectStatus(address: string, entrance: string|null): QueryRef<any, any> {
    return this.apollo.watchQuery({fetchPolicy: 'no-cache', query: GET_APT_PROSPECT_STATUS,
      variables: {address, entrance}});
  }

  public getAptHistory(match: string|null=null, isExactMatch: boolean|null=null, limit: number|null=null) {
    return this.apollo.watchQuery({fetchPolicy: 'no-cache', query: GET_APT_HISTORY_DATA,
      variables: {match, isExactMatch, limit}});
  }

  public getAptProspectStatusNTypes(address: string, entrance: string|null): QueryRef<any, any> {
    return this.apollo.watchQuery({fetchPolicy: 'no-cache', query: GET_APT_PROSPECT_STATUS_N_TYPES,
      variables: {address, entrance}});
  }

  public getMapProspectMarkers(): QueryRef<any, any> {
    return this.apollo.watchQuery({fetchPolicy: 'no-cache', query: GET_MAP_MARKERS});
  }

  public deleteProspectStatusType(statusTypeId: number): any {
    return this.apollo.mutate({
      mutation: DELETE_PROSPECT_STATUS_TYPE,
      variables: {statusTypeId}
    });
  }

  public addContactUs(category: string, data: ContactUsRow): any {
    return this.apollo.mutate({
      mutation: ADD_CONTACT_US,
      variables: {category, data}
    });
  }



  public updateAddressEntrance(address: string, oldEntrance: string|null, newEntrance: string|null) {
    return this.apollo.mutate({
      mutation: UPDATE_ADDRESS_ENTRANCE,
      variables: {address, oldEntrance, newEntrance}
    });
  }

  public deleteAptProspectStatus(aptStatusId: number): any {
    return this.apollo.mutate({
      mutation: DELETE_APT_PROSPECT_STATUS,
      variables: {aptStatusId}
    });
  }

  public addProspectStatusType(data: ProspectStatusType): any {
    return this.apollo.mutate({
      mutation: ADD_PROSPECT_STATUS_TYPE,
      variables: {data}
    });
  }

  public changeAptProspectStatusFloor(address: string, entrance: string|null, oldFloor: string,
                                      newFloor: string, allowExistingFloor: boolean): any {
    return this.apollo.mutate({
      mutation: CHANGE_APT_PROSPECT_STATUS_FLOOR,
      variables: {address, entrance, oldFloor, newFloor, allowExistingFloor}
    });
  }

  public updateProspectStatusType(statusTypeId: number, data: ProspectStatusType): any {
    return this.apollo.mutate({
      mutation: UPDATE_PROSPECT_STATUS_TYPE,
      variables: {statusTypeId, data}
    });
  }

  public updateAptProspectStatus(aptStatusId: number, data: AptProspectStatus): any {
    return this.apollo.mutate({
      mutation: UPDATE_APT_PROSPECT_STATUS,
      variables: {aptStatusId, data}
    });
  }


  public addApt(aptInput: AddApartment) {
    return this.apollo.mutate({
      mutation: ADD_APARTMENT_MUTATION,
      variables: {aptInput}
    });
  }


  public addCommodity(data: TableTypeRow) {
    return this.apollo.mutate({
    mutation: ADD_COMMODITY,
      variables: {data}
    });
  }

  public addUtilitiesAndServiceTypes(data: TableTypeRow) {
    return this.apollo.mutate({
    mutation: ADD_UTILITIES_AND_SERVICE_TYPE,
      variables: {data}
    });
  }

public addMaintenanceServiceSupplierType(data: TableTypeRow) {
    return this.apollo.mutate({
    mutation: ADD_MAINTENANCE_SERVICE_SUPPLIER_TYPE,
      variables: {data}
    });
  }

  public deleteCommodity(id: number) {
    return this.apollo.mutate({
      mutation: DELETE_COMMODITY,
      variables: {id}
    });
  }

  public deleteUtilitiesAndServiceTypes(id: number) {
    return this.apollo.mutate({
      mutation: DELETE_UTILITIES_AND_SERVICES_TYPE,
      variables: {id}
    });
  }

  public deleteMaintenanceServiceSupplierType(id: number) {
    return this.apollo.mutate({
      mutation: DELETE_MAINTENANCE_SERVICE_SUPPLIER_TYPE,
      variables: {id}
    });
  }

  public updateCommodity(id: number,data: TableTypeRow) {
    return this.apollo.mutate({
    mutation: UPDATE_COMMODITY,
      variables: {id, data}
    });
  }

  public updateUtilitiesAndServiceTypes(id: number,nameEn: string|null, nameHe:string|null=null, nameFr:string|null=null) {
    return this.apollo.mutate({
    mutation: UPDATE_UTILITIES_AND_SERVICE_TYPE,
      variables: {id ,nameEn, nameHe, nameFr}
    });
  }

public updateMaintenanceServiceSupplierType(id: number, nameEn: string|null, nameHe:string|null=null, nameFr:string|null=null) {
    return this.apollo.mutate({
    mutation: UPDATE_MAINTENANCE_SERVICE_SUPPLIER_TYPE,
      variables: {id, nameEn, nameHe, nameFr}
    });
  }


  public updateAptProspectStatusContact(aptProspectStatusContactId: number, contact: AptProspectStatusContact): any {
    return this.apollo.mutate({
      mutation: UPDATE_APT_PROSPECT_STATUS_CONTACT,
      variables: {aptProspectStatusContactId, contact}
    });
  }

  public deleteAptProspectStatusContact(aptProspectStatusContactId: number): any {
    return this.apollo.mutate({
      mutation: DELETE_APT_PROSPECT_STATUS_CONTACT,
      variables: {aptProspectStatusContactId}
    });
  }

  public addAptProspectStatusContact(aptProspectStatusId: number, contact: AptProspectStatusContact): any {
    return this.apollo.mutate({
      mutation: ADD_APT_PROSPECT_STATUS_CONTACT,
      variables: {aptProspectStatusId, contact}
    });
  }


  public addAptProspectStatus(address: string, entrance: string|null, data: AptProspectStatus): any {
    return this.apollo.mutate({
      mutation: ADD_APT_PROSPECT_STATUS,
      variables: {address, entrance, data}
    });
  }

  public addAddressGetID(data: AddressInput): any {
    return this.apollo.mutate({
      mutation: ADD_ADDRESS_GET_ID,
      variables: {data}
    });
  }
}
