import {ErrorHandler, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GraphQLModule } from './graphql/graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { ToolbarComponent } from './crm-components/toolbar/toolbar.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import { HomeComponent } from './site-pages/home/home.component';
import { ProspectBuildingComponent } from './crm-pages/prospect-building/prospect-building.component';
import { ProspectFloorComponent } from './crm-components/prospect-floor/prospect-floor.component';
import { ProspectDoorComponent } from './crm-components/prospect-door/prospect-door.component';
import {ToastrModule} from 'ngx-toastr';
import { ProspectStatusTypeComponent } from './crm-pages/prospect-status-type/prospect-status-type.component';
import {ColorPickerModule} from "ngx-color-picker";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {MatSortModule} from "@angular/material/sort";
import { AddAptProspectDialogComponent } from './crm-components/add-apt-prospect-dialog/add-apt-prospect-dialog.component';
import { SortByAptNumberPipe } from './sort-by-apt-number.pipe';
import { AdminComponent } from './crm-pages/admin/admin.component';
import { ProspectBuildingMapComponent } from './crm-pages/prospect-building-map/prospect-building-map.component';
import {GoogleMapsModule} from "@angular/google-maps";
import {MatBadgeModule} from "@angular/material/badge";
import {
  AddAptProspectContactDialogComponent
} from "./crm-components/add-apt-prospect-contact-dialog/add-apt-prospect-contact-dialog.component";
import {
  AddAptProspectFloorDialogComponent
} from "./crm-components/add-apt-prospect-floor-dialog/add-apt-prospect-floor-dialog.component";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {
  AddAptProspectChangeEntranceDialogComponent
} from "./crm-components/add-apt-prospect-change-entrance-dialog/add-apt-prospect-change-entrance-dialog.component";
import { EditTableTypeConfigPageComponent } from './crm-pages/edit-table-type-config-page/edit-table-type-config-page.component';
import {MatDividerModule} from "@angular/material/divider";
import { TopToolbarComponent } from './site-components/top-toolbar/top-toolbar.component';
import {MenuBottomSheetComponent} from "./site-components/menu-bottom-sheet/menu-bottom-sheet.component";
import {MobileMenuButtonsComponent} from "./site-components/mobile-menu-buttons/mobile-menu-buttons.component";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {MatSidenavModule} from "@angular/material/sidenav";
import { AboutUsComponent } from './site-components/about-us/about-us.component';
import { BuyComponent } from './site-components/buy/buy.component';
import { RentalManagementComponent } from './site-components/rental-management/rental-management.component';
import { SellComponent } from './site-components/sell/sell.component';
import { ContactUsComponent } from './site-components/contact-us/contact-us.component';
import { UnderConstructionsComponent } from './site-pages/under-constructions/under-constructions.component';
import { MyHorzCardComponent } from './site-components/my-horz-card/my-horz-card.component';
import { MyVertCardComponent } from './site-components/my-vert-card/my-vert-card.component';
import {MatCardModule} from "@angular/material/card";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatSelectModule} from "@angular/material/select";
import {MatDialogModule} from "@angular/material/dialog";
import {MatTableModule} from "@angular/material/table";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatMenuModule} from "@angular/material/menu";
import {MatListModule} from "@angular/material/list";
import { FooterComponent } from './footer/footer.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from "ngx-cookieconsent";
import { CookiesPolicyComponent } from './cookies-policy/cookies-policy.component';
import { EnterTheViewportNotifierDirective } from './directives/enter-to-viewport-notifier.directive';
import {NgOptimizedImage} from "@angular/common";
import { AptHistoryComponent } from './crm-pages/apt-history/apt-history.component';
import {MatPaginatorModule} from "@angular/material/paginator";
import {CdkDrag, CdkDropList} from "@angular/cdk/drag-drop";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { TableColumnsComponent } from './crm-components/table-columns/table-columns.component';
import {TimeAgoPipe} from "./time-ago.pipe";
import { CreateNewAptPageComponent } from './crm-pages/create-new-apt-page/create-new-apt-page.component';
import { AptStepperComponent } from './crm-components/new-apt/apt-stepper/apt-stepper.component';
import {MatStepperModule} from "@angular/material/stepper";
import { ContactGenericFieldsComponent } from './crm-components/generics/contact-generic-fields/contact-generic-fields.component';
import {
  AddressGenericFieldsComponent
} from "./crm-components/generics/address-generic-fields/address-generic-fields.component";
import { AptTypeNCondGenericFieldsComponent } from './crm-components/generics/apt-type-n-cond-generic-fields/apt-type-n-cond-generic-fields.component';
import {
  AptSizeNDispositionGenericFieldsComponent
} from "./crm-components/generics/apt-size-n-disposition-generic-fields/apt-size-n-disposition-generic-fields.component";
import {
  AptTerraceGenericFieldsComponent
} from "./crm-components/generics/apt-terrace-generic-fields/apt-terrace-generic-fields.component";
import {
  AptFurnitureGenericFieldsComponent
} from "./crm-components/generics/apt-furniture-generic-fields/apt-furniture-generic-fields.component";
import {
  AptCommoditiesGenericFieldsComponent
} from "./crm-components/generics/apt-commodities-generic-fields/apt-commodities-generic-fields.component";
import {
  AptViewNExpGenericFieldsComponent
} from "./crm-components/generics/apt-view-n-exp-generic-fields/apt-view-n-exp-generic-fields.component";
import { CompassFormInputComponent } from './crm-components/generics/compass-form-input/compass-form-input.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {
  AptProximitiesGenericFieldsComponent
} from "./crm-components/generics/apt-proximities-generic-fields/apt-proximities-generic-fields.component";
import {TuxDynamicFormComponent} from "./tux-dynamic-forms/components/tux-dynamic-form/tux-dynamic-form.component";
import {
  TuxDynamicFormQuestionComponent
} from "./tux-dynamic-forms/tux-dynamic-form-question/tux-dynamic-form-question.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {GlobalErrorHandler} from "./tux-dynamic-forms/components/global-error-handler";
import {MyMatFormFieldAbstract} from "./my-mat-form-field-abstract";

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'dreamhomeinisrael.com' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#2e4260'
    }
  },
  theme: 'edgeless',
  type: 'info',
  content: {
    message: $localize`This website uses cookies to ensure you get the best experience on our website.`,
    allow: $localize`Allow cookies`,
    deny: $localize`Decline`,
    policy: $localize`Cookie Policy`,
    link: $localize`Learn More`,
    dismiss: $localize`Got it!`

  }
};

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    HomeComponent,
    ProspectBuildingComponent,
    ProspectFloorComponent,
    ProspectDoorComponent,
    ProspectStatusTypeComponent,
    AddAptProspectDialogComponent,
    AddAptProspectContactDialogComponent,
    AddAptProspectFloorDialogComponent,
    SortByAptNumberPipe,
    AdminComponent,
    ProspectBuildingMapComponent,
    AddAptProspectChangeEntranceDialogComponent,
    EditTableTypeConfigPageComponent,
    TopToolbarComponent,
    MenuBottomSheetComponent,
    MobileMenuButtonsComponent,
    AboutUsComponent,
    BuyComponent,
    RentalManagementComponent,
    SellComponent,
    ContactUsComponent,
    UnderConstructionsComponent,
    MyHorzCardComponent,
    MyVertCardComponent,
    FooterComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    DisclaimerComponent,
    CookiesPolicyComponent,
    EnterTheViewportNotifierDirective,
    AptHistoryComponent,
    TableColumnsComponent,
    TimeAgoPipe,
    CreateNewAptPageComponent,
    AptStepperComponent,
    ContactGenericFieldsComponent,
    AptSizeNDispositionGenericFieldsComponent,
    AddressGenericFieldsComponent,
    AptTypeNCondGenericFieldsComponent,
    AptTerraceGenericFieldsComponent,
    AptFurnitureGenericFieldsComponent,
    AptCommoditiesGenericFieldsComponent,
    AptViewNExpGenericFieldsComponent,
    CompassFormInputComponent,
    AptProximitiesGenericFieldsComponent,
    TuxDynamicFormComponent,
    TuxDynamicFormQuestionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    GraphQLModule,
    HttpClientModule,
    MatToolbarModule,
    MatCardModule,
    MatButtonModule,
    ToastrModule.forRoot(),
    MatTableModule,
    ColorPickerModule,
    SweetAlert2Module.forRoot(),
    MatInputModule,
    FormsModule,
    MatIconModule,
    MatTooltipModule,
    MatSortModule,
    MatSelectModule,
    MatDialogModule,
    MatBottomSheetModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    MatBadgeModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatDividerModule,
    MatListModule,
    MatSidenavModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    NgOptimizedImage,
    MatPaginatorModule,
    CdkDropList,
    CdkDrag,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatCheckboxModule,
    NgSelectModule
  ],
  providers: [
    // {provide: ErrorHandler,
    // useClass: GlobalErrorHandler}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
